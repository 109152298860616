<template>
  <div>
    <mosaic-loading-and-error-cards object-type="Course Activity" :load="loadAssignmentWithRedirect">
      <assignment-card
        v-if="studentAssignment && sectionId"
        v-model:student-assignment="studentAssignment"
        v-model:section-id="sectionId"
        :can-review="canStaffReview"
        :can-edit-assignment="canStaffEdit"
        :can-mark-as-reviewed="userStaffHasPermissionForSelectedStudent('student.assignments.markAsReviewed')"
        :file-upload-presign-url="`/presign/students/${studentId}/assignment-files`"
        :version-number="versionNumber"
        :routing-data="routingData"
        :latest-version-number="latestVersionNumber"
        @click-section="onClickSection($event)"
        @update:dirty="dirty = $event"
      />
    </mosaic-loading-and-error-cards>
    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Course Activity Page"
      :custom-leave-action="changeSection"
    ></unsaved-changes-dialog>
  </div>
</template>

<script setup lang="ts">
import AssignmentCard from './AssignmentCard.vue';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { parseRouteId } from '@/composables/vue-router';
import { setupAssignmentPage } from './student-assignments';
import { computed } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { useRoute } from 'vue-router';
import { useApi } from '@/composables/api';
import { useStaffStore } from '@/stores/staff';

const {
  actions: { userStaffHasPermissionForSelectedStudent, userStaffHasRoleIdForSelectedStudent },
} = useStaffStore();

const api = useApi();

const route = useRoute();

const studentId = parseRouteId('studentId');

const routingData = computed(() => ({
  namePrefix: 'Tutor',
  params: {
    studentId: studentId.value.toString(),
    id: assignmentId.value.toString(),
  },
}));

const versionNumber = computed(() => {
  return studentAssignment.value?.versionNumber ?? null;
});
const latestVersionNumber = computed(() => {
  return studentAssignment.value?.latestVersionNumber ?? null;
});

const canStaffReview = computed(() => {
  return userStaffHasPermissionForSelectedStudent('student.assignments.edit');
});

const assignmentId = parseRouteId('id');
const routeVersionNumber = computed(() => route.params.versionNumber || null);

const router = useRouter();

async function loadAssignmentWithRedirect() {
  try {
    await load();
  } catch (e) {
    if (axios.isAxiosError(e)) {
      if (e.response?.status === 404 && e.response.data.error_code === 'not_assigned') {
        router.push({ name: 'TutorAssignmentsListPage' });
        return;
      }
    }

    throw e;
  }
}

const canStaffEdit = computed(() => {
  // Might be useful to set up typing so that roleId can't be null if isTraineeContributor is false
  if (!studentAssignment.value || !studentAssignment.value.assignment.roleId) return false;
  return userStaffHasRoleIdForSelectedStudent(studentAssignment.value.assignment.roleId);
});

const { studentAssignment, sectionId, load, dirty, dialog, onClickSection, changeSection } = setupAssignmentPage(
  api,
  'TutorAssignmentsListPage',
  `/students/${studentId.value}/assignments/${assignmentId.value}`,
  false,
  routeVersionNumber,
  canStaffReview.value
);
</script>
