<template>
  <div>
    <v-card v-if="error">
      <v-card-text>{{ error }}</v-card-text>
    </v-card>
    <calendar v-else-if="!busy" :events="events" />
  </div>
</template>

<script>
import Calendar from '../../components/calendar/Calendar.vue';

export default {
  name: 'StudentCalendarPage',
  components: { Calendar },
  data: () => ({
    busy: true,
    error: '',
    events: [],
  }),
  computed: {
    breadcrumbs() {
      return [
        {
          text: 'Calendar',
        },
      ];
    },
  },
  async created() {
    try {
      const r = await this.$api.get('/calendar');
      this.events = r.data;
      this.busy = false;
    } catch (e) {
      console.log(e);
      this.error = 'Sorry, cannot load your calendar at the moment';
    }
  },
};
</script>
