import type { MenuItem } from '@/components/library/buttons/mosaic-button-with-menu';
import type { CurriculumStatement } from '@/store/map-store';
import { mapGetters, mapState } from '@/store/map-store';
import type {
  ContentLayoutImage,
  ContentLayoutItem,
  ContentLayoutItemType,
  ContentLayoutResource,
  ContentLayoutVideo,
} from '@/utils/content-layout';
import { icons } from '@/utils/icons';
import { v4 as uuid } from 'uuid';
import { nextTick, ref } from 'vue';

export type ContentLayoutItemWithoutId = DistributiveOmit<ContentLayoutItem, 'id'>;

interface ItemTypeValue {
  text: string;
  icon: string;
  group: 'content' | 'input' | 'layout';
  defaultValue: ContentLayoutItemWithoutId;
}

export function useContentLayoutBuilder() {
  const { traineeNounCapitalisedAndPluralised } = mapGetters();

  const itemTypes: { [type in ContentLayoutItemType]: ItemTypeValue } = {
    text: {
      text: 'Text Block',
      icon: 'mdi-text-long',
      group: 'content',
      defaultValue: {
        itemType: 'text',
        value: `{"ops":[{"insert":"Some text to help guide, inform or instruct ${traineeNounCapitalisedAndPluralised.value} or Mentors \\n"}]}`,
      },
    },
    resource: {
      text: 'Resource',
      icon: 'mdi-file',
      group: 'content',
      defaultValue: {
        itemType: 'resource',
        title: '',
        description: '',
        resourceId: -1,
      },
    },
    video: {
      text: 'Video',
      icon: 'mdi-video',
      group: 'content',
      defaultValue: {
        itemType: 'video',
        title: '',
        resourceId: -1,
      },
    },
    youtube: {
      text: 'YouTube',
      icon: 'mdi-youtube',
      group: 'content',
      defaultValue: {
        itemType: 'youtube',
        url: '',
      },
    },
    link: {
      text: 'External Link',
      icon: 'mdi-link',
      group: 'content',
      defaultValue: { itemType: 'link', title: '', description: '', url: '', openType: 'newTab' },
    },
    image: {
      text: 'Image',
      icon: 'mdi-image',
      group: 'content',
      defaultValue: {
        itemType: 'image',
        resourceId: -1,
        description: '',
        displayType: 'fullWidth',
        widthPercentage: 33,
        alignment: 'left',
        backgroundColor: '#FFFFFF',
      },
    },
    curriculumLink: {
      text: 'Curriculum Link',
      icon: 'mdi-' + icons.curriculumStatement,
      group: 'content',
      defaultValue: {
        itemType: 'curriculumLink',
        curriculumStatementId: -1,
      },
    },
    nasbttMentorModule: {
      text: 'NASBTT Mentor Development Module',
      icon: 'mosaic-nasbtt',
      group: 'content',
      defaultValue: {
        itemType: 'nasbttMentorModule',
        publicationCode: '',
      },
    },
    nasbttTraineeModule: {
      text: 'NASBTT Learn Module',
      icon: 'mosaic-nasbtt',
      group: 'content',
      defaultValue: {
        itemType: 'nasbttTraineeModule',
        publicationCode: '',
      },
    },
    comment: {
      text: 'Text Question',
      icon: 'mdi-comment-question-outline',
      group: 'input',
      defaultValue: {
        itemType: 'comment',
        title: getRandomTitle(),
        placeholder: null,
      },
    },
    taskList: {
      text: 'Task List',
      icon: 'mdi-format-list-checks',
      group: 'input',
      defaultValue: {
        itemType: 'taskList',
        title: 'Tasks',
        tasks: [{ id: uuid(), title: 'Do something...' }],
      },
    },
    fileUpload: {
      text: 'File Upload',
      icon: 'mdi-progress-upload',
      group: 'input',
      defaultValue: {
        itemType: 'fileUpload',
        title: `Please upload your essay on: ${getRandomTitle()}`,
        minFiles: 1,
        maxFiles: 1,
      },
    },
    columns: {
      text: 'Columns',
      icon: 'mdi-format-columns',
      group: 'layout',
      defaultValue: {
        itemType: 'columns',
        alignment: 'top',
      },
    },
  };

  function itemName(item: ContentLayoutItem) {
    return itemTypes[item.itemType].text;
  }

  function itemIcon(item: ContentLayoutItem) {
    return itemTypes[item.itemType].icon;
  }

  interface AddItemDialog {
    active: boolean;
    addItem: (item: ContentLayoutItemWithoutId) => void;
  }
  const defaultAddItemDialog = {
    active: false,
    addItem: (_i: ContentLayoutItemWithoutId) => {
      return;
    },
  };

  const addResourceDialogRef = ref<AddItemDialog>({ ...defaultAddItemDialog });
  function resourceAdded(r: { title: string; description: string; resourceId: number }) {
    // Unsure the best way to make this properly type safe, but I don't think it adds much value
    const defaultResource = itemTypes['resource'].defaultValue as Omit<ContentLayoutResource, 'id'>;
    addResourceDialogRef.value.addItem({ ...defaultResource, ...r });
  }

  const addVideoDialogRef = ref<AddItemDialog>({ ...defaultAddItemDialog });
  function videoAdded({ resourceId, title }: { resourceId: number; title: string }) {
    // Unsure the best way to make this properly type safe, but I don't think it adds much value
    const defaultResource = itemTypes['video'].defaultValue as Omit<ContentLayoutVideo, 'id'>;
    addVideoDialogRef.value.addItem({
      ...defaultResource,
      title,
      resourceId,
    });
  }

  const addImageDialogRef = ref<AddItemDialog>({ ...defaultAddItemDialog });
  function imageAdded({ resourceId, title }: { resourceId: number; title: string }) {
    // Unsure the best way to make this properly type safe, but I don't think it adds much value
    const defaultResource = itemTypes['image'].defaultValue as Omit<ContentLayoutImage, 'id'>;
    let description = '';
    const titleSplit = title.split('.');
    // This is to remove file extensions and assumes the file extensions starts at the first dot
    description = titleSplit[0];
    addImageDialogRef.value.addItem({
      ...defaultResource,
      resourceId,
      description,
    });
  }

  const linkToCurriculumDialog = ref<AddItemDialog>({ ...defaultAddItemDialog });
  async function curriculumStatementsSelected(statements: CurriculumStatement[]) {
    for (const [i, s] of statements.entries()) {
      linkToCurriculumDialog.value.addItem({
        itemType: 'curriculumLink',
        curriculumStatementId: s.id,
      });

      if (i < statements.length - 1) {
        // addItem appends the item to the section's items
        // Without nextTick the section's items won't have updated to include the previous item as the props won't have updated
        // This results in only the last curriculumStatement being added to the content layout when multiple are selected
        //
        // Modifying addItem to become addItems is currently fiddly as columns can only contain one item
        // Therefore, either the columns assume they get a list of size one or we use this workaround here.
        await nextTick();
      }
    }
  }

  const nasbttModulesUnavailableDialogActive = ref(false);

  function createAddItemMenuItems(
    supportedItemTypes: ContentLayoutItemType[],
    includeLayoutItems: boolean,
    addItem: (item: ContentLayoutItemWithoutId) => void
  ): MenuItem[] {
    const { selectedInstitution } = mapState();
    const items = Object.entries(itemTypes)
      .map(([type, i]) => ({
        ...i,
        type,
        click: () => {
          if (type === 'resource') {
            addResourceDialogRef.value = {
              active: true,
              addItem,
            };
          } else if (type === 'video') {
            addVideoDialogRef.value = {
              active: true,
              addItem,
            };
          } else if (type === 'image') {
            addImageDialogRef.value = {
              active: true,
              addItem,
            };
          } else if (type === 'curriculumLink') {
            linkToCurriculumDialog.value = {
              active: true,
              addItem,
            };
          } else if (type === 'nasbttMentorModule' || type === 'nasbttTraineeModule') {
            if (selectedInstitution.value.config.nasbtt_modules_organisation_id) {
              addItem(itemTypes[type].defaultValue);
            } else {
              nasbttModulesUnavailableDialogActive.value = true;
            }
          } else {
            addItem(itemTypes[type].defaultValue);
          }
        },
      }))
      .filter(
        x =>
          x &&
          supportedItemTypes.includes(x.type) &&
          (x.type !== 'curriculumLink' || selectedInstitution.value.config.has_curriculum)
      );

    const contentItems = items.filter(x => x.group === 'content');
    const inputItems = items.filter(x => x.group === 'input');
    let menuItems: MenuItem[] = [
      ...(contentItems.length > 0 ? ([{ groupTitle: true, title: 'Content' }] as const) : []),
      ...contentItems,
      ...(inputItems.length > 0 ? ([{ groupTitle: true, title: 'Input' }] as const) : []),
      ...inputItems,
    ];

    if (includeLayoutItems) {
      const layoutItems = items.filter(x => x.group === 'layout');
      menuItems = menuItems.concat([
        ...(layoutItems.length > 0 ? ([{ groupTitle: true, title: 'Layout' }] as const) : []),
        ...layoutItems,
      ]);
    }

    return menuItems;
  }

  return {
    itemTypes,
    itemName,
    itemIcon,
    addResourceDialogRef,
    resourceAdded,
    addVideoDialogRef,
    videoAdded,
    addImageDialogRef,
    imageAdded,
    linkToCurriculumDialog,
    curriculumStatementsSelected,
    nasbttModulesUnavailableDialogActive,
    createAddItemMenuItems,
  };
}

function getRandomTitle(): string {
  const titles = [
    'How long is a piece of string?',
    "What's your favourite colour?",
    'How many roads must a man walk down?',
    'Is there life on Mars?',
    'Why does it always rain on me?',
  ];
  return titles[Math.floor(Math.random() * titles.length)];
}
