import { defineStore } from 'pinia';
import { mapMutations, mapState, type Cohort, type CohortStudent } from '@/store/map-store';
import { computed, ref, watch } from 'vue';
import { useStore } from './common';
import { useApi } from '@/composables/api';
import type { StudentCohortResponse } from '@/types/responses';
import { withProcessingAndError } from '@/composables/processing-and-errors';
import { CacheMap } from './cache-map';
import { hasPermissionForSelectedCohort } from '@/composables/permission';

interface CohortStaff {
  id: number;
  name: string;
  email: string;
  displayName: string;
  staffRoles: { id: number; studentId: number; noStudentYetCohortId: number; role: { id: number; name: string } }[];
  user: {
    emailVerified: boolean;
    emailBounced: boolean;
    optedOutOfEmails: boolean;
    isDemo: boolean;
  };
}

export const useCohortStore = useStore(
  defineStore('cohort', () => {
    const api = useApi();
    const { selectedCohort } = mapState();
    const { updateSelectedCohortWithChanges } = mapMutations();
    const hasPlacements = computed(
      () => !!selectedCohort.value.placements && selectedCohort.value.placements.length > 0
    );
    const cohortStaff = ref<CohortStaff[]>([]);
    async function loadCohortStaff() {
      const r = await api.get<CohortStaff[]>(`/cohorts/${selectedCohort.value.id}/staff`);
      cohortStaff.value = r.data;
    }

    const cohortStudents = ref<CacheMap<StudentCohortResponse[]>>(new CacheMap());
    const {
      action,
      actionInBackground,
      processing: loadCohortStudentsProcessing,
      error: loadCohortStudentsError,
    } = withProcessingAndError(async (force: boolean) => {
      if (!hasPermissionForSelectedCohort('Admin').value) return;

      const cohortId = selectedCohort.value.id;
      if (force || !cohortStudents.value.get(cohortId)) {
        const students = await api.getAllPages<StudentCohortResponse>(`/cohorts/${cohortId}/students`);
        cohortStudents.value.set(cohortId, students);
      }
    });

    function loadCohortStudents(force = false, doInBackground = false) {
      return doInBackground ? actionInBackground(force) : action(force);
    }

    watch(selectedCohort, cohort => {
      if (cohort) {
        loadCohortStudents();
      } else {
        cohortStaff.value = [];
      }
    });

    const selectedCohortStudents = computed<CohortStudent[]>(() => {
      return selectedCohort.value ? cohortStudents.value.getEvenIfExpired(selectedCohort.value.id) || [] : [];
    });

    function updateSelectedCohort(changes: Partial<Cohort>) {
      updateSelectedCohortWithChanges(changes);
    }

    const inUseCohortSubjects = computed(() => {
      type Subject =
        | NonNullable<StudentCohortResponse['subject']>
        | NonNullable<StudentCohortResponse['additional_subject']>;
      return (
        selectedCohortStudents.value
          .map(x => [x.subject, x.additional_subject])
          .flat()
          .filter(x => !!x) as Subject[]
      ).uniqueBy(x => x.id);
    });

    return {
      selectedCohort,
      hasPlacements,
      cohortStaff,
      loadCohortStaff,
      loadCohortStudents,
      selectedCohortStudents,
      updateSelectedCohort,
      loadCohortStudentsProcessing,
      loadCohortStudentsError,
      inUseCohortSubjects,
    };
  })
);
