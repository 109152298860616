<template>
  <mosaic-list-filters-card title="Evidence">
    <mosaic-select
      name="evidence-type-filter"
      v-model="visibleTypes"
      class="multiple-v-select"
      style="min-width: 250px"
      multiple
      no-icon
      label="Filter by Evidence type"
      :items="evidenceTypes"
      hide-details
    />

    <mosaic-select
      name="evidence-standard-filter"
      no-icon
      v-model="standardCodes"
      class="multiple-v-select"
      style="min-width: 250px"
      multiple
      :label="`Filter by ${standardNounCapitalised}`"
      :items="standards.map(s => s.code)"
      hide-details
    />
    <div class="d-flex align-center">
      <v-switch
        v-model="onlyFavourites"
        label="Only show best evidence"
        color="accent"
        hide-details
        class="mt-0 mr-2"
      ></v-switch>
      <ndt-icon-button icon="close" tooltip="Clear All" @click.prevent="clearFilters()" />
    </div>
  </mosaic-list-filters-card>
</template>

<script>
import { mapState } from 'vuex';
import NdtIconButton from './NdtIconButton.vue';
import { evidenceTypes } from '../models/evidence-types';

export default {
  props: {
    standards: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    evidenceTypes,
  }),
  computed: {
    ...mapState(['evidenceFilters']),
    standardCodes: {
      get() {
        return this.evidenceFilters.standardCodes;
      },
      set(standardCodes) {
        this.$store.commit('updateEvidenceStandardCodesFilter', standardCodes);
      },
    },
    onlyFavourites: {
      get() {
        return this.evidenceFilters.onlyFavourites;
      },
      set(onlyFavourites) {
        this.$store.commit('updateEvidenceOnlyFavouritesFilter', onlyFavourites);
      },
    },
    visibleTypes: {
      get() {
        return this.evidenceFilters.types;
      },
      set(types) {
        this.$store.commit('updateEvidenceTypesFilter', types);
      },
    },
  },
  methods: {
    clearFilters() {
      this.visibleTypes = [];
      this.standardCodes = [];
      this.onlyFavourites = false;
    },
  },
  components: { NdtIconButton },
};
</script>
