import { DateTime } from 'luxon';
import type { InstitutionRegion } from '@/store/map-store';
import { formatTwoDigits } from '@/utils/text';

export function institutionTimeNow(region: InstitutionRegion): DateTime {
  if (region === 'english') {
    return DateTime.utc().setZone('Europe/London');
  } else {
    return DateTime.utc().setZone('Australia/Sydney');
  }
}

export function institutionTimeTomorrowAsDate(region: InstitutionRegion): string {
  return institutionTimeNow(region).plus({ day: 1 }).toISODate();
}

export function formatDuration(hours: number, minutes: number) {
  const durationMinutes = minutes > 0 ? ` ${formatTwoDigits(minutes)}m` : '';
  return `${hours}h${durationMinutes}`;
}

export function formatTime(hours: number, minutes: number) {
  return `${hours || 0}:${formatTwoDigits(minutes || 0)}`;
}

export function splitTimeIntoHoursAndMinutes(time: string) {
  const [hours, minutes] = time.split(':');
  return { hours: parseInt(hours), minutes: parseInt(minutes) };
}
