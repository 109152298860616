<template>
  <div>
    <div class="d-flex" v-if="hasFilters">
      <div class="flex-grow-1" />
      <div class="clickable pr-2 pb-2" @click="resetFilters()">Clear Filters</div>
    </div>
    <v-table :fixed-header="fixedHeader">
      <thead>
        <tr>
          <th
            v-for="c in internalColumns"
            :key="c.key"
            class="px-2 pb-2"
            style="height: auto !important"
            :class="{
              'sticky-column': c.sticky,
            }"
            :style="{
              'min-width': !c.width ? props.minWidth : 'default',
              width: c.width || (paginatedRows.length === 0 ? props.minWidth : 'default') || 'default',
            }"
          >
            <div
              class="d-flex align-start"
              :style="{ cursor: !c.sort ? 'default' : 'pointer !important' }"
              v-on="c.sort ? { click: () => sortByColumn(c) } : {}"
            >
              <div class="flex-grow-1">
                <span @mouseover="onColumnHover($event, c.key)">
                  <v-tooltip v-if="tooltipColumnKeys.includes(c.key)" location="top">
                    <template #activator="{ props: tooltipProps }">
                      <span class="column-header-text" v-bind="tooltipProps">{{ c.name }}</span>
                    </template>
                    <span>{{ c.name }}</span>
                  </v-tooltip>
                  <span v-else class="column-header-text">{{ c.name }}</span>
                </span>
                <mosaic-help v-if="c.helpText">{{ c.helpText }}</mosaic-help>
                <mosaic-help v-if="c.helpTextHtml"><div v-html="c.helpTextHtml"></div></mosaic-help>
              </div>
              <div v-if="c.sort">
                <v-icon v-if="sortBy !== c.key">mdi-swap-vertical</v-icon>
                <v-icon v-else-if="sortDesc">mdi-arrow-down</v-icon>
                <v-icon v-else>mdi-arrow-up</v-icon>
              </div>
            </div>
          </th>
        </tr>
        <tr v-if="hasFilters">
          <th
            v-for="c in internalColumns"
            :key="c.key"
            class="px-2"
            :class="{
              'sticky-column': c.sticky,
            }"
          >
            <div v-if="c.filter" class="d-flex align-top">
              <v-text-field
                v-if="c.filter.type === 'text'"
                hide-details
                density="compact"
                :model-value="getFilterValue(c.key, filterValues)"
                @update:model-value="filterUpdated(c.key, $event)"
              />
              <v-select
                v-if="c.filter.type === 'select'"
                class="v-select-auto-width"
                :items="c.filter.items"
                hide-details
                density="compact"
                :model-value="getFilterValue(c.key, filterValues)"
                @update:model-value="filterUpdated(c.key, $event as string)"
              />
              <div v-if="c.filter.type === 'range'" class="d-flex align-center">
                <mosaic-text-field
                  :name="`${c.key}-min`"
                  hide-details
                  density="compact"
                  type="number"
                  :model-value="getMinRangeFilterValue(c.key)"
                  style="width: 30px"
                  no-icon
                  @update:model-value="minRangeFilterUpdated(c.key, $event)"
                />
                <span class="px-1">to</span>
                <mosaic-text-field
                  :name="`${c.key}-max`"
                  hide-details
                  density="compact"
                  type="number"
                  :model-value="getMaxRangeFilterValue(c.key)"
                  style="width: 30px"
                  no-icon
                  @update:model-value="maxRangeFilterUpdated(c.key, $event)"
                />
              </div>
              <div class="mt-2">
                <mosaic-icon class="ml-1" icon="filter" />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="paginatedRows.length === 0">
          <td colspan="100" class="text-start">
            There are no {{ props.objectTypePluralised }}
            <span v-if="rows.length === 0 && props.noDataSuffix"> {{ props.noDataSuffix }}</span
            ><span v-else>for these filters</span>
          </td>
        </tr>
        <tr v-for="row in paginatedRows" :key="row.id">
          <td
            v-for="c in props.columns"
            :key="`${row.id}-${c.key}`"
            class="px-2"
            :class="{
              'highlight-cell-on-hover': c.clickRoute && c.clickRoute(row),
              'sticky-column': c.sticky,
              'disable-row': row.disabled,
            }"
            :style="{
              cursor: c.clickRoute && c.clickRoute(row) ? 'pointer' : 'default',
            }"
            v-on="
              c.clickRoute && c.clickRoute(row)
                ? {
                    click: () => c.clickRoute && $router.push(c.clickRoute(row) || {}),
                  }
                : {}
            "
          >
            <div v-if="isTextColumn(c)">
              <div
                :class="
                  c.textColor && c.textColor(row) && !c.textColor(row).startsWith('#')
                    ? { ['text-' + c.textColor(row)]: true }
                    : {}
                "
                :style="{
                  color:
                    c.textColor && c.textColor(row) && c.textColor(row).startsWith('#') ? c.textColor(row) : 'default',
                  'white-space': noOverflow ? 'default' : 'nowrap',
                }"
                class="d-flex"
              >
                <div class="mr-1" v-if="c.prependHelpText && c.prependHelpText(row)">
                  <mosaic-help>{{ c.prependHelpText(row) }}</mosaic-help>
                </div>
                <span>{{ c.text(row) }}</span>
              </div>

              <div v-if="c.secondaryText" style="font-size: 0.75rem">{{ c.secondaryText(row) }}</div>
            </div>
            <div v-else-if="isEnumColumn(c) && c.chip && !c.chip(row).hide">
              <template v-if="c.chip(row).value">
                <v-tooltip v-if="c.chip(row).tooltip" location="top">
                  <template #activator="{ props: tooltipProps }">
                    <v-chip :color="c.chip(row).color" v-bind="tooltipProps">
                      <v-icon v-if="c.chip(row).icon" :color="c.chip(row).iconColor">{{ c.chip(row).icon }}</v-icon>
                      <span v-if="c.chip(row).text">{{ c.chip(row).text }}</span>
                    </v-chip>
                  </template>
                  <span>{{ c.chip(row).tooltip }}</span>
                </v-tooltip>
                <v-chip v-else :color="c.chip(row).color">
                  <v-icon v-if="c.chip(row).icon">{{ c.chip(row).icon }}</v-icon>
                  <span v-if="c.chip(row).text">{{ c.chip(row).text }}</span>
                </v-chip>
              </template>
              <div v-else-if="showNotAssigned" class="not-assigned-text">Not Assigned</div>
            </div>
            <div v-if="isNumberColumn(c)">
              <div
                :class="
                  c.valueColor && c.valueColor(row) && !c.valueColor(row).startsWith('#')
                    ? { ['text-' + c.valueColor(row)]: true }
                    : {}
                "
                :style="
                  c.valueColor && c.valueColor(row) && c.valueColor(row).startsWith('#')
                    ? { color: c.valueColor(row) }
                    : {}
                "
                class="d-flex align-center"
                style="white-space: nowrap"
              >
                <span>{{ c.value(row) }}</span>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </v-table>
    <mosaic-pagination
      v-model="currentPage"
      v-model:page-size="pageSize"
      class="mt-2"
      :total="paginationTotal"
      :include-top-margin="false"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { ref } from 'vue';
import type { Column, Row } from './mosaic-table';
import { isEnumColumn, isTextColumn, isNumberColumn } from './mosaic-table';
import { paginateList } from '@/components/library/pagination/pagination';

// - This is based off the table in TutorEctsOverviewPage, but hasn't been consolidated because of time/regression risk.
// - This component is missing URL syncing because we don't have a script setup implementation yet.
const props = withDefaults(
  defineProps<{
    columns: Column[];
    rows: Row[];
    objectTypePluralised: string;
    noDataSuffix?: string;
    minWidth?: string;
    showNotAssigned?: boolean;
    noOverflow?: boolean;
    fixedHeader?: boolean;
  }>(),
  { minWidth: '150px', showNotAssigned: false }
);

const internalColumns = computed(() =>
  props.columns.map(c => {
    let filter = c.filter || null;
    if (!filter && isTextColumn(c) && !c.noFilter) {
      filter = { type: 'text', value: c.text };
    }

    if (filter && !filter.value && isEnumColumn(c)) {
      filter.value = r => c.chip(r).value;
    }
    if (!filter && isNumberColumn(c)) {
      filter = { type: 'range', value: c.value };
    }
    return {
      ...c,
      filter,
      sort: c.sort || (isTextColumn(c) ? (x: Row) => c.text(x) : undefined),
    };
  })
);

const hasFilters = computed(() => internalColumns.value.some(c => c.filter));

const sortBy = ref(props.columns[0].key);
const sortDesc = ref(false);
function sortByColumn(c: { key: string }) {
  if (c.key === sortBy.value) {
    sortDesc.value = !sortDesc.value;
  } else {
    sortBy.value = c.key;
    sortDesc.value = false;
  }
}

// How can this by typed so that the key and value types match?
type RangeArray = [number | null, number | null];
type FilterValues = { key: string; value: string | null | RangeArray }[];

const filterDefaults = internalColumns.value
  .filter(c => c.filter)
  .map(c => {
    if (!c.filter)
      throw `Impossible error - Array.filter doesn't do type narrowing - could use a type guard, but this is easier`;
    const defaultValue = c.filter.type === 'text' ? '' : c.filter.type == 'range' ? ([0, null] as RangeArray) : null;
    return { key: c.key, value: defaultValue };
  });
const filterValues = ref(filterDefaults);

function resetFilters() {
  filterValues.value = filterDefaults;
}

// In the TutorEctOverviewsPage the columns are dynamic, so filterValues is a method argument to get reactivity to work
// This may end up being unnnecessary (or there now may be a better way to acheive this), but have kept for the time being
// Would separate functions for separate filter types be better?
function getFilterValue(key: string, filterValues: FilterValues) {
  const filter = filterValues.find(f => f.key === key);
  if (!filter) {
    console.log(key, filterValues);
    throw `Can't find filter for key ${key}`;
  }

  return filter.value;
}

function getMinRangeFilterValue(key: string) {
  const filterValue = getFilterValue(key, filterValues.value);
  return filterValue ? filterValue[0] : null;
}

function getMaxRangeFilterValue(key: string) {
  const filterValue = getFilterValue(key, filterValues.value);
  return filterValue ? filterValue[1] : null;
}

function minRangeFilterUpdated(key: string, value: number) {
  filterValues.value = filterValues.value.map(f => {
    if (f.key === key && Array.isArray(f.value)) {
      return { ...f, value: [value, f.value[1]] };
    }
    return f;
  });
}

function maxRangeFilterUpdated(key: string, value: number) {
  filterValues.value = filterValues.value.map(f => {
    if (f.key === key && Array.isArray(f.value)) {
      return { ...f, value: [f.value[0], value] };
    }
    return f;
  });
}

function filterUpdated(key: string, value: string) {
  filterValues.value = filterValues.value.map(f => {
    if (f.key === key) {
      return { ...f, value };
    }
    return f;
  });
}

const filteredAndSortedRows = computed(() => {
  const column = internalColumns.value.find(c => c.key === sortBy.value);
  if (!column) throw `Can't find column for key ${sortBy.value}`;
  const sortSelector = column.sort;
  if (!sortSelector) throw `Can't sort by ${column.key} as sort isn't defined for it`;

  const columnsWithFilters = internalColumns.value
    .filter(c => c.filter)
    .map(c => ({
      ...c,
      filterValue: getFilterValue(c.key, filterValues.value),
    }));

  return props.rows
    .filter(r =>
      columnsWithFilters.every(c => {
        if (!c.filter)
          throw `Impossible error - Array.filter doesn't do type narrowing - could use a type guard, but this is easier`;
        if (!c.filter.value) throw 'Filter specified, but it either needs a value or to be used';

        const columnValue = c.filter.value(r);
        if (c.filter.type === 'text' && typeof c.filterValue === 'string') {
          return columnValue
            .toString()
            .toLowerCase()
            .includes((c.filterValue || '').toLowerCase());
        }
        if (c.filter.type === 'select') {
          return c.filterValue === null || columnValue === c.filterValue;
        }
        if (c.filter.type === 'range' && Array.isArray(c.filterValue) && typeof columnValue === 'number') {
          return (
            (!c.filterValue[0] || columnValue >= c.filterValue[0]) &&
            (!c.filterValue[1] || columnValue <= c.filterValue[1])
          );
        }
        return true;
      })
    )
    .sortBy(sortSelector, sortDesc.value ? 'desc' : 'asc');
});

const tooltipColumnKeys = ref<string[]>([]);
function onColumnHover(event: MouseEvent, key: string) {
  const target = event.target as HTMLSpanElement;
  if (target.offsetHeight < target.scrollHeight) tooltipColumnKeys.value.push(key);
}

const { paginatedList: paginatedRows, currentPage, pageSize, paginationTotal } = paginateList(filteredAndSortedRows);
</script>

<style scoped>
.sticky-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 2;
}

.column-header-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  overflow: hidden;
  text-overflow: ellipsis;
}

.not-assigned-text {
  font-size: 0.75rem;
  color: #999;
  padding-top: 4px;
}

.clickable {
  text-decoration: underline;
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}

.disable-row {
  background-color: rgb(var(--v-theme-secondary-lighten-1));
}

td.highlight-cell-on-hover {
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
  border-top: 1px solid white !important;
  border-bottom: thin solid rgba(var(--v-border-color), var(--v-border-opacity));
}

td.highlight-cell-on-hover:hover {
  border: 1px solid rgba(0, 0, 0, 0.6) !important;
  border-radius: 4px;
}
</style>
