import moment from 'moment';
import api from '../entrypoints/application/api';
import { fromSnakeCaseToCamelCase } from '@/utils/transforms';
import { DateTime } from 'luxon';

const defaultState = {
  studentReviewPages: {
    error: null,
    busy: false,
    showCompletedModal: true,
  },
  studentReview: null,
};

const mutations = {
  updateStudentReviewPage(state, updates) {
    state.studentReviewPages = { ...state.studentReviewPages, ...updates };
  },
  clearStudentReview(state) {
    state.studentReview = null;
  },
  updateStudentReview(state, studentReview) {
    state.studentReview = { ...state.studentReview, ...studentReview };
  },
  updateStudentReviewOverall(state, { comments, statements, institution_judgement_descriptor_id, absence_count }) {
    state.studentReview.student_review_overall_comments = state.studentReview.student_review_overall_comments.map(x => {
      const c = comments.find(c => c.id === x.review_overall_comment_id);
      if (c) {
        return { ...x, comment: c.comment };
      }
      return x;
    });
    state.studentReview.student_review_overall_statements = state.studentReview.student_review_overall_statements.map(
      s => {
        const statement = statements.find(x => x.id === s.review_overall_statement_id);
        if (statement) {
          return { ...s, institution_judgement_descriptor_id: statement.institution_judgement_descriptor_id };
        }
        return s;
      }
    );
    if (institution_judgement_descriptor_id !== undefined) {
      state.studentReview.institution_judgement_descriptor_id = institution_judgement_descriptor_id;
    }
    if (absence_count !== undefined) {
      state.studentReview.absence_count = absence_count;
    }
  },
  updateStudentReviewStandard(state, standard) {
    state.studentReview.student_review_standard_comments = state.studentReview.student_review_standard_comments.map(
      x => {
        if (x.student_review_standard_id === standard.id) {
          const c = standard.comments.find(c => c.id === x.review_standard_comment_id);
          if (c) {
            return { ...x, comment: c.comment };
          }
        }
        return x;
      }
    );

    state.studentReview.student_review_statements = state.studentReview.student_review_statements.map(x => {
      const s = standard.statements.find(s => s.id === x.id);
      if (s) {
        return { ...x, institution_judgement_descriptor_id: s.institution_judgement_descriptor_id };
      }
      return x;
    });

    if (standard.institution_judgement_descriptor_id || standard.institution_judgement_descriptor_id === '') {
      state.studentReview.student_review_standards = state.studentReview.student_review_standards.map(x => {
        if (x.id === standard.id) {
          return { ...x, institution_judgement_descriptor_id: standard.institution_judgement_descriptor_id || null };
        }
        return x;
      });
    }
  },
  updateStudentReviewPart2Statements(state, statements) {
    state.studentReview.student_review_part2_statements = state.studentReview.student_review_part2_statements.map(x => {
      const s = statements.find(s => s.id === x.review_part2_statement_id);
      return {
        ...x,
        judgement_descriptor_id: s.judgement_descriptor_id,
        concerns_raised: s.concerns_raised,
        comment: s.comment,
      };
    });
  },
  addStudentReviewOverallTarget(state, target) {
    state.studentReview = { ...state.studentReview, targets: [...state.studentReview.targets, target] };
  },
  updateStudentReviewOverallTarget(state, target) {
    state.studentReview = {
      ...state.studentReview,
      targets: state.studentReview.targets.map(x => {
        if (x.id === target.id) {
          return { ...x, ...target };
        }
        return x;
      }),
    };
  },
  deleteStudentReviewOverallTarget(state, targetId) {
    state.studentReview = {
      ...state.studentReview,
      targets: state.studentReview.targets.filter(x => x.id !== targetId),
    };
  },
  addStudentReviewStandardTarget(state, { studentReviewStandardId, target }) {
    const student_review_standards = state.studentReview.student_review_standards.map(srs => {
      if (srs.id === studentReviewStandardId) {
        return {
          ...srs,
          targets: [...srs.targets, target],
        };
      }
      return srs;
    });
    state.studentReview = {
      ...state.studentReview,
      student_review_standards,
    };
  },
  updateStudentReviewStandardTarget(state, { studentReviewStandardId, target }) {
    const student_review_standards = state.studentReview.student_review_standards.map(srs => {
      if (srs.id === studentReviewStandardId) {
        return {
          ...srs,
          targets: srs.targets.map(x => {
            if (x.id === target.id) {
              return { ...x, ...target };
            }
            return x;
          }),
        };
      }
      return srs;
    });
    state.studentReview = {
      ...state.studentReview,
      student_review_standards,
    };
  },
  deleteStudentReviewStandardTarget(state, { studentReviewStandardId, targetId }) {
    const student_review_standards = state.studentReview.student_review_standards.map(srs => {
      if (srs.id === studentReviewStandardId) {
        return {
          ...srs,
          targets: srs.targets.filter(x => x.id !== targetId),
        };
      }
      return srs;
    });
    state.studentReview = {
      ...state.studentReview,
      student_review_standards,
    };
  },
  submitStudentReview(state, { roleIds }) {
    state.studentReview = {
      ...state.studentReview,
      student_review_submissions: state.studentReview.student_review_submissions.concat(
        roleIds.map(roleId => ({ role_id: roleId, submitted_at: DateTime.now().toISO() }))
      ),
    };
  },
  undoSubmissionStudentReview(state, { roleIds }) {
    const roleIdSet = new Set(roleIds);
    const filteredSubmissions = state.studentReview.student_review_submissions.filter(
      rev => !roleIdSet.has(rev.role_id)
    );
    state.studentReview.student_review_submissions = filteredSubmissions;
  },
};

const actions = {
  loadStudentReview({ commit, state, dispatch }, { id, force }) {
    if (state.studentReviewPages.error) {
      commit('updateStudentReviewPage', { error: null });
    }

    if (!force && state.studentReview && state.studentReview.id === parseInt(id)) return;
    commit('updateStudentReviewPage', { busy: true, showCompletedModal: showStudentReviewCompletedModal(id) });
    return dispatch('loadRoles').then(() => loadStudentReview(commit, `/student-reviews/${id}`));
  },
  dontShowStudentReviewCompletedModal({ state, commit }) {
    if (!state.studentReview) return;
    commit('updateStudentReviewPage', { showCompletedModal: false });
    const completedMap = getCompletedMap();
    completedMap[state.studentReview.id] = moment().toISOString();
    localStorage.setItem('studentReviewCompletedModal', JSON.stringify(completedMap));
  },
  studentReviewLogout() {
    localStorage.removeItem('studentReviewCompletedModal');
  },
};

function showStudentReviewCompletedModal(studentReviewId) {
  const completedMap = getCompletedMap();
  const lastShowDateTime = completedMap[studentReviewId];
  if (!lastShowDateTime) return true;

  return moment(lastShowDateTime).isBefore(moment().subtract(1, 'day'));
}

function getCompletedMap() {
  const completedMapString = localStorage.getItem('studentReviewCompletedModal');
  let completedMap = {};
  try {
    if (completedMapString) {
      completedMap = JSON.parse(completedMapString);
    }
  } catch (e) {
    console.log('Failed to parse studentReviewCompleted from local storage', e);
  }
  return completedMap;
}

async function loadStudentReview(commit, url) {
  try {
    const response = await api.get(url);
    commit('updateStudentReview', response.data);
    commit('updateStudentReviewPage', { busy: false });
  } catch (e) {
    console.log(e);
    commit('updateStudentReviewPage', { error: 'Sorry, cannot load your review point at the moment.', busy: false });
  }
}

const getters = {
  studentReviewForStaff(state, getters) {
    const studentReview = state.studentReview;
    if (!studentReview) return null;

    const reviewStandards = [
      createOverallReviewStandard(state, {
        name: 'TutorReviewOverallPage',
        params: { studentId: state.selectedStudent.id, id: studentReview.id },
      }),
    ].concat(
      studentReview.review.review_standards
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .filter(x => {
          const studentReviewStandard = studentReview.student_review_standards.find(
            srs => srs.review_standard_id === x.id
          );
          // Won't exist if subjects don't match
          return !!studentReviewStandard;
        })
        .map(x => {
          const studentReviewStandard = studentReview.student_review_standards.find(
            srs => srs.review_standard_id === x.id
          );
          return createStudentReviewStandard(
            state,
            x,
            {
              name: 'TutorReviewStandardPage',
              params: {
                studentId: state.selectedStudent.id,
                id: studentReview.id,
                standardId: studentReviewStandard.id,
              },
            },
            getters
          );
        })
    );
    if (studentReview.review.use_part2) {
      reviewStandards.push({
        id: 'part2',
        title: 'Part 2 - Personal and Professional Conduct',
        clickRoute: {
          name: 'TutorReviewPart2Page',
          params: { studentId: state.selectedStudent.id, id: studentReview.id },
        },
      });
    }
    return createStudentReview(state, reviewStandards, getters);
  },
  studentReviewForTrainee(state, getters) {
    const studentReview = state.studentReview;
    if (!studentReview) return null;
    const reviewStandards = [
      createOverallReviewStandard(state, {
        name: 'StudentReviewOverallPage',
        params: { id: studentReview.id },
      }),
    ].concat(
      studentReview.review.review_standards
        .sort((a, b) => (a.order > b.order ? 1 : -1))
        .filter(x => {
          const studentReviewStandard = studentReview.student_review_standards.find(
            srs => srs.review_standard_id === x.id
          );
          // Won't exist if subjects don't match
          return !!studentReviewStandard;
        })
        .map(x => {
          const studentReviewStandard = studentReview.student_review_standards.find(
            srs => srs.review_standard_id === x.id
          );
          return createStudentReviewStandard(
            state,
            x,
            {
              name: 'StudentReviewStandardPage',
              params: { id: studentReview.id, standardId: studentReviewStandard.id },
            },
            getters
          );
        })
    );
    if (studentReview.review.use_part2) {
      reviewStandards.push({
        id: 'part2',
        title: 'Part 2 - Personal and Professional Conduct',
        clickRoute: {
          name: 'StudentReviewPart2Page',
          params: { id: studentReview.id },
        },
      });
    }
    return createStudentReview(state, reviewStandards, getters);
  },
};

function createOverallReviewStandard(state, clickRoute) {
  return {
    id: 'overall',
    title: 'General',
    clickRoute,
    judgementDescriptor: state.studentReview.review.review_overall_institution_judgement_set
      ? state.studentReview.review.review_overall_institution_judgement_set?.institution_judgement_descriptors.find(
          x => x.id === state.studentReview.institution_judgement_descriptor_id
        ) || {
          color: 'secondary',
          descriptor: 'Not Recorded',
          id: null,
          order: null,
        }
      : null,
    canAddTargets: state.studentReview.review.can_add_targets_overall,
    targetCount: state.studentReview.targets.length,
  };
}

function createStudentReviewStandard(state, reviewStandard, clickRoute, getters) {
  // This is repeated, which feels like a smell that these functions need refactoring
  const roleIdsThatAreContributors = roleIdsWithPermission(state, 'Contributor');
  const roleIdsThatCanEditJudgements = roleIdsWithPermission(state, 'StandardJudgement');
  const roleIdsThatCanEditStatementJudgements = roleIdsWithPermission(state, 'StandardStatementJudgement');

  const studentReview = state.studentReview;
  const studentReviewStandard = studentReview.student_review_standards.find(
    srs => srs.review_standard_id === reviewStandard.id
  );
  const rs = {
    id: studentReviewStandard.id,
    title: `${reviewStandard.code} - ${reviewStandard.name}`,
    code: reviewStandard.code,
    clickRoute,
    canAddTargets: state.studentReview.review.can_add_targets_per_standard,
    targetCount: studentReviewStandard.targets.length,
    targets: studentReviewStandard.targets,
    judgementDescriptorId: studentReviewStandard.institution_judgement_descriptor_id,
    judgementDescriptor: state.studentReview.review.review_standard_institution_judgement_set
      ? state.studentReview.review.review_standard_institution_judgement_set?.institution_judgement_descriptors.find(
          x => x.id === studentReviewStandard.institution_judgement_descriptor_id
        ) || {
          color: 'secondary',
          descriptor: 'Not Recorded',
          id: null,
          order: null,
        }
      : null,
    note: reviewStandard.note,
    statementGroups: studentReview.review.review_statement_groups
      .filter(
        g =>
          (g.standard_id && g.standard_id === reviewStandard.standard_id) ||
          (g.substandard_id && g.substandard_id === reviewStandard.substandard_id)
      )
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map(g => ({
        id: g.id,
        name: g.name,
        statements: g.review_statements
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map(s => {
            const studentReviewStatement = studentReview.student_review_statements.find(
              x => x.review_statement_id === s.id
            );
            return {
              id: s.id,
              statement: s.statement,
              guidance: s.guidance,
              studentReviewStatementId: studentReviewStatement.id,
              judgementDescriptorId: studentReviewStatement.institution_judgement_descriptor_id,
              previousJudgementDescriptorId: studentReviewStatement.previous_institution_judgement_descriptor_id,
            };
          }),
      })),
    standardComments: studentReview.review.review_standard_comments
      .sort((a, b) => (a.order > b.order ? 1 : -1))
      .map(c => ({
        id: c.id,
        label: c.label,
        placeholder: c.placeholder,
        optional: c.optional,
        comment: studentReview.student_review_standard_comments.find(
          x => x.review_standard_comment_id === c.id && x.student_review_standard_id === studentReviewStandard.id
        ).comment,
        canTraineeEdit: c.can_trainee_edit,
        roleIdsThatCanEdit: state.roles
          .filter(
            r =>
              studentReview.review.review_permissions.find(
                x => x.review_standard_comment_id == c.id && x.role_id === r.id
              )?.edit || false
          )
          .map(r => r.id),
      })),
  };

  const completed = [];
  if (studentReview.review.is_trainee_contributor && rs.standardComments.filter(x => x.canTraineeEdit).length > 0) {
    completed.push({
      completed: rs.standardComments.filter(x => x.canTraineeEdit).every(x => x.comment && x.comment !== x.placeholder),
      name: getters.traineeNounCapitalised(),
      trainee: true,
      roleId: 'student',
    });
  }

  rs.completed = [
    ...completed,
    ...roleIdsThatAreContributors
      .filter(x => {
        return (
          rs.standardComments.filter(c => c.roleIdsThatCanEdit.includes(x)).length > 0 ||
          (studentReview.review.review_standard_institution_judgement_set &&
            roleIdsThatCanEditJudgements.includes(x)) ||
          (studentReview.review.review_standard_statement_institution_judgement_set &&
            roleIdsThatCanEditStatementJudgements.includes(x) &&
            rs.statementGroups.length > 0)
        );
      })
      .map(x => {
        return {
          completed:
            (rs.standardComments
              .filter(c => c.roleIdsThatCanEdit.includes(x))
              .every(x => x.comment && x.comment !== x.placeholder) &&
              (!studentReview.review.review_standard_institution_judgement_set ||
                !roleIdsThatCanEditJudgements.includes(x) ||
                rs.judgementDescriptorId) &&
              (!studentReview.review.review_standard_statement_institution_judgement_set ||
                !roleIdsThatCanEditStatementJudgements.includes(x) ||
                rs.statementGroups.every(sg => sg.statements.every(s => s.judgementDescriptorId)))) ||
            false,
          name: state.roles.find(r => r.id === x).name,
          roleId: x,
        };
      }),
  ];

  return rs;
}

function createStudentReview(state, reviewStandards, getters) {
  const studentReview = state.studentReview;
  const roleIdsThatAreContributors = roleIdsWithPermission(state, 'Contributor');
  const roleIdsThatCanEditPart2 = roleIdsWithPermission(state, 'Part2');
  const roleIdsThatCanEditOverallJudgement = roleIdsWithPermission(state, 'OverallJudgement');

  const overallComments = studentReview.review.review_overall_comments
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(c => {
      const permissions = studentReview.review.review_permissions.filter(x => x.review_overall_comment_id === c.id);
      const overallComment = studentReview.student_review_overall_comments.find(
        x => x.review_overall_comment_id === c.id
      );
      return {
        id: c.id,
        label: c.label,
        placeholder: c.placeholder,
        optional: c.optional,
        comment: overallComment.comment,
        canTraineeEdit: c.can_trainee_edit,
        roleIdsThatCanEdit: state.roles
          .filter(r => permissions.find(x => x.role_id === r.id)?.edit || false)
          .map(r => r.id),
      };
    });

  const overallStatementGroups = studentReview.review.review_overall_statement_groups
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(g => {
      return {
        id: g.id,
        name: g.name,
        statements: g.review_overall_statements
          .map(s => {
            const statement = studentReview.student_review_overall_statements.find(
              x => x.review_overall_statement_id === s.id
            );
            const permissions = studentReview.review.review_permissions.filter(
              x => x.review_overall_statement_id === s.id
            );
            return {
              id: s.id,
              statement: s.statement,
              order: s.order,
              judgementDescriptorId: statement.institution_judgement_descriptor_id,
              canTraineeView: s.can_trainee_view,
              canTraineeEdit: s.can_trainee_edit,
              roleIdsThatCanView: state.roles
                .filter(r => permissions.find(x => x.role_id === r.id)?.view || false)
                .map(r => r.id),
              roleIdsThatCanEdit: state.roles
                .filter(r => permissions.find(x => x.role_id === r.id)?.edit || false)
                .map(r => r.id),
            };
          })
          .sort((a, b) => (a.order > b.order ? 1 : -1)),
      };
    });
  let overallReviewCompleted = [];
  const overallReviewStandard = reviewStandards.find(x => x.id === 'overall');
  if (overallReviewStandard) {
    if (
      studentReview.review.is_trainee_contributor &&
      (overallComments.filter(x => x.canTraineeEdit).length > 0 ||
        overallStatementGroups
          .map(x => x.statements)
          .flat()
          .filter(x => x.canTraineeEdit).length > 0)
    ) {
      overallReviewCompleted.push({
        completed:
          overallComments.filter(x => x.canTraineeEdit).every(x => x.comment && x.comment !== x.placeholder) &&
          overallStatementGroups
            .map(x => x.statements)
            .flat()
            .filter(x => x.canTraineeEdit)
            .every(x => x.judgementDescriptorId),
        name: getters.traineeNounCapitalised(),
        trainee: true,
        roleId: 'student',
      });
    }

    overallReviewCompleted = [
      ...overallReviewCompleted,
      ...roleIdsThatAreContributors
        .filter(
          x =>
            overallComments.some(c => c.roleIdsThatCanEdit.includes(x)) ||
            overallStatementGroups
              .map(x => x.statements)
              .flat()
              .some(s => s.roleIdsThatCanEdit.includes(x)) ||
            roleIdsThatCanEditOverallJudgement.includes(x)
        )
        .map(x => {
          return {
            completed:
              overallComments
                .filter(c => c.roleIdsThatCanEdit.includes(x))
                .every(x => x.comment && x.comment !== x.placeholder) &&
              overallStatementGroups
                .map(x => x.statements)
                .flat()
                .filter(s => s.roleIdsThatCanEdit.includes(x))
                .every(s => s.judgementDescriptorId) &&
              (!studentReview.review.review_overall_institution_judgement_set ||
                !roleIdsThatCanEditOverallJudgement.includes(x) ||
                !!studentReview.institution_judgement_descriptor_id),
            name: state.roles.find(r => r.id === x).name,
            roleId: x,
          };
        }),
    ];

    overallReviewStandard.completed = overallReviewCompleted;
  }

  const part2Statements = studentReview.review.review_part2_statements
    .sort((a, b) => (a.order > b.order ? 1 : -1))
    .map(s => {
      const statement = studentReview.student_review_part2_statements.find(x => x.review_part2_statement_id === s.id);
      return {
        id: s.id,
        statement: s.statement,
        judgementDescriptorId: statement.judgement_descriptor_id,
        comment: statement.comment,
        concernsRaised: statement.concerns_raised,
      };
    });

  let part2Completed = [];
  const part2 = reviewStandards.find(x => x.id === 'part2');
  if (part2) {
    part2Completed = roleIdsThatAreContributors
      .filter(x => roleIdsThatCanEditPart2.includes(x))
      .map(x => ({
        completed: part2Statements.every(s => s.judgementDescriptorId),
        name: state.roles.find(r => r.id === x).name,
        roleId: x,
      }));

    part2.completed = part2Completed;
  }

  reviewStandards.forEach((rs, i) => {
    if (i !== reviewStandards.length - 1) {
      rs.next = reviewStandards[i + 1].clickRoute;
    }

    if (i !== 0) {
      rs.previous = reviewStandards[i - 1].clickRoute;
    }
  });

  return {
    id: studentReview.id,
    ectSchool: studentReview.ect_school,
    isEarlyCareers: studentReview.is_early_careers,
    approvedAt: studentReview.approved_at,
    approved: !!studentReview.approved_at,
    studentSubmittedAt: studentReview.student_submitted_at,
    name: studentReview.review.name,
    previousStudentReviewId: studentReview.previous_student_review_id,
    judgementDescriptorId: studentReview.institution_judgement_descriptor_id,
    absenceCount: studentReview.absence_count,
    dueDate: studentReview.due_date || studentReview.review.due_date,
    review: {
      dueDate: studentReview.review.due_date,
      canAddTargetsOverall: studentReview.review.can_add_targets_overall,
      canTraineeAddTargetsOverall: studentReview.review.can_trainee_add_targets_overall,
      canAddTargetsPerStandard: studentReview.review.can_add_targets_per_standard,
      canTraineeAddTargetsPerStandard: studentReview.review.can_trainee_add_targets_per_standard,
      canTraineesEditStandardStatementJudgements: studentReview.review.can_trainees_edit_standard_statement_judgements,
      canTraineesViewJudgements: studentReview.review.can_trainees_view_judgements,
      canTraineesViewOverallJudgement: studentReview.review.can_trainees_view_overall_judgement,
      canTraineeRecordAbsences: studentReview.review.can_trainee_record_absences,
      // casing needed as review template looks directly as judgementSets in store, which is camel case from api
      overallJudgementSet: fromSnakeCaseToCamelCase(studentReview.review.review_overall_institution_judgement_set),
      standardJudgementSet: fromSnakeCaseToCamelCase(studentReview.review.review_standard_institution_judgement_set),
      standardStatementJudgementSet: fromSnakeCaseToCamelCase(
        studentReview.review.review_standard_statement_institution_judgement_set
      ),
      overallStatementJudgementSet: fromSnakeCaseToCamelCase(
        studentReview.review.review_overall_statement_institution_judgement_set
      ),
      completionWindowInWeeks:
        state.reviewTypeItems.find(x => x.value === studentReview.review.review_type)?.completionWindowInWeeks || null,
      canTraineeViewReview: studentReview.review.can_trainee_view_review,
      isTraineeContributor: studentReview.review.is_trainee_contributor,
      overallNote: studentReview.review.overall_note,
      overallAssessmentGuidance: studentReview.review.overall_assessment_guidance,
      standardNoun: studentReview.review.standard_noun,
      usePart2: studentReview.review.use_part2,
      recordAbsences: studentReview.review.record_absences,
      roleIdsThatCanEditOverallJudgement,
      roleIdsThatCanEditJudgements: roleIdsWithPermission(state, 'StandardJudgement'),
      roleIdsThatCanEditStatementJudgements: roleIdsWithPermission(state, 'StandardStatementJudgement'),
      roleIdsThatCanEditPart2,
      roleIdsThatCanAddTargetsOverall: roleIdsWithPermission(state, 'AddTargetsOverall'),
      roleIdsThatCanAddTargetsPerStandard: roleIdsWithPermission(state, 'AddTargetsPerStandard'),
      roleIdsThatAreContributors,
      roleIdsThatCanApprove: roleIdsWithPermission(state, 'Approve'),
      roleIdsThatCanViewReview: roleIdsWithPermission(state, 'Viewer'),
      rolesIdsThatCanRecordAbsences: roleIdsWithPermission(state, 'RecordAbsences'),
      useStPhilipsLegacyPart2JudgementSet: [2745, 2746, 2747, 2748].includes(studentReview.review.id), // St. Philip's reviews that were migrated from legacy reviews
    },
    submissions: studentReview.student_review_submissions.map(s => ({
      id: s.id,
      roleId: s.role_id,
      submittedAt: s.submitted_at,
      submitterId: s.submitter_id,
    })),
    targets: studentReview.targets,
    overallComments,
    overallReviewCompleted,
    overallStatementGroups,
    part2Statements,
    part2Completed,
    reviewStandards,
  };
}

function roleIdsWithPermission(state, permission) {
  return state.roles
    .filter(
      r =>
        state.studentReview.review.review_permissions
          .filter(x => x.permission_type === permission)
          .find(x => x.role_id === r.id)?.edit || false
    )
    .map(r => r.id);
}

export default {
  defaultState,
  mutations,
  actions,
  getters,
};
