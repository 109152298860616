<template>
  <div>
    <mosaic-loading-and-error-cards object-type="Training Module" :load="load">
      <mosaic-card v-if="notAssignedError">
        <v-card-text>
          <div class="pa-2">
            It looks like this module is no longer a requirement for you. Please click
            <span class="clickable" @click="reloadStaffTraining">here</span> to reload your requirements.
          </div>
        </v-card-text>
      </mosaic-card>
      <staff-training-module-card
        v-else-if="staffTrainingCompletion && sectionId"
        v-model:staff-training-completion="staffTrainingCompletion"
        v-model:section-id="sectionId"
        :readonly="staffId != userStaff.id"
        @click-section="onClickSection($event)"
        @update:dirty="dirty = $event"
        @update:staff-training-completion="loadStaffTraining(staffId, true)"
      />
    </mosaic-loading-and-error-cards>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Training Module Page"
      :custom-leave-action="changeSection"
    />
  </div>
</template>

<script setup lang="ts">
import {
  mapStaffTrainingModuleToSectionCompletions,
  type StaffTrainingModuleCompletionResponse,
} from './staff-training';
import StaffTrainingModuleCard from './StaffTrainingModuleCard.vue';
import { myStaffTrainingBreadcrumbs, selectedStaffTrainingBreadcrumbs, useStaffTraining } from './staff-training';
import { computed, ref } from 'vue';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { mapState } from '@/store/map-store';
import { useRoute, useRouter } from 'vue-router';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { isAxiosError } from 'axios';
import { useApi } from '@/composables/api';
import { parseRouteId } from '@/composables/vue-router';
import { useStaffTrainingStore } from '@/stores/staff-training';

// #region setup
const route = useRoute();
const router = useRouter();
const api = useApi();
const { userStaff, selectedInstitution } = mapState();
const { staff, staffId, isMyStaffTraining } = useStaffTraining();
const {
  actions: { loadStaffTraining },
} = useStaffTrainingStore();
const moduleId = parseRouteId('moduleId');

const dirty = ref(false);
const { dialog } = useUnsavedChanges(dirty);

const notAssignedError = ref(false);
const sectionId = ref<number>();
const onClickSectionId = ref<number>();
const staffTrainingCompletion = ref<StaffTrainingModuleCompletionResponse>();

// #endregion

// #region breadcrumbs
const breadcrumbs = computed(() => {
  return [
    ...(isMyStaffTraining.value
      ? myStaffTrainingBreadcrumbs('training-modules')
      : selectedStaffTrainingBreadcrumbs(
          'training-modules',
          selectedInstitution.value.id,
          staffId.value,
          route,
          staff.value?.user.name
        )),
    {
      text: staffTrainingCompletion.value?.staffTrainingModule.name || '',
    },
  ];
});
setBreadcrumbs(breadcrumbs);
// #endregion

// #region load
async function load() {
  try {
    const r = await api.get<StaffTrainingModuleCompletionResponse>(
      `/staff/${staffId.value}/training-modules/${moduleId.value}/completion`
    );
    staffTrainingCompletion.value = r.data;
    if (!staffTrainingCompletion.value.staffTrainingModuleCompletion) {
      staffTrainingCompletion.value.staffTrainingModuleCompletion = {
        id: -1,
        staffId: staffId.value,
        staffTrainingModuleSectionCompletions: mapStaffTrainingModuleToSectionCompletions(r.data.staffTrainingModule),
      };
    }

    setSectionId();
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404 && e.response.data.error_code === 'not_assigned') {
      notAssignedError.value = true;
    } else {
      throw e;
    }
  }
}

function setSectionId() {
  if (!staffTrainingCompletion.value) return;
  for (const sectionCompletion of staffTrainingCompletion.value.staffTrainingModuleCompletion
    .staffTrainingModuleSectionCompletions) {
    if (!sectionCompletion.completed) {
      sectionId.value = sectionCompletion.staffTrainingModuleSectionId;
      break;
    }
  }
  if (!sectionId.value) {
    sectionId.value = staffTrainingCompletion.value.staffTrainingModule.staffTrainingModuleSections[0].id;
  }
}

function reloadStaffTraining() {
  loadStaffTraining(staffId.value, true);
  // This doesn't work on page refresh and should be changed to a static value with query overrides (if necessary)
  router.go(-1);
}
// #endregion

function onClickSection(id: number) {
  if (id === sectionId.value) return;
  onClickSectionId.value = id;
  if (dirty.value) {
    dialog.value = {
      active: true,
      to: null,
      leaving: false,
      navigatingToLogin: false,
    };
  } else {
    changeSection();
  }
}

function changeSection() {
  sectionId.value = onClickSectionId.value!;
  onClickSectionId.value = undefined;
  dialog.value = {
    active: false,
    to: null,
    leaving: false,
    navigatingToLogin: false,
  };
}
</script>

<style scoped>
.clickable {
  text-decoration: underline;
  color: rgb(var(--v-theme-primary));
  cursor: pointer;
}
</style>
