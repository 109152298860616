<template>
  <files-list
    :load-items="loadItems"
    files-storage-type="Mosaic"
    :get-file-url="getFileUrl"
    :move-item="moveItem"
    :can-add-as-evidence="false"
    :page-breadcrumbs="breadcrumbs"
  />
</template>

<script>
import FilesList from '../../components/files-list/FilesList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorAdminStudentCourseFilesPage',
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: `${this.traineeNounCapitalised()} Course Files`,
          to: {
            name: 'TutorAdminStudentCourseFilesPage',
          },
        },
      ];
    },
  },
  methods: {
    async loadItems(folder) {
      const response = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/course-files/${encodeURIComponent(folder.id)}`
      );

      // Add editable at the API instead
      return {
        items: response.data.items.map(x => ({
          ...x,
          sortName: x.name,
          editable: true,
          isMoveTarget: x.type === 'folder',
          icon: {
            name: this.getIconName(x),
            color: null,
            tooltip: '',
          },
          filesApiPath: `/institutions/${this.selectedInstitution.id}/course-files`,
        })),
        folder: {
          id: folder.id || response.data.folder.id,
          name: folder.name || response.data.folder.name,
          isRootFolder: folder.isRootFolder || false,
          editable: true,
          uploadDirectToExternalStorage: true,
          filesApiPath: `/institutions/${this.selectedInstitution.id}/course-files`,
          presignFilesApiPath: `/presign/institutions/${this.selectedInstitution.id}/course-files`,
          folderApiPath: `/institutions/${this.selectedInstitution.id}/course-folders`,
        },
      };
    },
    async getFileUrl(item, download) {
      const r = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/course-file/${encodeURIComponent(item.id)}?download=${download}`
      );
      return r.data.webUrl;
    },
    getIconName(item) {
      return item.type === 'file' ? 'file' : 'folder-home';
    },
    async moveItem(item, destinationFolder, currentFolder) {
      await this.$api.post(
        `/institutions/${this.selectedInstitution.id}/course-files/${encodeURIComponent(item.id)}/move-item`,
        {
          destinationId: destinationFolder.id,
          currentParentId: currentFolder.id,
        }
      );
    },
  },
  components: { FilesList },
};
</script>
