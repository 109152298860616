<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex align-center">
          <v-text-field
            v-model="searchTerm"
            density="compact"
            hide-details
            variant="outlined"
            label="Filter Subjects"
            prepend-inner-icon="mdi-magnify"
          />
          <div class="flex-grow-1"></div>
          <div class="pl-2 flex-grow-1">
            <v-text-field
              v-model="newSubject"
              class="pr-4"
              label="New Subject"
              prepend-inner-icon="mdi-book-education"
            />
          </div>
          <v-btn ripple :disabled="!newSubject" @click.prevent="addSubject()">
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Add</span>
            </div>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-text>
        <mosaic-snackbar v-model="snackbar.status" :color="snackbar.color" :message="snackbar.message" />
        <v-list>
          <v-list-item v-for="subject in filteredSubjects" :key="subject.id" ripple>
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-book-education</v-icon>
              </v-avatar>
            </template>

            {{ subject.name }}
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'AdminSubjectsPage',
  data: function () {
    return {
      subjects: [],
      searchTerm: '',
      newSubject: '',
      snackbar: {
        message: '',
        status: false,
        color: '',
      },
    };
  },
  computed: {
    breadcrumbs() {
      return [{ text: 'Subjects' }];
    },
    filteredSubjects() {
      return this.subjects.filter(s => s.name.toLowerCase().includes(this.searchTerm.toLowerCase()));
    },
  },
  async created() {
    await this.getSubjects();
  },
  methods: {
    async getSubjects() {
      const response = await this.$api.get(`/subjects`);
      this.subjects = response.data;
    },
    async addSubject() {
      try {
        await this.$api.post(`subjects`, { name: this.newSubject });
        this.getSubjects();
        this.snackbar = {
          message: 'Success',
          status: true,
          color: 'success',
        };
        this.newSubject = '';
      } catch (e) {
        console.log(e);
        this.snackbar = {
          message: (e.response && e.response.data && e.response.data.message) || `Sorry, cannot add subject right now`,
          status: true,
          color: 'error',
        };
      }
    },
  },
};
</script>
