<template>
  <div>
    <legacy-mosaic-inline-creation-list-page
      object-type="Competency Theme"
      :title="name"
      :load="load"
      :items="themeItems"
      :icon="icons.instructorTrainingCompetencyTheme"
      :hide-add="false"
      :can-add-item="canAddTheme"
      :add-item="addThemeItem"
      :can-retitle-item="canRetitleTheme"
      :retitle-item-dialog-opened="retitleTheme"
      :retitle-item="saveRetitleTheme"
      :delete-url-stem="deleteUrlStem"
      :move-item="moveThemeListItem"
      @after-update="afterUpdate()"
    >
      <template #subtitle>
        <div v-if="framework && framework.status === 'published'">
          This Framework is published to the following Cohorts:
          <span v-for="c in framework.publishedToCohorts" :key="c.id">
            <mosaic-router-link :to="{ name: 'TutorCohortStaffTrainingPage', params: { cohortId: c.id.toString() } }">{{
              c.name
            }}</mosaic-router-link
            >,
          </span>
          for the following roles:
          <span v-for="(r, index) in framework.publishedToRoles" :key="r.id">
            {{ r.name }}
            <span v-if="index !== framework.publishedToRoles.length - 1">, </span>
          </span>
        </div>
      </template>
      <template #add-item-fields>
        <mosaic-text-field
          v-model="newThemeCode"
          name="add-theme-code"
          label="Code"
          prepend-icon="mdi-pencil"
          style="width: 100px"
        />
        <mosaic-text-field
          v-model="newThemeName"
          name="add-theme-name"
          label="Name"
          prepend-icon="mdi-pencil"
          class="flex-grow-1 pl-2"
        />
      </template>

      <template #retitle-item-fields="{ onKeyupEnter }">
        <div class="d-flex align-center">
          <mosaic-text-field
            v-model="renameDialog.code"
            name="rename-theme-code"
            label="Code"
            prepend-icon="mdi-pencil"
            style="width: 100px"
          />
          <mosaic-text-field
            v-model="renameDialog.name"
            name="rename-theme-name"
            label="Name"
            prepend-icon="mdi-pencil"
            class="ml-2 flex-grow-1"
            @keyup.enter="onKeyupEnter"
          />
        </div>
      </template>
    </legacy-mosaic-inline-creation-list-page>
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import type { Item } from '@/components/library/mosaic-list/list-item-helpers';
import { useMoveListItem, useRetitleListItem } from '@/components/library/mosaic-list/list-item-helpers';
import { enumerateItems } from '@/utils/text';
import { parseRouteId } from '@/composables/vue-router';
import { useApi } from '@/composables/api';
import { icons } from '@/utils/icons';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { useStaffStore } from '@/stores/staff';
import { useInstitutionStaffTrainingStore } from '@/stores/institution-staff-training';

const api = useApi();
const { userStaff } = useStaffStore();
const {
  actions: { loadStaffTraining },
} = useStaffTrainingStore();
const {
  actions: { clearInstitutionStaffTrainingFrameworks },
} = useInstitutionStaffTrainingStore();

const frameworkId = parseRouteId('id');

type StaffTrainingFramework = {
  id: number;
  name: string;
  status: 'published' | 'draft';
  staffTrainingCompetencyThemes: Theme[];
  publishedToCohorts: {
    id: number;
    name: string;
  }[];
  publishedToRoles: {
    id: number;
    name: string;
  }[];
};

type Theme = {
  id: number;
  order: number;
  name: string;
  code: string;
  allCompetenciesHaveRequirements: boolean;
  staffTrainingCompetencies: {
    id: number;
  }[];
};

const framework = ref<StaffTrainingFramework | null>(null);
const name = computed(() => framework.value?.name || '');

async function load() {
  const r = await api.get<StaffTrainingFramework>(`/staff-training/frameworks/${frameworkId.value}`);
  framework.value = r.data;
}

setBreadcrumbs(
  computed(() => [
    { text: 'Instructors', to: { name: 'TutorStaffListPage' } },
    { text: 'Training', to: { name: 'InstitutionStaffTrainingPage' } },
    {
      text: `Competency Frameworks`,
      to: {
        name: 'InstitutionStaffTrainingPage',
        query: { tab: 'frameworks' },
      },
    },
    {
      text: name.value,
    },
  ])
);

//Transform Themes to Simple List Items

const themeItems = computed(() => {
  let themes: Item[] = [];
  if (framework.value) {
    themes = framework.value.staffTrainingCompetencyThemes
      .map(t => ({
        id: t.id,
        order: t.order,
        title: `${t.code} - ${t.name}`,
        subtitle: enumerateItems(t.staffTrainingCompetencies.length, 'Competency'),
        warningMessage:
          framework.value!.status === 'published'
            ? t.staffTrainingCompetencies.length === 0
              ? 'This Theme has no Competencies, but the Framework is published to Instructors. This may cause confusion.'
              : !t.allCompetenciesHaveRequirements
              ? 'Some of the Competencies in this Theme have no Training Module, Certificate or published Event requirements. These Competencies will still show to Instructors assigned to this Framework, but they will only be able to meet it through Manual Training Records.'
              : ''
            : '',
        hideMove: false,
        disableDelete: t.staffTrainingCompetencies.length > 0,
        deleteDisabledTooltip: t.staffTrainingCompetencies.length > 0 ? 'Cannot delete Themes with Competencies' : '',
        clickTo: {
          name: 'InstitutionStaffTrainingCompetencyThemePage',
          params: { frameworkId: frameworkId.value.toString(), id: t.id.toString() },
        },
      }))
      .sortBy('order');
  }
  return themes;
});

// Add Theme

const newThemeName = ref('');
const newThemeCode = ref('');
const canAddTheme = computed(() => !!newThemeName.value && !!newThemeCode.value);

async function addThemeItem() {
  await api.post(`/staff-training/frameworks/${frameworkId.value}/competency-themes`, {
    name: newThemeName.value,
    code: newThemeCode.value,
  });
}

// Move Theme

const { moveListItem } = useMoveListItem('/staff-training/frameworks/competency-themes', api);
async function moveThemeListItem(direction: 'up' | 'down', listItem: Item) {
  await moveListItem(direction, listItem);
  clearInstitutionStaffTrainingFrameworks();
}

// Delete Theme
// Delete only needs id and is handled by deleteDialog in MosaicInlineCreationList
const deleteUrlStem = '/staff-training/frameworks/competency-themes';

// Retitle Theme
const renameDialog = ref({ name: '', originalName: '', code: '', originalCode: '' });

function retitleTheme(themeItem: Item) {
  const theme = framework.value?.staffTrainingCompetencyThemes.find(t => t.id === themeItem.id);
  if (!theme) return;
  renameDialog.value = {
    code: theme.code,
    name: theme.name,
    originalName: theme.name,
    originalCode: theme.code,
  };
}

const canRetitleTheme = computed(
  () =>
    !!renameDialog.value.name &&
    !!renameDialog.value.code &&
    (renameDialog.value.name !== renameDialog.value.originalName ||
      renameDialog.value.code !== renameDialog.value.originalCode)
);

const body = computed(() => ({
  name: renameDialog.value.name,
  code: renameDialog.value.code,
}));
const { retitleListItem } = useRetitleListItem('/staff-training/frameworks/competency-themes', body, api);
async function saveRetitleTheme(itemId: number) {
  await retitleListItem(itemId);
  clearInstitutionStaffTrainingFrameworks();
}

// Might be a better way of doing this, but distinction with triggerBackgroundLoad is that this should only fire if something is changed,
// Not on component load.
// Probably quite nice to have a hook like this anyway for extensibility of this kind of behaviour
// For this particular case it's needed for e.g. an training author who is editing a framework that is published to them. Updates should be reflected in My Training.
async function afterUpdate() {
  await loadStaffTraining(userStaff.value.id, true);
  clearInstitutionStaffTrainingFrameworks();
}
</script>
