import type { RouteLocationNamedRaw } from 'vue-router';

// - To type this component properly requires component level generics https://vuejs.org/api/sfc-script-setup.html#generics, which are supported in Vue 3.3, but not backport to 2.x
export type Row = {
  id: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

export type Column = TextColumn | EnumColumn | NumberColumn;

export interface TextColumn extends BaseColumn {
  text: (row: Row) => string;
  textColor?: (row: Row) => string;
  secondaryText?: (row: Row) => string;
  prependHelpText?: (row: Row) => string | undefined;
  filter?: TextFilter;
  noFilter?: boolean;
}
export interface TextFilter {
  type: 'text';
  value: (row: Row) => string;
}

export interface EnumColumn extends BaseColumn {
  chip: (row: Row) => Chip;
  filter: SelectFilter;
}
export interface SelectFilter {
  type: 'select';
  items: { title: string; value: string | null }[];
  value?: (row: Row) => string;
}

export interface NumberColumn extends BaseColumn {
  value: (row: Row) => number;
  valueColor?: (row: Row) => string;
  filter?: NumberFilter;
}

export interface NumberFilter {
  type: 'range';
  value: (row: Row) => number;
}

// Ideally would do this as a union, but there are problems with the export type checking
// I think this can be solved when we have component level generics and using a computed, rather than executing the column function in the template
export type Chip = {
  color: string;
  text: string;
  value: string;
  icon?: string;
  iconColor?: string;
  tooltip?: string;
  hide: boolean;
};
// | { hide: true };

export interface BaseColumn {
  name: string;
  key: string;
  clickRoute?: (row: Row) => RouteLocationNamedRaw | undefined;
  sort?: (row: Row) => string | number;
  helpText?: string;
  helpTextHtml?: string;
  filter?: unknown;
  sticky?: boolean;
  width?: string;
}

export function isTextColumn(c: Column): c is TextColumn {
  return !!(c as TextColumn).text;
}

export function isEnumColumn(c: Column): c is EnumColumn {
  return !!(c as EnumColumn).chip;
}

export function isNumberColumn(c: Column): c is NumberColumn {
  return !!(c as NumberColumn).value;
}
