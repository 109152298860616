<template>
  <div>
    <mosaic-configure-preview-page
      v-model:trigger-background-load="triggerBackgroundLoad"
      v-model="previewToggle"
      object-type="Training Module Page"
      :load="loadModule"
      :error="loadError"
    >
      <template #configure>
        <mosaic-card v-if="section">
          <div class="d-flex">
            <mosaic-card-title class="flex-grow-1">
              {{ section.name }}
              <template #chip>
                <mosaic-published-draft-chip
                  mode="card"
                  :published="published"
                  object-type="Training Module"
                  :show-published-message="true"
                />
              </template>
            </mosaic-card-title>
            <v-btn class="ml-2" :disabled="!previousSectionId" @click="changeSection(previousSectionId)"
              ><v-icon>mdi-chevron-left</v-icon></v-btn
            >
            <v-btn class="ml-2" :disabled="!nextSectionId" @click="changeSection(nextSectionId)"
              ><v-icon>mdi-chevron-right</v-icon></v-btn
            >
          </div>
          <mosaic-content-layout-builder
            v-model:content-layout="contentLayout"
            :editable="!published"
            :get-resource-url-prefix="`/staff-training/resources`"
            :presign-url="`/presign/institutions/${selectedInstitution.id}/staff-training-resources`"
            :save-resource-url="`/institutions/${selectedInstitution.id}/staff-training/resources`"
            :save-resource-params="{ staffTrainingModuleSectionId: sectionId }"
            :supported-item-types="[
              'text',
              'resource',
              'comment',
              'taskList',
              'video',
              'youtube',
              'link',
              'image',
              'nasbttMentorModule',
              'columns',
              'fileUpload',
            ]"
            @valid="contentLayoutValid = $event"
          />
          <mosaic-error-alert :error="saveError" action="save this Page" />
          <mosaic-success-snackbar v-model="saveSuccess" object-type="Page" />
          <div v-if="!published" class="d-flex justify-end">
            <mosaic-btn class="mr-2" @click="$router.go(-1)">Cancel</mosaic-btn>
            <mosaic-btn color="primary" :disabled="!canSave || saveProcessing" :loading="saveProcessing" @click="save"
              >Save</mosaic-btn
            >
          </div>
        </mosaic-card>
      </template>

      <template #preview>
        <staff-training-module-card
          :staff-training-completion="staffTrainingCompletion"
          :section-id="sectionId"
          :preview="true"
          @update:section-id="changeSection($event)"
          @click-section="changeSection($event)"
        />
      </template>
    </mosaic-configure-preview-page>

    <unsaved-changes-dialog v-model:unsaved-changes-dialog="unsavedChangesDialog" object-type="Training Module Page" />
  </div>
</template>

<script>
import MosaicContentLayoutBuilder from '@/components/mosaic-content-layout/MosaicContentLayoutBuilder.vue';
import StaffTrainingModuleCard from '@/pages/staff-training/StaffTrainingModuleCard.vue';
import unsavedChangesMixin from '@/components/unsaved-changes-mixin';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import { mapState } from 'vuex';
import { mapStaffTrainingModuleToSectionCompletions } from '@/pages/staff-training/staff-training';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'InstitutionStaffTrainingModuleSectionPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      previewToggle: { query: 'preview', type: 'string' },
    }),
    unsavedChangesMixin,
  ],
  components: { MosaicContentLayoutBuilder, UnsavedChangesDialog, StaffTrainingModuleCard },
  data() {
    return {
      triggerBackgroundLoad: false,
      loadError: false,
      sectionId: null,
      moduleId: null,
      module: null,
      contentLayout: null,
      contentLayoutValid: true,
      saveProcessing: false,
      saveError: false,
      saveSuccess: false,
      previewToggle: null,
    };
  },
  computed: {
    ...mapState(['selectedInstitution', 'userStaff']),
    breadcrumbs() {
      return [
        {
          text: 'Instructors',
          to: {
            name: 'TutorStaffListPage',
          },
        },
        {
          text: 'Training',
          to: {
            name: 'InstitutionStaffTrainingPage',
          },
        },
        {
          text: 'Modules',
          to: {
            name: 'InstitutionStaffTrainingPage',
            query: { tab: 'modules' },
          },
        },
        {
          text: this.module?.name,
          to: {
            name: 'InstitutionStaffTrainingModulePage',
            params: { id: this.moduleId },
          },
        },
        {
          text: this.section?.name,
        },
      ];
    },
    dirtyComputed() {
      if (!this.section) return false;
      const contentLayoutDirty = JSON.stringify(this.contentLayout) !== JSON.stringify(this.section.contentLayout);

      return contentLayoutDirty;
    },
    canSave() {
      return this.dirty && this.contentLayoutValid;
    },
    section() {
      return this.module?.staffTrainingModuleSections.find(s => s.id === this.sectionId);
    },
    staffTrainingCompletion() {
      if (!this.module) return null;

      const staffTrainingModule = {
        ...this.module,
        staffTrainingModuleSections: this.module.staffTrainingModuleSections.map(s => {
          if (s.id === this.sectionId) {
            return { ...s, contentLayout: this.contentLayout };
          }
          return s;
        }),
      };
      return {
        staff: {
          id: this.userStaff.id,
        },
        staffTrainingModule,
        staffTrainingModuleCompletion: {
          staffTrainingModuleSectionCompletions: mapStaffTrainingModuleToSectionCompletions(staffTrainingModule),
        },
      };
    },
    published() {
      return this.module.status === 'published';
    },
    nextSectionId() {
      return this.module.staffTrainingModuleSections.find(s => s.order === this.section.order + 1)?.id;
    },
    previousSectionId() {
      return this.module.staffTrainingModuleSections.find(s => s.order === this.section.order - 1)?.id;
    },
  },
  watch: {
    dirtyComputed(x) {
      this.dirty = x;
    },
    section(x) {
      if (x) {
        this.loadError = false;
        this.contentLayout = x.contentLayout;
      } else {
        this.loadError = true;
      }
    },
    $route: {
      handler() {
        this.sectionId = parseInt(this.$route.params.id);
      },
      deep: true,
    },
  },
  async created() {
    this.sectionId = parseInt(this.$route.params.id);
    this.moduleId = this.$route.params.moduleId;
  },
  methods: {
    async loadModule() {
      const r = await this.$api.get(`/staff-training/modules/${this.moduleId}`);
      this.module = r.data;

      if (!this.previewToggle) {
        this.previewToggle = this.published ? 'preview' : 'configure';
      }
    },
    async save() {
      this.saveProcessing = true;
      this.saveError = false;

      try {
        await this.$api.put(`/staff-training/modules/sections/${this.sectionId}/content`, {
          contentLayout: this.contentLayout,
        });
        this.triggerBackgroundLoad = true;
        this.saveSuccess = true;
      } catch (e) {
        console.log(e);
        this.saveError = true;
      }

      this.saveProcessing = false;
    },
    changeSection(sectionId) {
      this.$router.push({
        name: 'InstitutionStaffTrainingModuleSectionPage',
        params: {
          id: sectionId,
        },
        query: {
          preview: this.previewToggle,
        },
      });
    },
  },
};
</script>
