<template>
  <full-calendar :options="fullCalendarOptions" />
</template>

<script>
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

export default {
  components: { FullCalendar },
  props: {
    events: {
      type: Array,
      required: true,
    },
  },
  emits: ['onSelect', 'onEventClick'],
  computed: {
    fullCalendarOptions() {
      return {
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
        initialView: this.smallScreen ? 'listWeek' : 'timeGridWeek',
        selectable: true,
        events: this.events,
        headerToolbar: {
          start: 'title',
          center: this.smallScreen ? 'listWeek,dayGridMonth' : '',
          end: this.smallScreen ? 'prev,next' : 'timeGridWeek,dayGridMonth today prev,next',
        },
        select: info => this.$emit('onSelect', info),
        eventClick: info => this.$emit('onEventClick', info),
        allDaySlot: false,
        buttonText: {
          today: 'Today',
          month: 'Month',
          week: 'Week',
        },
        height: this.smallScreen ? '65vh' : '70vh',
        nowIndicator: true,
        weekends: !this.smallScreen,
      };
    },
  },
};
</script>

<style>
/* Fixes for prev/next vertical alignment */
.fc-next-button {
  padding-top: 2px !important;
  padding-bottom: 8.15px !important;
}
.fc-prev-button {
  padding-top: 2px !important;
  padding-bottom: 8.15px !important;
}

.fc-button-primary {
  background-color: rgb(var(--v-theme-primary)) !important;
}

.fc-timeGridWeek-button.fc-button-primary.fc-button-active {
  background-color: rgb(var(--v-theme-primary)) !important;
}
.fc-timeGridWeek-button.fc-button-primary {
  background-color: rgb(var(--v-theme-secondary)) !important;
}
.fc-dayGridMonth-button.fc-button-primary.fc-button-active {
  background-color: rgb(var(--v-theme-primary)) !important;
}
.fc-dayGridMonth-button.fc-button-primary {
  background-color: rgb(var(--v-theme-secondary)) !important;
}
</style>
