<template>
  <div>
    <mosaic-loading-card v-if="busy" type="list" />
    <v-card v-else-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <div v-else>
      <mosaic-list-filters-card
        title="Instructors"
        :filters-applied="filtersApplied"
        :extra-filters-applied="extraFiltersApplied"
      >
        <!-- Potential abuse of the switches slot, we should change this to something proper if it becomes a pattern -->
        <template #switches v-if="staffLoading">
          <v-icon>mdi-loading mdi-spin</v-icon>
        </template>

        <template #filters>
          <div class="d-flex align-center flex-wrap flex-grow-1" style="row-gap: 16px; column-gap: 16px">
            <div style="min-width: 230px">
              <staff-list-name-filter @update:filter="nameEmailFilter = $event" />
            </div>
            <staff-list-role-filter @update:role="selectedRole = $event" />
          </div>
        </template>
        <template #extraFilters>
          <div class="d-flex align-center flex-wrap flex-grow-1" style="row-gap: 16px; column-gap: 16px">
            <staff-list-wont-receive-emails-filter @update:wont-receive-emails="wontReceiveEmails = $event" />
            <staff-list-last-active-filter @update:last-active="selectedLastActive = $event" />
            <staff-list-storage-not-set-up-filter @update:storage-not-set-up="storageNotSetUp = $event" />
          </div>
        </template>

        <template #actions>
          <div>
            <v-tooltip location="bottom">
              <template #activator="{ props }">
                <v-btn ripple @click.prevent="downloadInstructors()" v-bind="props">
                  <v-icon>mdi-table-arrow-down</v-icon>
                </v-btn>
              </template>
              <span>Download instructors</span>
            </v-tooltip>
          </div>
          <div class="d-flex align-center">
            <v-btn ripple @click.prevent="addStaff">
              <div class="d-flex align-center">
                <v-icon>mdi-plus</v-icon>
                <span>Instructor</span>
              </div>
            </v-btn>
            <div class="d-flex pl-1">
              <v-tooltip location="bottom">
                <template #activator="{ props }">
                  <v-icon color="primary" dark v-bind="props">mdi-information</v-icon>
                </template>
                <div class="py-2">
                  Default password:
                  <span class="font-weight-bold">{{ userStaff.institution_staff_default_password }}</span>
                  <br />(Instructors will be prompted to change their password on first login)
                </div>
              </v-tooltip>
            </div>
          </div>

          <mosaic-error-snackbar v-model="downloadError" action="download your Instructors" />
        </template>
      </mosaic-list-filters-card>

      <v-card v-if="!error && !busy" class="flex-grow-1">
        <v-snackbar
          v-model="passwordSnackbar"
          color="success"
          location="top"
          absolute
          variant="text"
          class="elevation-8"
          :timeout="2000"
          content-class="text-center"
          >Password updated</v-snackbar
        >
        <v-card-text>
          <mosaic-list
            :items="paginatedStaff"
            :empty-text="`You have no Instructors${staff.length > 0 ? ' for these filters' : ''}`"
          >
            <template #item="{ item: s }">
              <mosaic-list-item
                :to="clickStaffTo(s.id)"
                icon="mdi-human-male-board"
                :title="renderStaffTitle(s)"
                :subtitle="`${renderStaffSubtitle(s)} ${renderStaffSubtitle2(s)} ${renderStaffLastActive(s)}`"
              >
                <template #information>
                  <demo-account-badge v-if="s.user.is_demo" class="pr-2" />
                  <account-locked-chip v-if="s.user.account_locked" class="pr-2" />
                  <email-status-chip
                    v-if="isEmailVerificationOnForSelectedInstitution && !s.user.is_demo && s.user.wont_receive_emails"
                    class="pr-2"
                    :email-verified="s.user.email_verified"
                    :email-bounced="s.user.email_bounced"
                    :opted-out-of-emails="s.user.opted_out_of_emails"
                  />
                  <div>
                    <mosaic-tooltip-chip v-for="role in staffRoles(s)" :key="role.id" color="primary" class="mr-2">
                      <template #text> {{ role.initialisedText || initialiseText(role.name) }} </template>
                      <template #tooltip>
                        {{ role.name }}
                      </template>
                    </mosaic-tooltip-chip>
                  </div>
                </template>

                <template #actions>
                  <ndt-icon-button
                    icon="package-down"
                    :tooltip="
                      hasMultipleInstitutions(s)
                        ? 'Cannot archive as this Instructor shares their account with another Institution.'
                        : 'Archive Instructor'
                    "
                    :disabled="hasMultipleInstitutions(s)"
                    @click.prevent="archiveStaff(s)"
                  />
                  <ndt-icon-button
                    icon="lock-reset"
                    :tooltip="
                      hasMultipleInstitutions(s)
                        ? 'Cannot reset password as this Instructor shares their account with another Institution. Please ask them to try the forgotten password flow or contact support'
                        : s.user.force_microsoft_sso
                        ? 'Cannot reset password as this Instructor must sign in with Microsoft'
                        : 'Reset password'
                    "
                    class="mr-2"
                    :disabled="hasMultipleInstitutions(s) || s.user.force_microsoft_sso"
                    @click.prevent="resetPassword(s)"
                  />
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </v-card-text>
      </v-card>
      <mosaic-pagination v-model="currentPage" v-model:page-size="pageSize" :total="staffTotal" />
    </div>

    <ndt-dialog v-model:active="addStaffDialog.active" title="Add Instructor" :error-message="addStaffDialog.error">
      <v-text-field v-model="addStaffDialog.name" prepend-icon="mdi-account" name="name" label="Name" type="text" />
      <v-text-field v-model="addStaffDialog.email" prepend-icon="mdi-at" name="email" label="Email" type="text" />
      <role-select
        v-model:role="addStaffDialog.selectedRole"
        @update:scope="addStaffDialog.selectedScope = $event"
        @update:no-student-yet-cohort-id="addStaffDialog.selectedNoStudentYetCohortId = $event"
        @update:valid="addStaffDialog.roleAndScopeValid = $event"
      >
      </role-select>
      <div class="d-flex align-center">
        <mosaic-checkbox
          v-model="addStaffDialog.isDemo"
          class="pr-2"
          no-icon
          label="Create as demo account"
        /><mosaic-help
          ><div>Demo accounts are intended for testing or demonstrating functionality</div>
          <div>and will not trigger any automated emails from Mosaic.</div></mosaic-help
        >
      </div>
      <force-microsoft-sso-checkbox v-model="addStaffDialog.forceMicrosoftSso" />
      <div class="pl-2 pb-4" v-if="!addStaffDialog.forceMicrosoftSso">
        Note: the default password for new Instructors is
        <span class="font-weight-bold">{{ userStaff.institution_staff_default_password }}</span>
        <br />(Instructors will be prompted to change their password on first login)
      </div>
      <div class="pl-4">If this Instructor already exists on Mosaic then they will keep their current name.</div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitAddStaff" @click.prevent="submitAddStaff">Save</v-btn>
      </template>
    </ndt-dialog>
    <mosaic-dialog
      v-model:active="archiveDialog.active"
      :title="`Archive Instructor`"
      :error-message="archiveDialog.error"
    >
      <div>
        Are you sure you want to archive {{ archiveDialog.title }}'s account? The User will no longer be able to log in
        and their account will not be visible in the Instructor list.
      </div>
      <div class="pt-2">You will be able to retrieve this account later by contacting support.</div>
      <template #buttons>
        <mosaic-btn
          variant="text"
          ripple
          color="error"
          :disabled="archiveDialog.processing"
          @click.prevent="submitArchiveStaff()"
          >Archive
        </mosaic-btn>
      </template>
    </mosaic-dialog>

    <ndt-dialog
      v-model:active="resetPasswordDialog.active"
      title="Reset Password"
      :on-close="resetPasswordDialogClose"
      :error-message="resetPasswordDialog.errorMessage"
    >
      <div>
        <v-alert v-if="resetPasswordDialog.accountLocked" type="info" variant="outlined"
          >This account is locked due to too many incorrect login attempts. Resetting the password will unlock the
          account.</v-alert
        >
        <mosaic-password-text-field
          v-model="resetPasswordDialog.newPassword"
          name="newPassword"
          label="Password"
          :validate-password="true"
          :revealed="true"
          @password-valid="passwordValid = $event"
        />
        <mosaic-password-text-field
          v-model="resetPasswordDialog.newPasswordConfirmation"
          name="newPasswordConfirmation"
          label="Password Confirmation"
          :rules="passwordConfirmationRules"
          :revealed="true"
          @keyup.enter="submitResetPassword"
        />
        <div class="d-flex justify-end">
          <mosaic-password-security-link />
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canSubmitResetPassword" @click.prevent="submitResetPassword()"
          >Submit</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import NdtDialog from '@/components/NdtDialog.vue';
import NdtIconButton from '@/components/NdtIconButton.vue';
import RoleSelect from '@/components/RoleSelect.vue';
import DemoAccountBadge from '@/components/DemoAccountBadge.vue';
import { validateEmail } from '@/utils/email';
import { initialiseText } from '@/utils/text';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { generateStongPassword } from '@/utils/passwords';
import XLSX from 'xlsx';
import moment from 'moment';
import EmailStatusChip from '@/components/user/EmailStatusChip.vue';
import AccountLockedChip from '@/components/user/AccountLockedChip.vue';
import { validationsPass } from '@/utils/validations';
import { getEmailStatusChipParams } from '@/utils/email-verification';
import StaffListRoleFilter from './StaffListRoleFilter.vue';
import StaffListNameFilter from './StaffListNameFilter.vue';
import StaffListWontReceiveEmailsFilter from './StaffListWontReceiveEmailsFilter.vue';
import StaffListLastActiveFilter from './StaffListLastActiveFilter.vue';
import StaffListStorageNotSetUpFilter from './StaffListStorageNotSetUpFilter.vue';
import { useInstitutionStaffStore } from '@/stores/institution-staff';
import { useQueryStore } from '@/stores/query';
import ForceMicrosoftSsoCheckbox from '@/components/ForceMicrosoftSsoCheckbox.vue';

const addStaffDialogDefaults = {
  active: false,
  error: null,
  processing: false,
  email: '',
  name: '',
  selectedRole: null,
  selectedScope: null,
  selectedNoStudentYetCohortId: null,
  roleAndScopeValid: false,
  isDemo: false,
  forceMicrosoftSso: false,
};

export default {
  components: {
    NdtDialog,
    RoleSelect,
    NdtIconButton,
    DemoAccountBadge,
    EmailStatusChip,
    AccountLockedChip,
    StaffListRoleFilter,
    StaffListNameFilter,
    StaffListWontReceiveEmailsFilter,
    StaffListLastActiveFilter,
    StaffListStorageNotSetUpFilter,
    ForceMicrosoftSsoCheckbox,
  },
  mixins: [
    syncQueryParamsMixin({
      filterTerm: { query: 'name', type: 'string' },
      selectedRoleId: { query: 'selectedRoleId', type: 'integer' },
      notLoggedInOnly: { query: 'notLoggedIn', type: 'boolean' },
    }),
  ],
  setup() {
    const {
      actions: { clearInstitutionStaff },
    } = useInstitutionStaffStore();
    const queryStore = useQueryStore();
    return { clearInstitutionStaff, queryStore };
  },
  name: 'TutorStaffListPage',
  data: () => ({
    error: null,
    passwordSnackbar: false,
    busy: true,
    staff: [],
    staffLoading: true,
    staffCount: 0,
    selectedRoleId: null,
    filterTerm: '',
    currentPage: 1,
    pageSize: 10,
    wontReceiveEmails: false,
    storageNotSetUp: false,
    addStaffDialog: {
      ...addStaffDialogDefaults,
    },
    resetPasswordDialog: {
      active: false,
      name: '',
      userId: null,
      accountLocked: false,
      processing: false,
      newPassword: '',
      newPasswordConfirmation: '',
      errorMessage: '',
    },
    archiveDialog: {
      active: false,
      id: null,
      title: '',
      error: '',
      processing: false,
    },
    passwordValid: false,
    passwordConfirmationRules: [],
    selectedLastActive: {
      text: 'Show all users',
      value: 'all',
      filter: () => true,
    },
    errorSnackbar: {
      active: false,
      message: '',
    },
    downloadError: false,
  }),
  computed: {
    ...mapState(['roles', 'user', 'selectedInstitution', 'userStaff']),
    ...mapGetters(['isEmailVerificationOnForSelectedInstitution']),
    breadcrumbs() {
      return [
        {
          text: 'Instructors',
        },
      ];
    },
    filtersApplied() {
      return !!(this.filterTerm || this.selectedRoleId);
    },
    extraFiltersApplied() {
      return this.selectedLastActive.value !== 'all' || this.wontReceiveEmails || this.storageNotSetUp;
    },
    institutionRoles() {
      return [{ name: 'All Roles', id: null }].concat(this.roles).concat({ name: 'No Role Assigned', id: -1 });
    },
    filteredStaff() {
      return this.staff.filter(x => {
        const roleFilter =
          !this.selectedRoleId ||
          (this.selectedRoleId === -1 && x.roles.length === 0) ||
          x.roles.map(x => x.id).includes(this.selectedRoleId);

        const lastActiveFilter = this.selectedLastActive.filter(x.user);
        const nameEmailFilter =
          x.user.name.toLowerCase().includes(this.filterTerm.toLowerCase()) ||
          x.user.email.toLowerCase().includes(this.filterTerm.toLowerCase());
        return (
          roleFilter &&
          lastActiveFilter &&
          nameEmailFilter &&
          (!this.wontReceiveEmails || x.user.wont_receive_emails) &&
          (this.selectedInstitution.storage_type === 'Mosaic' || !this.storageNotSetUp || !x.storage_email)
        );
      });
    },
    paginatedStaff() {
      const index = (this.currentPage - 1) * this.pageSize;
      return this.filteredStaff.slice(index, index + this.pageSize);
    },
    staffTotal() {
      if (this.staff.length === this.filteredStaff.length && this.staff.length < this.staffCount)
        return this.staffCount;
      return this.filteredStaff.length;
    },
    canSubmitResetPassword() {
      return (
        !this.resetPasswordDialog.processing &&
        this.resetPasswordDialog.newPassword &&
        validationsPass(this.passwordConfirmationRules, this.resetPasswordDialog.newPasswordConfirmation) &&
        this.passwordValid
      );
    },
    canSubmitAddStaff() {
      return !this.addStaffDialog.processing && this.addStaffDialog.email && this.addStaffDialog.roleAndScopeValid;
    },
  },
  watch: {
    filteredStaff() {
      this.currentPage = 1;
    },
  },
  async created() {
    this.passwordConfirmationRules = [
      v => (v && v === this.resetPasswordDialog.newPassword) || 'Password confirmation should match password',
    ];
    this.$store.dispatch('loadRoles');
    await this.loadStaff();
  },
  methods: {
    hasMultipleInstitutions(s) {
      return s.user.in_multiple_institutions;
    },
    async loadStaff() {
      this.busy = true;
      this.staffLoading = true;
      try {
        const [staffResponse, countResponse] = await Promise.all([
          this.$api.get(`/institutions/${this.selectedInstitution.id}/staff?offset=0`),
          this.$api.get(`/institutions/${this.selectedInstitution.id}/staff/count`),
        ]);
        this.staff = staffResponse.data;
        this.staffCount = countResponse.data.count;
        this.busy = false;

        let offset = this.staff.length;
        while (true) {
          if (this.staff.length >= this.staffCount) break;
          const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/staff?offset=${offset}`);
          if (r.data.length === 0) break;
          this.staff = this.staff.concat(r.data);
          offset += r.data.length;
        }
      } catch (e) {
        console.log(e);
        this.error = 'Sorry, cannot load your Instructors at the moment';
        this.busy = false;
      }
      this.staffLoading = false;
    },
    addStaff() {
      this.addStaffDialog.active = true;
      this.addStaffDialog.forceMicrosoftSso = this.selectedInstitution.config.allow_forcing_microsoft_sso;
    },
    async submitAddStaff() {
      this.addStaffDialog.email = this.addStaffDialog.email?.trim();
      if (!validateEmail(this.addStaffDialog.email)) {
        this.addStaffDialog.error = 'Please supply a valid email';
        this.addStaffDialog.processing = false;
        return;
      }

      try {
        this.addStaffDialog.processing = true;
        this.addStaffDialog.error = '';
        const scope = this.addStaffDialog.selectedScope;
        await this.$api.post(`/institutions/${this.selectedInstitution.id}/staff`, {
          email: this.addStaffDialog.email,
          name: this.addStaffDialog.name,
          roleId: this.addStaffDialog.selectedRole.id,
          institutionId: scope?.type === 'institution' ? scope.id : null,
          studentId: scope?.type === 'student' ? scope.id : null,
          noStudentYetCohortId:
            scope?.type === 'student' && scope?.id === -1 ? this.addStaffDialog.selectedNoStudentYetCohortId : null,
          cohortId: scope?.type === 'cohort' ? scope.id : null,
          schoolId: scope?.type === 'school' ? scope.id : null,
          isDemo: this.addStaffDialog.isDemo,
        });
        this.loadStaff();
        this.clearInstitutionStaff();

        if (this.addStaffDialog.email === this.user.email) {
          await this.$store.dispatch('refreshUser');
        }
      } catch (e) {
        if (e.response?.status === 409 && e.response.data.error_code === 'email_belongs_to_student') {
          this.addStaffDialog.error = `${
            this.traineeNounArticleCapitalised
          } ${this.traineeNoun()} with that email already exists`;
        }
        if (e.response?.status === 422 && e.response.data.error_code === 'demo_user_cant_be_in_multiple_institutions') {
          this.addStaffDialog.error = `An Instructor with this email already exists in a different Institution and demo Instructors cannot be in multiple Institutions`;
        } else if (e.response?.status === 422 && e.response.data.error_code === 'user_in_other_institution_is_demo') {
          this.addStaffDialog.error = `A demo Instructor with this email already exists in a different Institution and cannot be added as demo Instructors cannot be in multiple Institutions`;
        } else {
          console.log(e);
          this.addStaffDialog.error = 'Sorry, cannot add this Instructor at the moment';
        }
        this.addStaffDialog.processing = false;
        return;
      }
      this.addStaffDialog = {
        ...addStaffDialogDefaults,
      };
    },
    archiveStaff(staff) {
      this.archiveDialog = {
        active: true,
        id: staff.id,
        title: staff.user.name || staff.user.email,
        processing: false,
      };
    },
    async submitArchiveStaff() {
      this.archiveDialog.processing = true;
      this.archiveDialog.error = null;
      try {
        await this.$api.put(`/staff/${this.archiveDialog.id}/archive`);
        this.archiveDialog = {
          active: false,
          processing: false,
        };
        this.loadStaff();
        this.clearInstitutionStaff();
      } catch (e) {
        console.log(e);
        this.archiveDialog.error = 'Sorry, cannot archive this Instructor at the moment';
        this.archiveDialog.processing = false;
      }
    },
    staffRoles(staff) {
      const roles = staff.roles.uniqueBy(x => x.id);

      if (roles.length > 4) {
        return roles
          .slice(0, 3)
          .concat([{ name: `And ${roles.length - 3} more`, initialisedText: `+${roles.length - 3}` }]);
      }

      return roles;
    },
    clickStaffTo(id) {
      return { name: 'TutorStaffPage', params: { id } };
    },
    renderStaffTitle(staff) {
      return staff.user.name ? staff.user.name : staff.user.email;
    },
    renderStaffSubtitle(staff) {
      return staff.user.email;
    },
    renderStaffSubtitle2(staff) {
      return !(
        this.selectedInstitution.storage_type === 'Mosaic' ||
        this.selectedInstitution.config.on_demand_sharing ||
        staff.storage_email
      )
        ? '- Storage not set up'
        : '';
    },
    renderStaffLastActive(staff) {
      return !staff.user.last_active_at
        ? ' - Has never logged in'
        : ' - Last active on ' + this.formatDate(staff.user.last_active_at);
    },
    async resetPassword(staff) {
      const password = await generateStongPassword(this.$api, this.user);
      this.resetPasswordDialog = {
        active: true,
        name: staff.name,
        id: staff.id,
        processing: false,
        newPassword: password,
        newPasswordConfirmation: password,
        accountLocked: staff.user.account_locked,
      };
    },
    async submitResetPassword() {
      if (!this.canSubmitResetPassword) return;
      this.resetPasswordDialog.processing = true;
      if (this.resetPasswordDialog.newPassword !== this.resetPasswordDialog.newPasswordConfirmation) {
        this.resetPasswordDialog.errorMessage = `Password and confirmation must match`;
        this.resetPasswordDialog.processing = false;
        return;
      }

      this.resetPasswordDialog.errorMessage = '';
      try {
        await this.$api.put(`/staff/${this.resetPasswordDialog.id}/reset-password`, {
          password: this.resetPasswordDialog.newPassword,
        });
        await this.loadStaff();
        this.resetPasswordDialog.active = false;
        this.passwordSnackbar = true;
      } catch (e) {
        console.log(e);
        this.resetPasswordDialog.errorMessage = `Sorry, cannot reset this password at the moment`;
      }

      this.resetPasswordDialog.processing = false;
    },
    resetPasswordDialogClose() {
      this.resetPasswordDialog = {
        active: false,
        name: '',
        userId: null,
        processing: false,
        newPassword: '',
        newPasswordConfirmation: '',
        errorMessage: '',
      };
    },
    navigateToPermissionsPage() {
      this.$router.push({ name: 'TutorAdminPermissionsPage' });
    },
    downloadInstructors() {
      try {
        const xlxs = XLSX.utils.book_new();
        const selectedRoleName = this.institutionRoles.find(x => x.id === this.selectedRoleId).name;
        const filters = [
          ['Search term', 'Role', 'Show logged in users?'],
          [this.filterTerm || 'None', selectedRoleName, !this.notLoggedInOnly],
        ];
        const headers = ['Email', 'Name', 'Created Date', 'Roles', 'Last active on', 'Storage email'];
        if (this.isEmailVerificationOnForSelectedInstitution) headers.push('Email verification status');
        const data = [
          headers,
          ...this.filteredStaff.map(x => [
            x.user.email,
            x.user.name,
            x.created_at,
            x.roles.map(r => r.name).join(', '),
            !x.user.last_active_at ? 'Has never logged in' : this.formatDate(x.user.last_active_at),
            !x.storage_email ? 'Storage not set up' : x.storage_email,
            x.user.is_demo
              ? 'Demo user'
              : this.isEmailVerificationOnForSelectedInstitution
              ? getEmailStatusChipParams(x.user.email_verified, x.user.email_bounced, x.user.opted_out_of_emails)
                  .friendlyValue
              : null,
          ]),
        ];
        XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), 'Instructors');
        XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(filters), `Applied Filters`);
        XLSX.writeFile(xlxs, `Instructors (${moment().format('DD-MM-YYYY')}).xlsx`);
      } catch (e) {
        console.log(e);
        this.downloadError = true;
      }
    },
    initialiseText,
  },
};
</script>
