<template>
  <mosaic-save-card
    :save="save"
    object-type="Target"
    :can-save="canSave"
    :is-creating="!isEditing"
    :readonly="readonly"
    :return-to="returnTo"
  >
    <div class="d-flex">
      <div v-if="mentorMeetingId && !target" class="text-h6">Add Target to Mentor Meeting</div>
      <div v-else class="text-h6">Target</div>
      <div class="mb-2 ml-2">
        <mosaic-tooltip-chip v-if="target && target.target_type === 'course'" color="secondary">
          <template #text>Course Target</template>
          <template #tooltip>Set by a course lead</template>
        </mosaic-tooltip-chip>

        <mosaic-tooltip-chip v-else-if="target && target.target_type === 'review'" color="secondary">
          <template #text>
            {{ reviewNounCapitalised }} Target{{
              target.is_approved_review_target ? ` - ${reviewNounCapitalised} approved` : ''
            }}
          </template>
          <template #tooltip>
            Set as part of a {{ reviewNounCapitalised
            }}{{
              target.is_approved_review_target
                ? `. ${reviewNounCapitalised} is approved, so target and description cannot be edited`
                : ''
            }}
          </template>
        </mosaic-tooltip-chip>

        <v-chip v-else-if="target && target.target_type === 'normal' && target.set_by" color="secondary">
          Set by {{ target.set_by.name }}
        </v-chip>
      </div>
    </div>
    <div class="py-2">
      <v-chip v-if="status === 'approved'" color="secondary">Approved</v-chip>
      <v-chip v-else-if="!target" color="accent">Active</v-chip>
      <v-btn-toggle
        v-else-if="target.can_edit_rest_of_target.permitted"
        v-model="status"
        mandatory
        variant="outlined"
        divided
      >
        <v-btn color="accent" value="active">Active</v-btn>
        <v-btn color="primary" value="completed">Completed</v-btn>
      </v-btn-toggle>
      <template v-else>
        <v-chip v-if="status === 'active'" color="accent">Active</v-chip>
        <v-chip v-else color="primary">Completed</v-chip>
      </template>

      <template v-if="showApprovalComment && target && target.mentor_comment && status === 'approved'">
        <div class="text-subtitle-1 mt-4">Approval Comment</div>
        <div class="text-body">{{ target.mentor_comment }}</div>
      </template>

      <template v-if="!target || target.can_edit_goal_and_description.permitted">
        <mosaic-text-area
          v-model="goal"
          class="mt-4"
          name="target"
          label="Target"
          autofocus
          auto-grow
          type="text"
          rows="1"
          hide-details
          no-icon
        />
        <v-textarea
          v-model="description"
          class="mt-4"
          variant="filled"
          label="Description - please add any additional detail relevant to this target"
          auto-grow
          type="text"
          rows="2"
          hide-details
        />
      </template>
      <template v-else>
        <div class="text-body-1 ml-1 mt-4">{{ goal }}</div>
        <template v-if="description">
          <div class="text-subtitle-2 mt-2">Description</div>
          <div class="text-body-2 ml-1">{{ description }}</div>
        </template>
      </template>
    </div>
    <div class="text-h6 mt-4">Actions</div>

    <div v-if="!target || target.can_edit_actions.permitted">
      <div class="subtitle">What steps will you take to achieve this target?</div>
      <div>
        <div v-for="(action, index) in actions" :key="index" class="d-flex align-center">
          <mosaic-checkbox name="action-check" v-model="action.completed" class="mr-1" hide-details no-icon dense />
          <!-- https://vuejs.org/guide/essentials/template-refs.html#function-refs -->
          <mosaic-text-field
            name="action-text"
            :ref="
              el => {
                actionInputs[action.id.toString()] = el as unknown as MosaicTextField;
              }
            "
            v-model="action.text"
            placeholder="Add new action"
            class="mr-2 flex-grow-1 pb-1"
            hide-details
            no-icon
            dense
          />
          <mosaic-icon-btn icon="delete" tooltip="Remove Action" @click.prevent="deleteAction(action.id)" />
        </div>
        <div class="d-flex align-center pt-2">
          <v-btn ripple @click="addAction()">
            <v-icon>mdi-plus</v-icon>
            <span>Add new action</span>
          </v-btn>
        </div>
      </div>
    </div>
    <div v-else class="pb-2">
      <div v-if="!actions || actions.length === 0">No actions recorded</div>
      <div v-for="action in actions" :key="action.id" class="d-flex align-center pa-1">
        <v-icon v-if="action.completed">mdi-checkbox-marked-circle-outline</v-icon>
        <v-icon v-else>mdi-checkbox-blank-circle-outline</v-icon>
        <span class="pl-2">{{ action.text }}</span>
      </div>
    </div>

    <template v-if="(target && target.mentor_meeting) || mentorMeetingDate">
      <div class="text-h6 mt-4">Mentor Meeting</div>
      <div class="py-2">
        <mosaic-alert border="start" border-color="primary" class="mb-0" variant="elevated">
          <div class="d-flex my-1">
            <div class="flex-grow-1 d-flex align-center">
              <div class="px-2">
                Mentor Meeting -
                {{ formatDate(target ? target.mentor_meeting.meeting_date : mentorMeetingDate || '') }}
              </div>
            </div>
            <div v-if="target && target.mentor_meeting" class="flex-shrink-1 text-right pl-4 py-2">
              <v-btn @click="goToMentorMeeting(target.mentor_meeting.id)">Go to Mentor Meeting</v-btn>
            </div>
          </div>
        </mosaic-alert>
      </div>
    </template>

    <curriculum-links
      class="mt-4"
      :selected-curriculum-statements="curriculumStatements"
      :curriculum-statement-id="curriculumStatementId ? curriculumStatementId.toString() : undefined"
      :can-edit="!target || target.can_edit_curriculum_links.permitted"
      artefact-type="Target"
      @update:link-added="addCurriculumLink"
      @update:link-removed="removeCurriculumLink"
    />

    <div>
      <div class="text-h6 mt-4">Reflection</div>
      <mosaic-quill
        v-if="canEditReflection || (target && target.reflection)"
        v-model:contents="reflection"
        class="mb-4"
        :read-only="!canEditReflection"
      />
      <div v-else class="pb-2">
        <div class="text-body">No reflection recorded</div>
      </div>
    </div>
  </mosaic-save-card>
</template>

<script setup lang="ts">
import CurriculumLinks from '@/components/CurriculumLinks.vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { ref, computed, nextTick } from 'vue';
import { mapState } from '@/store/map-store';
import { useRouter, type RouteLocationNamedRaw } from 'vue-router';
import { watchEffect } from 'vue';
import { watch } from 'vue';
import { useApi } from '@/composables/api';
import { useActiveTargetsStore } from '@/stores/active-targets';

const api = useApi();

// Types
interface Target {
  id: number;
  status: string;
  goal: string;
  impact_goal: string;
  target_actions: TargetAction[];
  reflection: string;
  mentor_comment: string;
  mentor_meeting: {
    id: number;
    meeting_date: string;
  };
  is_approved_review_target: boolean;
  set_by: {
    name: string;
  };
  curriculum_statements: CurriculumStatement[];
  target_type: 'normal' | 'mentor_meeting' | 'review' | 'course';
  can_edit_goal_and_description: PermittedWithReason;
  can_edit_curriculum_links: PermittedWithReason;
  can_edit_actions: PermittedWithReason;
  can_edit_rest_of_target: PermittedWithReason;
  can_delete: PermittedWithReason;
}

interface TargetAction {
  id: number;
  text: string;
  completed: boolean;
}

interface PermittedWithReason {
  permitted: boolean;
  reason: string;
}

interface CurriculumStatement {
  id: number;
  code: string;
  statement: string;
}

// Setup
const props = defineProps<{
  target?: Target;
  studentId: number;
  curriculumStatementId?: string | number;
  mentorMeetingId?: string | number;
  mentorMeetingDate?: string;
  returnUrl?: string;
  showApprovalComment: boolean;
  returnTo: RouteLocationNamedRaw;
}>();

const emit = defineEmits<{
  (e: 'update:dirty', dirty: boolean): void;
  (e: 'update:target', target: Target): void;
}>();

defineExpose({
  save,
});

const { selectedInstitution, user } = mapState();
const isEditing = computed(() => !!props.target);
const readonly = computed(
  () =>
    isEditing.value &&
    !(
      props.target!.can_edit_goal_and_description.permitted ||
      props.target!.can_edit_actions.permitted ||
      props.target!.can_edit_curriculum_links.permitted ||
      props.target!.can_edit_rest_of_target.permitted
    )
);

const status = ref('active');
const reflection = ref('');
const goal = ref('');
const description = ref('');
const actions = ref<TargetAction[]>([]);
const curriculumStatements = ref<CurriculumStatement[]>([]);

if (!isEditing.value) {
  status.value = 'active';
  reflection.value = selectedInstitution.value.config.target_reflection_template;
}

setTargetFields(props.target);
watch(
  () => props.target,
  t => setTargetFields(t)
);

function setTargetFields(target?: Target) {
  if (!target) {
    reflection.value = selectedInstitution.value.config.target_reflection_template;
    return;
  }
  goal.value = target.goal;
  description.value = target.impact_goal;
  actions.value = target.target_actions.map(a => ({ ...a }));
  status.value = target.status;
  reflection.value = target.reflection || selectedInstitution.value.config.target_reflection_template;
  curriculumStatements.value = [...target.curriculum_statements];
}

const canEditReflection = computed(() => {
  if (props.target) return props.target.can_edit_rest_of_target.permitted;
  if (user.value.student) return true;
  return false;
});

// Actions
interface MosaicTextField {
  focus: () => void;
}
interface ActionInputElementMap {
  [key: string]: MosaicTextField;
}

const actionInputs = ref<ActionInputElementMap>({});

async function addAction() {
  // These ids are not used for creating the ids serverside - just to have unique keys in the vue list
  const id = actions.value.length ? Math.max(...actions.value.map(x => x.id)) + 1 : 0;
  actions.value.push({ id, text: '', completed: false });
  await nextTick();
  actionInputs.value[id.toString()].focus();
}

function deleteAction(id: number) {
  actions.value = actions.value.filter(x => x.id !== id);
}

// Curriculum links

function addCurriculumLink(curriculumStatement: CurriculumStatement) {
  curriculumStatements.value.push(curriculumStatement);
}

function removeCurriculumLink(curriculumStatement: CurriculumStatement) {
  const index = curriculumStatements.value.findIndex(s => s.id === curriculumStatement.id);
  if (index > -1) {
    curriculumStatements.value.splice(index, 1);
  }
}

// Mentor meeting
const router = useRouter();
function goToMentorMeeting(meetingId: number) {
  router.push({
    name: 'MentorMeetingPage',
    params: { id: meetingId.toString() },
  });
}

// Save/dirty checking
const dirty = computed<boolean>(() => {
  if (isEditing.value && props.target) {
    const reflectionDirty = props.target.reflection
      ? props.target.reflection !== reflection.value
      : reflection.value !== selectedInstitution.value.config.target_reflection_template;
    return (
      props.target.goal !== goal.value ||
      props.target.impact_goal !== description.value ||
      props.target.target_actions.length !== actions.value.length ||
      props.target.target_actions.some(
        (a, i) => actions.value[i].text !== a.text || actions.value[i].completed !== a.completed
      ) ||
      props.target.curriculum_statements.length !== curriculumStatements.value.length ||
      props.target.curriculum_statements.some(cs => !curriculumStatements.value.map(x => x.id).includes(cs.id)) ||
      reflectionDirty ||
      props.target.status !== status.value
    );
  } else {
    const curriculumStatementsDirty =
      curriculumStatements.value.length > 1 ||
      (curriculumStatements.value.length === 1 && curriculumStatements.value[0].id != props.curriculumStatementId);
    return (
      !!goal.value ||
      !!description.value ||
      actions.value.length > 0 ||
      curriculumStatementsDirty ||
      (!!reflection.value && reflection.value !== selectedInstitution.value.config.target_reflection_template)
    );
  }
});
watchEffect(() => emit('update:dirty', dirty.value));

const canSave = computed(() => !!goal.value && dirty.value);

const {
  actions: { updateTarget, addTarget },
} = useActiveTargetsStore();

async function save() {
  if (!goal.value) return 'Sorry cannot save as the Target is required';

  const target = {
    id: props.target?.id,
    status: status.value,
    goal: goal.value,
    impact_goal: description.value,
    reflection: reflection.value,
    target_actions: actions.value,
    curriculum_statement_ids: curriculumStatements.value.map(cs => cs.id),
    mentor_meeting_id: props.mentorMeetingId,
  };

  let apiCall;
  if (isEditing.value) {
    apiCall = (x: TargetRequest) => api.put<TargetRequest, Target>(`/targets/${target.id}`, x);
  } else {
    apiCall = (x: TargetRequest) => api.post<TargetRequest, Target>(`/students/${props.studentId}/targets`, x);
  }
  const r = await apiCall(target);

  if (isEditing.value) {
    updateTarget(r.data);
  } else {
    addTarget(r.data);
  }
  emit('update:target', r.data);
}

interface TargetRequest {
  id?: number;
  status: string;
  goal: string;
  impact_goal: string;
  reflection: string;
  target_actions: { text: string; completed: boolean }[];
  curriculum_statement_ids: number[];
  mentor_meeting_id?: string | number;
}
</script>
