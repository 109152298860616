<template>
  <mosaic-card :pl="3" :pr="0">
    <div ref="titleBlock" class="pb-3 border-bottom" :class="{ 'mr-5': !smallScreen, 'mr-3': smallScreen }">
      <mosaic-card-title>
        {{ title }}
        <template #chip>
          <slot name="title-chip"></slot>
        </template>
        <template #actions>
          <slot name="title-actions"></slot>
        </template>
        <template #subtitle>
          <slot name="subtitle" />
        </template>
        <template #info>
          <slot name="info"></slot>
        </template>
      </mosaic-card-title>
    </div>
    <div class="d-flex" :class="{ 'flex-column': smallScreen }" style="column-gap: 20px; row-gap: 12px">
      <div
        v-if="sortedSections.length + metaSections.length > 1"
        class="flex-shrink-0 d-flex"
        :class="{
          'flex-column': !smallScreen,
          'border-right': !smallScreen,
          'border-bottom': smallScreen,
          'mr-3': smallScreen,
        }"
        :style="{
          'max-width': smallScreen ? 'auto' : '200px',
          'overflow-x': smallScreen ? 'auto' : 'visible',
        }"
      >
        <div
          v-for="s in sortedSections"
          :key="s.id"
          :id="`section-link-${s.id}`"
          class="px-4 py-2 flex-shrink-0 d-flex align-top"
          :class="{ 'selected-section': sectionId === s.id }"
          :style="{
            height: !smallScreen ? 'auto' : '40px',
            'max-width': !smallScreen ? 'auto' : '200px',
            cursor: s.disabled ? 'default' : 'pointer',
          }"
          @click="s.disabled ? null : emit('clickSection', s.id)"
          style="cursor: pointer"
        >
          <div v-if="s.disabled" class="disabled-sidebar-label">
            <v-tooltip>
              <template #activator="{ props: activatorProps }">
                <div class="d-flex">
                  <div class="flex-grow-1 pr-2" :class="{ 'mobile-section-link': smallScreen }">
                    {{ s.name }}
                  </div>

                  <mosaic-icon v-bind="activatorProps" small icon="eye-off" />
                </div>
              </template>

              {{ s.disabledTooltip }}
            </v-tooltip>
          </div>
          <div v-else class="flex-grow-1 section-sidebar-label" :class="{ 'mobile-section-link': smallScreen }">
            {{ s.name }}
          </div>
          <div v-if="!s.readonly && !s.disabled" class="pl-2 d-flex align-center">
            <div v-if="s.completed" class="completed-spot"></div>
            <div v-else class="incomplete-spot"></div>
          </div>
        </div>
        <v-divider class="my-1" v-if="metaSections.length > 0" :vertical="smallScreen"></v-divider>
        <div
          v-for="s in metaSections"
          :key="s.id"
          class="px-4 py-2 flex-shrink-0 d-flex align-top"
          :class="{ 'selected-section': sectionId === s.id }"
          style="cursor: pointer"
          @click="emit('clickSection', s.id)"
        >
          <div class="flex-grow-1 section-sidebar-label" :class="{ 'mobile-section-link': smallScreen }">
            {{ s.name }}
          </div>
        </div>
      </div>
      <div
        class="flex-grow-1"
        :class="{ 'pr-5 pl-2 pt-2': !smallScreen, 'pr-3': smallScreen }"
        :style="{
          maxHeight: maxHeight,
        }"
        style="overflow: auto"
      >
        <mosaic-card-title>{{ section.name }}</mosaic-card-title>
        <div :key="section.id">
          <slot></slot>
        </div>

        <mosaic-error-alert :error="saveError" :action="`save this ${objectType} Page`" />
        <mosaic-success-snackbar v-model="saveSuccess" :object-type="`${objectType} Page`" location="bottom" />
        <div v-if="!hideButtons" class="d-flex flex-wrap justify-end align-center mt-2 mb-1" style="row-gap: 4px">
          <slot name="beside-buttons"></slot>
          <div class="ml-4">
            <div v-if="!section.isMetaSection && !section.readonly">
              <mosaic-btn class="mr-2" :disabled="!canSave" :loading="justSaving" @click="justSave()">Save</mosaic-btn>
              <mosaic-btn
                color="primary"
                :disabled="!canSave"
                :loading="savingAndContinuing"
                @click="saveAndContinue()"
                >{{ nextSectionId ? 'Save and Continue' : 'Save and Return' }}</mosaic-btn
              >
            </div>
            <div v-else>
              <mosaic-btn v-if="nextSectionId" color="primary" @click="clickContinue()">Continue</mosaic-btn>
              <mosaic-btn v-else color="primary" @click="clickReturn()">Return</mosaic-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
  </mosaic-card>
</template>

<script setup lang="ts">
import { useRouter } from 'vue-router';
import { notUndefinedRef } from '@/composables/vue';
import { computed, ref, watchEffect } from 'vue';
import { watch } from 'vue';
import { useSmallScreen } from '@/composables/vuetify';
import { onMounted } from 'vue';
import { nextTick } from 'vue';

interface ContentSection {
  id: number;
  name: string;
  completed: boolean;
  order: number;
  readonly: boolean;
  disabled?: boolean;
  disabledTooltip?: string;
  isMetaSection?: false;
}

interface MetaSection {
  id: number;
  name: string;
  order: number;
  isMetaSection: true;
}

export type Section = ContentSection | MetaSection;

const props = defineProps<{
  title: string;
  objectType: string;
  sections: Section[];
  // sectionId is owned by the page component, to allow easier interacting with unsaved changes and the URL
  // (as we've not got a version of them for script setup), but it is potentially simpler in the long run
  // to have it owned by this component
  sectionId: number;
  canSave: boolean;
  save: () => Promise<void>;
  usedWithConfigurePreview?: boolean;
  hideButtons?: boolean;
}>();

const emit = defineEmits<{
  (e: 'clickSection', sectionId: number): void;
  (e: 'update:sectionId', sectionId: number): void;
}>();

watchEffect(() => {
  const element = document.getElementById(`section-link-${props.sectionId}`);
  element?.scrollIntoView();
});

const titleBlock = ref<HTMLElement>();
const titleBlockHeight = ref(0);
onMounted(async () => {
  // Without waiting for the next tick the title-chip slot is not rendered - potentially a vue problem?
  await nextTick();
  titleBlockHeight.value = titleBlock.value?.offsetHeight || 0;
});
const smallScreen = useSmallScreen();
const maxHeight = computed(() => {
  if (smallScreen.value) return 'unset';

  let maxHeightOffset = 180;
  if (props.usedWithConfigurePreview) maxHeightOffset = 250;

  maxHeightOffset += titleBlockHeight.value;

  return `calc(100vh - ${maxHeightOffset}px)`;
});

const sortedSections = computed(() => props.sections.filter(s => !s.isMetaSection).sortBy('order') as ContentSection[]);
const metaSections = computed(() => props.sections.filter(s => s.isMetaSection).sortBy('order') as MetaSection[]);
const section = notUndefinedRef(
  computed(() => props.sections.find(s => s.id === props.sectionId)),
  'section'
);
const nextSectionId = computed(() => {
  if (section.value.isMetaSection) return null;
  // Because of subject-based filtering, cannot guarantee that order is consecutive, so just find the first higher order
  return props.sections.filter(s => !s.isMetaSection && s.order > section.value.order).sortBy('order')[0]?.id;
});

const justSaving = ref(false);
const savingAndContinuing = ref(false);
const canSave = computed(() => !(justSaving.value || savingAndContinuing.value) && props.canSave);
const saveError = ref(false);
watch(
  () => props.sectionId,
  () => (saveError.value = false)
);
const saveSuccess = ref(false);

async function justSave() {
  justSaving.value = true;
  if (await saveWithErrorHandling()) saveSuccess.value = true;
  justSaving.value = false;
}

const router = useRouter();
async function saveAndContinue() {
  savingAndContinuing.value = true;
  const success = await saveWithErrorHandling();
  if (success) {
    if (nextSectionId.value) {
      clickContinue();
    } else {
      clickReturn();
    }
  }
  savingAndContinuing.value = false;
}

function clickContinue() {
  if (nextSectionId.value) {
    emit('update:sectionId', nextSectionId.value);
  }
}

function clickReturn() {
  router.go(-1);
}

async function saveWithErrorHandling(): Promise<boolean> {
  saveError.value = false;
  try {
    if (props.usedWithConfigurePreview) {
      await new Promise(resolve => setTimeout(resolve, 1000));
    } else {
      await props.save();
    }
    return true;
  } catch (e) {
    console.log(e);
    saveError.value = true;
    return false;
  }
}
</script>

<style scoped>
.selected-section {
  background-color: var(--light-grey);
}

.section-sidebar-label {
  color: rgb(var(--v-theme-primary));
}

.disabled-sidebar-label {
  color: rgb(var(--v-theme-secondary));
}

.border-right {
  border-right: var(--light-grey) 3px solid;
}
.border-bottom {
  border-bottom: var(--light-grey) 3px solid;
}

.mobile-section-link {
  display: block;
  max-width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.completed-spot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: rgb(var(--v-theme-primary));
}
.incomplete-spot {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  background-color: rgb(var(--v-theme-accent));
}
</style>
