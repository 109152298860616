<template>
  <div>
    <v-card class="mb-4">
      <v-card-text>
        <div class="d-flex justify-space-between">
          <v-list-subheader class="pl-6">Config</v-list-subheader>
        </div>
        <v-list-item>
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-light-switch</v-icon>
            </v-avatar>
          </template>
          <div class="pt-4 pl-4" style="width: 220px">
            <v-switch
              v-model="institutionStatus"
              class="mr-6"
              :label="`Status: ${institutionStatus ? 'active' : 'archived'}`"
              @update:model-value="updateInstitutionStatus(institutionStatus)"
              color="primary"
            ></v-switch>
          </div>
        </v-list-item>

        <div v-if="!institution.curriculum">
          <div class="pl-18 pr-4">
            Once a Curriculum has been selected for an institution it cannot be easily changed.
          </div>
          <v-list-item>
            <template #prepend>
              <v-avatar>
                <v-icon>mdi-hexagon-multiple</v-icon>
              </v-avatar>
            </template>
            <div class="d-flex align-center">
              <div class="flex-grow-1 flex-shrink-0">
                <mosaic-select
                  name="curriculumSelect"
                  label="Curriculum Template"
                  v-model="curriculumTemplateIdToAdd"
                  :items="curriculums"
                  placeholder="Select a Curriculum "
                  item-title="name"
                  item-value="id"
                  no-icon
                />
              </div>
              <div>
                <v-btn
                  variant="text"
                  ripple
                  :disabled="!curriculumTemplateIdToAdd || addingCurriculum"
                  @click.prevent="addCurriculum()"
                  >Confirm</v-btn
                >
              </div>
            </div>
          </v-list-item>
        </div>
        <v-list-item v-else ripple>
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-hexagon-multiple</v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>Curriculum</v-list-item-title>
          <v-list-item-subtitle>{{ institution.curriculum.name }}</v-list-item-subtitle>
        </v-list-item>

        <v-list-item
          v-if="institution.standard_set"
          ripple
          @click.prevent="standardSetClick(institution.standard_set.id)"
        >
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-list-status</v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>Standard Set</v-list-item-title>
          <v-list-item-subtitle>{{ institution.standard_set.name }}</v-list-item-subtitle>

          <template #append>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-list-item ripple @click.prevent="configClick()">
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-cog</v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>Other Config</v-list-item-title>
          <template #append>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-list-item v-if="adminInstitution.config.early_careers" ripple @click.prevent="creditsClick()">
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-credit-card-multiple</v-icon>
            </v-avatar>
          </template>
          <v-list-item-title>AB Credits</v-list-item-title>
          <template #append>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </template>
        </v-list-item>
        <v-list-item v-if="adminInstitution.config.early_careers">
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-identifier</v-icon>
            </v-avatar>
          </template>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <mosaic-text-field v-model="establishmentId" name="establishment-id" label="Establishment Id" no-icon />
            </div>
            <v-btn
              variant="text"
              ripple
              class="pl-4"
              :disabled="establishmentId === institution.establishment_id"
              @click.prevent="updateEstablishmentId()"
              >Update</v-btn
            >
          </div>
        </v-list-item>
        <v-list-item>
          <template #prepend
            ><v-avatar><v-icon>mdi-flag</v-icon></v-avatar></template
          >
          <v-list-item-title>Region</v-list-item-title>
          <v-list-item-subtitle>{{ selectedRegion.title }}</v-list-item-subtitle>
        </v-list-item>
        <v-list-item>
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-email</v-icon>
            </v-avatar>
          </template>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <mosaic-text-field name="contact-email" v-model="contactEmail" label="Contact Email" no-icon />
            </div>
            <v-btn
              variant="text"
              ripple
              class="pl-4"
              :disabled="contactEmail === institution.contact_email"
              @click.prevent="updateContactEmail()"
              >Update</v-btn
            >
          </div>
        </v-list-item>
        <v-list-item>
          <template #prepend>
            <v-avatar>
              <v-icon>mdi-key</v-icon>
            </v-avatar>
          </template>
          <div class="d-flex align-center">
            <div class="flex-grow-1">
              <mosaic-text-field
                v-model="defaultPassword"
                name="default-password"
                :append-icon="showDefaultPassword ? 'mdi-eye' : 'mdi-eye-off'"
                label="Default password stem"
                :type="showDefaultPassword ? 'text' : 'password'"
                :error-messages="
                  passwordIsCommonlyUsed ? ['This default password stem creates a commonly used password'] : []
                "
                no-icon
                @click:append="showDefaultPassword = !showDefaultPassword"
                @keyup="onPasswordStemKeyUp"
              />
            </div>
            <v-btn
              variant="text"
              ripple
              class="pl-4"
              :disabled="
                passwordIsCommonlyUsed ||
                passwordIsCommonlyUsedBusy ||
                defaultPassword === institution.default_password_stem
              "
              @click.prevent="updateDefaultPasswordStem()"
              >Update</v-btn
            >
          </div>
        </v-list-item>
      </v-card-text>
      <mosaic-snackbar v-model="snackbar.active" :color="snackbar.color" :message="snackbar.message" />
    </v-card>

    <v-card class="mb-4">
      <v-card-text>
        <v-list-subheader class="pl-6">Roles</v-list-subheader>
        <div v-if="permissionsWithoutRoles.length > 0" class="pl-10">
          Permissions not assigned to any roles: {{ permissionsWithoutRoles.map(x => x.name).join(', ') }}
        </div>
        <mosaic-list :items="institution.roles">
          <template #item="{ item: role }">
            <mosaic-list-item
              :key="role.id"
              icon="mdi-account-check"
              :title="role.name"
              :subtitle="role.permissions.map(x => x.name).join(', ')"
            >
              <template #actions>
                <ndt-icon-button icon="pencil" tooltip="Edit role" @click.prevent="editRole(role)" />

                <ndt-icon-button
                  icon="delete"
                  :disabled="role.staff_roles_count > 0"
                  tooltip="Delete role"
                  @click.prevent="deleteRole(role)"
                />
              </template>
            </mosaic-list-item>
          </template>
          <v-list-item>
            <div class="d-flex align-center">
              <mosaic-text-field
                name="new-role"
                v-model="addRole.name"
                label="Role name"
                prepend-icon="mdi-account-check"
                class="flex-grow-1"
              />
              <v-btn variant="text" ripple :disabled="!addRole.name || addRole.processing" @click.prevent="addNewRole()"
                >Add</v-btn
              >
            </div>
          </v-list-item>
          <mosaic-error-alert :override-error-message="addRole.error" />
        </mosaic-list>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <v-card-text>
        <v-list-subheader class="pl-6">Cohorts</v-list-subheader>
        <mosaic-list :items="institution.cohorts" empty-text="There are no Cohorts to display">
          <template #item="{ item: cohort }">
            <mosaic-list-item
              :key="cohort.id"
              icon="mdi-account-multiple"
              :title="cohort.name"
              :subtitle="renderCohortInfo(cohort)"
              @click.prevent="cohortClick(cohort)"
              show-chevron
            >
              <template #actions>
                <ndt-icon-button icon="pencil" tooltip="Edit role" @click.prevent="editCohort(cohort)" />
              </template>
            </mosaic-list-item>
          </template>
        </mosaic-list>
        <v-list-item>
          <div class="d-flex align-center">
            <mosaic-text-field
              v-model="newCohortName"
              name="add-cohort"
              label="Cohort name"
              prepend-icon="mdi-account-multiple"
              class="flex-grow-1"
            /><mosaic-radio-buttons
              v-if="!adminInstitution.early_careers"
              v-model="selectedAcademicYear"
              :radios="academicYearRadios"
              label="Academic Year:"
              inline
              class="pl-2"
            ></mosaic-radio-buttons>
            <v-btn variant="text" ripple :disabled="!canAddCohort" @click.prevent="addCohort()">Add</v-btn>
          </div>
        </v-list-item>
      </v-card-text>
    </v-card>

    <v-card class="mb-4">
      <mosaic-list-item :to="{ name: 'AdminInstitutionStaffPage' }" icon="mdi-human-male-board" title="Instructors" />
    </v-card>

    <v-card class="mb-4">
      <v-list>
        <v-list-subheader class="pl-6">Student Bulk Upload</v-list-subheader>
        <v-list-item>
          <div class="d-flex">
            <v-file-input :label="'Select CSV of ' + traineeNounPluralised()" v-model="fileObject" />

            <v-select v-model="newBulkStudentsCohort" class="pl-2" :items="cohortNames" label="Cohort" />
          </div>
          <div class="d-flex justify-end align-center">
            <mosaic-checkbox label="Force sign in with Microsoft" v-model="bulkStudentForceMicrosoftSso" />
            <v-btn variant="text" ripple :disabled="!canBulkUpload" @click.prevent="uploadCsv()" class="ml-4"
              >Upload CSV</v-btn
            >
          </div>
        </v-list-item>
        <v-list-item v-if="uploadingStudents">
          <v-progress-linear indeterminate color="accent " />
        </v-list-item>
      </v-list>
      <div>
        <v-alert v-model="bulkStudentsCompletionAlert" class="pa-4" closable :type="successAlertType">
          {{ createdStudents.length + traineeNounPluralised() + ' created successfully: ' }}
          <br />
          <ul>
            <li v-for="student in createdStudents" :key="student.id">{{ student.user.email }}</li>
          </ul>
          <br />
          <template v-if="rejectedStudents.length">
            The following {{ traineeNounPluralised() }} could not be created:
            <br />
            <ul>
              <li v-for="student in rejectedStudents" :key="student.email">
                {{ 'row: ' + student.row + ', ' + student.email + ', errors: ' + student.error_messages.join() }}
              </li>
            </ul>
          </template>
        </v-alert>
        <v-alert v-model="errorAlertBox" class="pa-4" closable type="error"
          >Sorry, could not process this file at the moment</v-alert
        >
      </div>
    </v-card>

    <ndt-dialog v-model:active="editRoleDialog.active" title="Edit Role" :error-message="editRoleDialog.errorMessage">
      <v-text-field v-model="editRoleDialog.name" prepend-icon="mdi-pencil" label="Name" type="text" />
      <div class="font-weight-bold ml-2 mt-2">Permissions</div>
      <v-list density="compact">
        <v-list-item v-for="p of editRoleDialog.permissions" :key="p.id" ripple>
          {{ p.name }} ({{ scopes(p) }})
          <template #append>
            <ndt-icon-button icon="delete" tooltip="Remove permission" @click.prevent="removePermission(p)" />
          </template>
        </v-list-item>
      </v-list>
      <div class="d-flex align-center">
        <div class="flex-grow-1 flex-shrink-1">
          <mosaic-select
            name="permissionToAdd"
            label="Add Permission"
            v-model="editRoleDialog.permissionToAdd"
            class="pl-2"
            :items="unusedPermissions"
            item-value="id"
            item-title="name"
            no-icon
          >
            <template #item="{ item, props }">
              <v-list-item v-bind="props" :title="`${item.raw.name} (${scopes(item.raw)})`" />
            </template>
          </mosaic-select>
        </div>
        <v-btn class="ml-2" @click.prevent="addPermission()">Add</v-btn>
      </div>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="editRoleDialog.processing || !editRoleDialog.name"
          @click.prevent="submitEditRole()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="deleteRoleDialog.active"
      title="Delete Role"
      :error-message="deleteRoleDialog.errorMessage"
      :width="500"
    >
      <span>Are you sure you want to delete "{{ deleteRoleDialog.name }}"?</span>
      <v-alert type="warning">
        <div>Make sure to check this role is not in use with any review templates before deletion</div>
        <div>
          <ul>
            <li>the role is not in use with any review templates</li>
            <li>whether any role-specific staff files will be deleted</li>
          </ul>
        </div>
      </v-alert>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteRoleDialog.processing"
          @click.prevent="submitDeleteRole()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
    <ndt-dialog
      v-model:active="editCohortDialog.active"
      title="Edit Cohort"
      :error-message="editCohortDialog.errorMessage"
      :width="500"
    >
      <v-text-field v-model="editCohortDialog.name" label="Cohort Name" type="text" />
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editCohortDialog.processing" @click.prevent="submitEditCohort()"
          >Update</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="confirmUpdateEstablishmentIdDialog.active"
      title="Confirm Establishment Id update"
      :error-message="confirmUpdateEstablishmentIdDialog.error"
    >
      <span
        >Updating the establishment ID will impact whether DfE bulk upload spreadsheets can be successfully imported.
        Are you sure you want to update this?</span
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          :disabled="confirmUpdateEstablishmentIdDialog.processing"
          @click.prevent="submitUpdateEstablishmentId()"
          >Confirm</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import { getFullAdminInstitution } from '../../models/institutions';
import NdtIconButton from '../../components/NdtIconButton.vue';
import NdtDialog from '../../components/NdtDialog.vue';
import { impersonate } from '../../utils/auth';
import { isPasswordStemCommonlyUsed } from '../../utils/passwords';
import Papa from 'papaparse';
import { mapGetters, mapState } from 'vuex';
import { refreshAdminInstitutions } from '../../models/institutions';
import { validateEmail } from '@/utils/email';

export default {
  name: 'AdminInstitutionPage',
  components: { NdtIconButton, NdtDialog },
  data: function () {
    return {
      institutionId: null,
      institution: {
        cohorts: [],
      },
      errorAlertBox: false,
      curriculums: [],
      showDefaultPassword: false,
      curriculumTemplateIdToAdd: null,
      addingCurriculum: false,
      newBulkStudentsCohort: null,
      bulkStudentForceMicrosoftSso: false,
      newCohortName: '',
      studentToAdd: null,
      fileObject: null,
      uploadingStudents: false,
      createdStudents: [],
      rejectedStudents: [],
      bulkStudentsCompletionAlert: false,
      showStudentDefaultPassword: false,
      defaultPassword: '',
      passwordIsCommonlyUsed: false,
      passwordIsCommonlyUsedBusy: false,
      establishmentId: '',
      contactEmail: '',
      academicYears: [],
      selectedAcademicYear: null,
      confirmUpdateEstablishmentIdDialog: {
        error: '',
        active: false,
        processing: false,
      },
      snackbar: {
        active: false,
        color: '',
        message: '',
      },
      addRole: {
        name: '',
        processing: false,
        error: null,
      },
      editCohortDialog: {
        active: false,
        id: null,
        name: '',
        processing: false,
        errorMessage: '',
      },
      editRoleDialog: {
        active: false,
        processing: false,
        errorMessage: null,
        permissions: [],
        roleId: null,
        name: '',
        permissionToAdd: null,
      },
      deleteRoleDialog: {
        active: false,
        processing: false,
        errorMessage: null,
        name: '',
        roleId: null,
      },
      institutionStatus: null,
    };
  },
  computed: {
    ...mapState(['adminInstitution', 'permissions', 'user']),
    ...mapGetters(['selectedRegion']),
    breadcrumbs() {
      return [
        {
          text: this.adminInstitution?.name,
        },
      ];
    },
    academicYearRadios() {
      return this.academicYears.map((w, index) => {
        return {
          label: w.name,
          value: index,
        };
      });
    },
    cohortNames() {
      return this.institution.cohorts.map(c => c.name);
    },
    canAddCohort() {
      return this.newCohortName && (this.selectedAcademicYear !== null || this.adminInstitution.early_careers);
    },
    canBulkUpload() {
      return !!this.fileObject && this.newBulkStudentsCohort && !this.uploadingStudents;
    },
    successAlertType() {
      return this.rejectedStudents.length ? 'warning' : 'success';
    },
    permissionsWithoutRoles() {
      if (!this.permissions || !this.institution) return [];
      return this.permissions.filter(x => this.institution.roles.every(r => r.permissions.every(p => p.id !== x.id)));
    },
    unusedPermissions() {
      const usedPermissionIds = this.editRoleDialog.permissions.map(x => x.id);
      return this.permissions.filter(x => !usedPermissionIds.includes(x.id));
    },
  },
  watch: {
    adminInstitution(x) {
      this.institution = x;
      this.defaultPassword = x.default_password_stem || '';
      this.bulkStudentForceMicrosoftSso = x.config.allow_forcing_microsoft_sso;
    },
    institutionStatus(x) {
      this.institution = {
        ...this.institution,
        status: x,
      };
    },
  },
  async created() {
    this.institutionId = this.$route.params.institutionId;
    this.$api.get('/curriculum-templates').then(r => {
      this.curriculums = r.data;
    });
    this.institution = this.adminInstitution;
    this.$store.dispatch('loadPermissionsForAdmin');

    this.institutionStatus = this.institution.status === 'active';
    this.defaultPassword = this.institution.default_password_stem || '';
    this.establishmentId = this.institution.establishment_id || '';
    this.contactEmail = this.institution.contact_email || '';
    this.academicYears = this.institution.academic_years;
    this.bulkStudentForceMicrosoftSso = this.institution.config.allow_forcing_microsoft_sso;
  },
  methods: {
    async addNewRole() {
      this.addRole.processing = true;
      this.addRole.error = null;
      try {
        await this.$api.post(`/admin/roles`, {
          name: this.addRole.name,
          institutionId: this.institution.id,
        });
        getFullAdminInstitution(this.adminInstitution.id, this.$api);
        this.addRole.name = '';
      } catch (e) {
        console.log(e);
        this.addRole.error = 'Sorry, cannot add a role at the moment';
      }
      this.addRole.processing = false;
    },
    async updateInstitutionStatus(status) {
      this.processing = true;
      try {
        await this.$api.put(`/institutions/${this.adminInstitution.id}`, {
          status: status ? 'active' : 'archived',
        });
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Updated',
        };
        refreshAdminInstitutions(this.$api);
      } catch (e) {
        this.institutionStatus = !status;
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Error whilst updating',
        };
      }
      this.processing = false;
    },
    editRole(role) {
      this.editRoleDialog = {
        ...this.editRoleDialog,
        active: true,
        name: role.name,
        permissions: role.permissions,
        roleId: role.id,
      };
    },
    removePermission(p) {
      this.editRoleDialog.permissions = this.editRoleDialog.permissions.filter(x => x.id !== p.id);
    },
    addPermission() {
      this.editRoleDialog.permissions = this.editRoleDialog.permissions.concat(
        this.permissions.find(x => this.editRoleDialog.permissionToAdd === x.id)
      );
      this.editRoleDialog.permissionToAdd = null;
    },
    async submitEditRole() {
      this.editRoleDialog.processing = true;
      this.editRoleDialog.errorMessage = null;
      try {
        await this.$api.put(`/admin/roles/${this.editRoleDialog.roleId}`, {
          permissionIds: this.editRoleDialog.permissions.map(x => x.id),
          name: this.editRoleDialog.name,
        });
        getFullAdminInstitution(this.adminInstitution.id, this.$api);
        this.editRoleDialog.active = false;
      } catch (e) {
        console.log(e);
        this.editRoleDialog.errorMessage = 'Sorry, cannot edit this role';
      }
      this.editRoleDialog.processing = false;
    },
    deleteRole(role) {
      this.deleteRoleDialog = {
        ...this.editRoleDialog,
        active: true,
        name: role.name,
        roleId: role.id,
      };
    },
    async submitDeleteRole() {
      this.deleteRoleDialog.processing = true;
      this.deleteRoleDialog.errorMessage = null;
      try {
        await this.$api.delete(`/admin/roles/${this.deleteRoleDialog.roleId}`);
        getFullAdminInstitution(this.adminInstitution.id, this.$api);
        this.deleteRoleDialog.active = false;
      } catch (e) {
        console.log(e);
        this.deleteRoleDialog.errorMessage = 'Sorry, cannot delete this role';
      }
      this.deleteRoleDialog.processing = false;
    },
    editCohort(cohort) {
      this.editCohortDialog = {
        active: true,
        name: cohort.name,
        processing: false,
        id: cohort.id,
        errorMessage: '',
      };
    },
    async submitEditCohort() {
      this.editCohortDialog.errorMessage = '';
      this.editCohortDialog.processing = true;
      try {
        const r = await this.$api.put(`/cohorts/${this.editCohortDialog.id}/admin-update`, {
          name: this.editCohortDialog.name,
        });
        this.institution.cohorts.find(c => c.id === this.editCohortDialog.id).name = r.data.name;
        this.editCohortDialog.active = false;
      } catch (e) {
        console.log(e);
        this.editCohortDialog.errorMessage = 'Sorry, cannot rename cohort at the moment';
      }

      this.editCohortDialog.processing = false;
    },
    uploadCsv() {
      this.bulkStudentsWarningAlert = false;
      this.bulkStudentsSuccessAlert = false;
      Papa.parse(this.fileObject, { complete: this.postBulkStudents });
    },
    async postBulkStudents(results) {
      const cohort = this.institution.cohorts.find(c => c.name == this.newBulkStudentsCohort);
      const students = results.data.map((s, row) => {
        return {
          email: s[0],
          name: s[1] || '',
          subject: s[2] || '',
          row: row,
        };
      });
      //remove header (file must contain header!)
      students.shift();
      try {
        this.uploadingStudents = true;
        const r = await this.$api.post(`/students/bulk-upload`, {
          students: students,
          cohortId: cohort.id,
          forceMicrosoftSso: this.bulkStudentForceMicrosoftSso,
        });
        this.createdStudents = r.data.created_students;
        this.rejectedStudents = r.data.rejected_students;
        cohort.students_including_archived_count += this.createdStudents.length;
        this.bulkStudentsCompletionAlert = true;
        this.uploadingStudents = false;
        this.fileObject = null;
        this.errorAlertBox = false;
      } catch (e) {
        this.uploadingStudents = false;
        this.errorAlertBox = true;
      }
    },
    configClick() {
      this.$router.push({
        name: 'AdminInstitutionConfigPage',
      });
    },
    creditsClick() {
      this.$router.push({
        name: 'AdminInstitutionCreditsPage',
      });
    },
    async addCohort() {
      try {
        const r = await this.$api.post(`/cohorts`, {
          name: this.newCohortName,
          institutionId: this.institutionId,
          academicYear: this.adminInstitution.config.early_careers
            ? null
            : this.academicYears[this.selectedAcademicYear].name,
        });
        this.institution.cohorts.push(r.data);
        this.newCohortName = '';
        this.selectedAcademicYear = null;
      } catch (e) {
        console.log(e);
      }
    },

    standardSetClick(standardSetId) {
      this.$router.push({
        name: 'AdminStandardSetStandardsPage',
        params: { standardSetId: standardSetId },
      });
    },
    async updateDefaultPasswordStem() {
      try {
        const r = await this.$api.put(`/institutions/${this.institution.id}/update-default-password`, {
          default_password_stem: this.defaultPassword,
        });
        this.defaultPassword = r.data.default_password_stem;
        this.institution.default_password_stem = this.defaultPassword;
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Updated default password stem',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Error updating default password stem',
        };
      }
    },
    updateEstablishmentId() {
      this.confirmUpdateEstablishmentIdDialog.active = true;
    },
    async submitUpdateEstablishmentId() {
      this.confirmUpdateEstablishmentIdDialog.processing = true;
      try {
        await this.$api.put(`institutions/${this.institution.id}/update-establishment-id`, {
          establishment_id: this.establishmentId,
        });
        this.institution.establishment_id = this.establishmentId;
        this.confirmUpdateEstablishmentIdDialog = {
          error: '',
          active: false,
          processing: false,
        };
      } catch (e) {
        console.log(e);
        this.confirmUpdateEstablishmentIdDialog.error = 'Sorry, cannot update your establishment id at the moment';
      }
      this.confirmUpdateEstablishmentIdDialog.processing = false;
    },
    async updateContactEmail() {
      if (!validateEmail(this.contactEmail)) {
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Not a valid email',
        };
        return;
      }

      try {
        await this.$api.put(`institutions/${this.institution.id}/update-contact-email`, {
          contact_email: this.contactEmail,
        });
        this.institution.contact_email = this.contactEmail;
        this.snackbar = {
          active: true,
          color: 'success',
          message: 'Updated contact email',
        };
      } catch (e) {
        console.log(e);
        this.snackbar = {
          active: true,
          color: 'error',
          message: 'Error updating contact email',
        };
      }
    },

    renderCohortInfo(cohort) {
      return `${cohort.name} - ${cohort.students_including_archived_count} student${
        cohort.students_including_archived_count === 1 ? '' : 's'
      }`;
    },
    cohortClick(cohort) {
      this.$store.commit('updateAdminCohort', cohort);
      this.$router.push({
        name: 'AdminInstitutionCohortPage',
        params: { institutionId: this.institution.id, cohortId: cohort.id },
      });
    },
    async addCurriculum() {
      this.addingCurriculum = true;
      await this.$api.post(`/curriculum-templates/${this.curriculumTemplateIdToAdd}/copy-to-institution`, {
        institutionId: this.institutionId,
      });
      const r = await this.$api.get(`/institutions/${this.institutionId}`);
      this.institution = r.data;
      this.addingCurriculum = false;
    },
    async impersonate(userId) {
      const response = await this.$api.post(`/impersonate`, { target_id: userId });
      impersonate(response.data.auth_token);
    },
    scopes(permission) {
      const scopes = [];
      if (permission.student_scope) scopes.push('Student');
      if (permission.school_scope) scopes.push('School');
      if (permission.cohort_scope) scopes.push('Cohort');
      if (permission.institution_scope) scopes.push('Institution');
      return scopes.join(', ');
    },
    async onPasswordStemKeyUp() {
      if (!this.defaultPassword) {
        this.passwordIsCommonlyUsed = false;
        return;
      }
      this.passwordIsCommonlyUsedBusy = true;
      this.passwordIsCommonlyUsed = false;
      this.passwordIsCommonlyUsed = await isPasswordStemCommonlyUsed(this.$api, this.defaultPassword);
      this.passwordIsCommonlyUsedBusy = false;
    },
  },
};
</script>

<style scoped>
.list-hotfix {
  flex: 1 1 auto !important;
  overflow: hidden;
}
</style>
