<template>
  <assignments-list
    :url="`/students/${selectedStudent.id}/student-assignments`"
    :click-student-assignment-to="
      sa => ({ name: 'TutorAssignmentLatestVersionPage', params: { id: sa.assignment.id.toString() } })
    "
  />
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import AssignmentsList from './AssignmentsList.vue';
import { mapState } from '@/store/map-store';

const { selectedStudent } = mapState();
setBreadcrumbs([
  {
    text: 'Course Activities',
  },
]);
</script>
