<template>
  <mosaic-card>
    <mosaic-card-title>Instructor Training</mosaic-card-title>
    <div class="d-flex py-2 align-center flex-wrap">
      <monitoring-mode-select v-model:mode="mode" class="mr-2" :modes="modes" />
    </div>
    <mosaic-table
      :columns="columns"
      :rows="props.trainingData"
      object-type-pluralised="Instructors"
      no-data-suffix="in this Cohort"
    />
  </mosaic-card>
</template>

<script setup lang="ts">
import MosaicTable from '@/components/monitoring/MosaicTable.vue';
import type { Column, EnumColumn, Row } from '@/components/monitoring/mosaic-table';
import MonitoringModeSelect from '@/components/monitoring/MonitoringModeSelect.vue';
import { ref } from 'vue';
import { hasPermission } from '@/mixins/global-mixins';
import { mapState } from '@/store/map-store';

const props = defineProps<{
  modules: { id: number; name: string }[];
  trainingData: StaffTrainingData[];
}>();

const modes = [{ value: 'modules', label: 'Training Requirements' }];
const mode = ref('modules');

type StaffTrainingData = {
  id: number;
  name: string;
  roles: string[];
} & Record<string, { value: 'Not Started' | 'Started' | 'Completed' }>;

const statusColumns = props.modules.map<EnumColumn>(m => ({
  name: m.name,
  key: m.id.toString(),
  clickRoute: (row: Row) => {
    const staffTrainingData = row as StaffTrainingData;
    const data = staffTrainingData[m.id];
    if (!data.value) return undefined;
    return {
      name: 'TutorCohortStaffTrainingModulePage',
      params: {
        staffId: staffTrainingData.id.toString(),
        moduleId: m.id.toString(),
      },
    };
  },
  chip: (row: Row) => {
    const staffTrainingData = row as StaffTrainingData;
    const data = staffTrainingData[m.id];
    return {
      hide: !data.value,
      text: data.value,
      color: data.value === 'Completed' ? 'primary' : 'accent',
      value: data.value,
    };
  },
  filter: {
    type: 'select',
    items: [
      { title: 'All', value: null },
      { title: 'Completed', value: 'Completed' },
      { title: 'Not Completed', value: 'Not Completed' },
      { title: 'Not Assigned', value: 'Not Assigned' },
    ],
    value: (row: Row) => {
      const staffTrainingData = row as StaffTrainingData;
      const data = staffTrainingData[m.id];
      return data.value === 'Completed'
        ? 'Completed'
        : data.value === 'Started' || data.value === 'Not Started'
        ? 'Not Completed'
        : 'Not Assigned';
    },
  },
  sort: (row: Row) => {
    const staffTrainingData = row as StaffTrainingData;
    const data = staffTrainingData[m.id];
    return data.value === 'Completed' ? 1 : data.value === 'Started' ? 2 : data.value === 'Not Started' ? 3 : 4;
  },
}));

const { userStaff, selectedInstitution } = mapState();
const columns: Column[] = [
  {
    name: 'Name',
    key: 'name',
    sticky: true,
    clickRoute: hasPermission(userStaff.value, 'staff.edit')
      ? (row: Row) => ({
          name: 'TutorStaffPage',
          params: {
            institutionId: selectedInstitution.value.id.toString(),
            id: (row as StaffTrainingData).id.toString(),
          },
          query: {
            tab: 'training-requirements',
          },
        })
      : undefined,
    text: (row: Row) => (row as StaffTrainingData).name,
    secondaryText: (row: Row) => (row as StaffTrainingData).roles.join(', '),
  },
  ...statusColumns,
];
</script>
