export const assignmentStatuses = [
  'not_started',
  'started',
  'completed',
  'reviewed',
  'reviewed_requires_changes',
] as const;
export type AssignmentStatus = (typeof assignmentStatuses)[number];

export const AssignmentStatusItems: {
  text: string;
  value: AssignmentStatus;
  order: number;
  color: 'accent' | 'primary';
}[] = [
  { text: 'Not Started', value: 'not_started', order: 0, color: 'accent' },
  { text: 'Started', value: 'started', order: 1, color: 'accent' },
  { text: 'Completed', value: 'completed', order: 2, color: 'primary' },
  { text: 'Reviewed', value: 'reviewed', order: 3, color: 'primary' },
  { text: 'Reviewed (Requires Changes)', value: 'reviewed_requires_changes', order: 4, color: 'primary' },
];

export function mapAssignmentStatusToChip(status: AssignmentStatus) {
  // Safe to use "!" because we're restricting the status argument to the values in AssignmentStatuses
  const text = AssignmentStatusItems.find(s => s.value === status)!.text;
  const color = AssignmentStatusItems.find(s => s.value === status)!.color;
  let tooltip = '';
  switch (status) {
    default:
    case 'not_started':
      tooltip = 'The Course Activity has not been started';
      break;
    case 'started':
      tooltip = 'The Course Activity has been started, but not yet marked as complete';
      break;
    case 'completed':
      tooltip = 'The Course Activity has been completed, but not yet marked as reviewed';
      break;
    case 'reviewed':
      tooltip = 'The Course Activity has been completed and reviewed';
      break;
    case 'reviewed_requires_changes':
      tooltip = 'The Course Activity has been reviewed and requires changes';
      break;
  }
  return { text, color, tooltip };
}

const WeekStatuses = ['not_started', 'started', 'completed'] as const;
export type WeekStatus = (typeof WeekStatuses)[number];

export const WeekStatusItems: {
  text: string;
  value: WeekStatus;
  order: number;
  color: 'accent' | 'primary';
}[] = [
  { text: 'Not Started', value: 'not_started', order: 0, color: 'accent' },
  { text: 'Started', value: 'started', order: 1, color: 'accent' },
  { text: 'Completed', value: 'completed', order: 2, color: 'primary' },
];

export function mapWeekStatusToSortOrder(status: WeekStatus) {
  switch (status) {
    default:
    case 'not_started':
      return 0;
    case 'started':
      return 1;
    case 'completed':
      return 2;
  }
}

export function mapWeekStatusToChip(
  status: WeekStatus,
  studentViewer: boolean,
  traineeNounCapitalised: string,
  weekNounCapitalised: string
) {
  // Safe to use "!" because we're restricting the status argument to the values in WeekStatuses
  const text = WeekStatusItems.find(s => s.value === status)!.text;
  const color = WeekStatusItems.find(s => s.value === status)!.color;
  let tooltip = '';
  if (studentViewer) {
    switch (status) {
      default:
      case 'not_started':
        tooltip = `You have not started the ${weekNounCapitalised}`;
        break;
      case 'started':
        tooltip = `You have started the ${weekNounCapitalised}, but not yet marked it as complete`;
        break;
      case 'completed':
        tooltip = `You have completed the ${weekNounCapitalised}`;
        break;
    }
  } else {
    switch (status) {
      default:
      case 'not_started':
        tooltip = `The ${traineeNounCapitalised} has not started the ${weekNounCapitalised}`;
        break;
      case 'started':
        tooltip = `The ${traineeNounCapitalised} has started the ${weekNounCapitalised} but not yet marked it as complete`;
        break;
      case 'completed':
        tooltip = `The ${traineeNounCapitalised} has completed the ${weekNounCapitalised}`;
        break;
    }
  }
  return { text, color, tooltip };
}
