import { getAuthToken } from './auth';
import type { Api } from '@/composables/api';
export function startStudentLinkOneDriveFlow(returnPath: string) {
  setLinkStorageEmailCookies(returnPath);
  const scopes = 'user.read files.readwrite.appfolder offline_access email openid files.readwrite.all';
  microsoftAuthFlow(scopes);
}

export function startStaffLinkOneDriveEmailMicrosoftFlow(returnPath: string, institutionId: number) {
  setLinkStorageEmailCookies(returnPath, institutionId);
  const scopes = 'email openid';
  microsoftAuthFlow(scopes);
}

export function startStudentLinkGoogleDriveFlow(returnPath: string, api: Api) {
  setLinkStorageEmailCookies(returnPath);
  googleAuthFlow(api);
}

// This should be different from the student flow because of permissions, but currently this requests all the same permissions as student
export function startStaffLinkGoogleDriveEmailFlow(returnPath: string, api: Api) {
  setLinkStorageEmailCookies(returnPath);
  googleAuthFlow(api);
}

export function startGoogleSsoFlow(api: Api) {
  setSsoCookies();
  googleAuthFlow(api);
}

export function startMicrosoftSsoFlow() {
  setSsoCookies();
  // We ask for all permissions in case it's a student who we need to create their files folder for
  // Could potentially use URLs to differentiate and therefore ask for fewer permissions for staff?
  const scopes = 'user.read files.readwrite.appfolder offline_access email openid files.readwrite.all';
  microsoftAuthFlow(scopes);
}

function microsoftAuthFlow(scopes: string) {
  const clientId = window.ndt_config.one_drive_client_id;
  const redirect_uri = encodeURIComponent(window.ndt_config.one_drive_redirect_uri);
  scopes = encodeURIComponent(scopes);
  const url = `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?prompt=select_account&client_id=${clientId}&response_type=code&redirect_uri=${redirect_uri}&response_mode=form_post&scope=${scopes}`;
  window.location.href = url;
}

function googleAuthFlow(api: Api) {
  api.get<{ url: string }>('google-drive-auth-url').then(r => {
    window.location.href = r.data.url;
  });
}

function setSsoCookies() {
  clearCookie('auth_token_cookie');
  clearCookie('next_step_cookie');
  clearCookie('institution_id_cookie');
  setCookie('frontend_app_version', window.ndt_config.app_version);
}

function setLinkStorageEmailCookies(returnPath: string, institutionId?: number) {
  const authToken = getAuthToken();
  if (!authToken) {
    throw 'Tried to set auth_token_cookie, but there is no auth token';
  }
  setCookie('auth_token_cookie', encodeURIComponent(authToken));
  setCookie('next_step_cookie', returnPath);

  if (institutionId) {
    setCookie('institution_id_cookie', institutionId);
  }
}

function setCookie(name: string, value: string | number) {
  document.cookie = `${name}=${value}; max-age=3600;  path=/; SameSite=None; secure`;
}

function clearCookie(name: string) {
  document.cookie = `${name}=''; max-age=0; path=/; secure`;
}
