<template>
  <mosaic-card-subheading>Excluded Institutions</mosaic-card-subheading>
  <mosaic-list :items="exclusions" empty-text="There are no Institution exclusions for this Resource">
    <template #item="{ item: exclusion }">
      <mosaic-list-item :item="exclusion" icon="book-open" :title="exclusion.institutionName">
        <template #actions>
          <mosaic-icon-btn
            icon="delete"
            tooltip="Remove Exclusion"
            @click.prevent="emit('clickRemoveExclusion', exclusion.institutionId)"
          />
        </template>
      </mosaic-list-item>
    </template>
  </mosaic-list>
  <mosaic-card-subheading>New Exclusion</mosaic-card-subheading>
  <div class="d-flex align-center">
    <mosaic-select
      v-model="newExclusion"
      name="institutions"
      label="Institutions"
      :items="notExcludedInstitutions"
      prepend-icon="mdi-book-open"
      class="flex-grow-1 mr-2"
      item-title="institutionName"
      item-value="institutionId"
      return-object
    />
    <mosaic-btn ripple @click.prevent="emit('clickAddExclusion', newExclusion)" :disabled="!canAddExclusion">
      Add
    </mosaic-btn>
  </div>
</template>

<script setup lang="ts">
import { mapState } from '@/store/map-store';
import { refreshAdminInstitutions } from '@/models/institutions';
import { useApi } from '@/composables/api';
import { computed, ref } from 'vue';
import type { Exclusion } from './professional-resource';

const api = useApi();

const props = defineProps<{
  exclusions: Array<Exclusion>;
}>();
refreshAdminInstitutions(api, true);
const { institutions } = mapState();
const notExcludedInstitutions = computed(
  () =>
    institutions.value
      ?.filter(i => !props.exclusions?.some(e => e.institutionId === i.id))
      .map(i => ({ institutionId: i.id, institutionName: i.name })) || []
);

const newExclusion = ref<{ institutionId: number; institutionName: string } | null>(null);

const canAddExclusion = computed(() => !!newExclusion.value);

const emit = defineEmits<{
  (e: 'clickAddExclusion', newExclusion: Exclusion | null): void;
  (e: 'clickRemoveExclusion', institutionId: number): void;
}>();
</script>
