import { defineStore } from 'pinia';
import { useApi } from '@/composables/api';
import { watch, ref } from 'vue';
import { useStore } from './common';
import type { TargetResponse } from '@/types/responses';
import { useUserStore } from './user';
import { useStudentStore } from './student';

export const useActiveTargetsStore = useStore(
  defineStore('active-targets', () => {
    const activeTargets = ref<TargetResponse[]>([]);
    const userType = ref<'student' | 'staff' | null>(null);
    const studentId = ref<number | null>(null);
    const load = ref(true);
    const error = ref(false);

    const api = useApi();

    const { selectedStudent } = useStudentStore();
    const { userStudent, user } = useUserStore();

    // watch the user and the selectedStudent to trigger state changes
    watch(
      user,
      (curUserDetails, prevUserDetails) => {
        if (!curUserDetails) {
          userType.value = null;
          return;
        }
        if (curUserDetails?.student) {
          userType.value = 'student';
        } else if (curUserDetails?.staff && curUserDetails?.staff.length > 0) {
          userType.value = 'staff';
        }

        if (curUserDetails !== prevUserDetails && userType.value === 'student') {
          // the active targets should load only if the user is student
          loadTargets();
        }
      },
      { deep: true }
    );

    watch(
      selectedStudent,
      (curSelectedStudent, prevStudent) => {
        if (!curSelectedStudent) {
          studentId.value = null;
          return;
        }
        if (curSelectedStudent?.id !== prevStudent?.id && userType.value === 'staff') {
          const id = +curSelectedStudent?.id;
          studentId.value = typeof id === 'number' && !isNaN(id) ? id : null;
          // the active targets should load only if the user is staff and a student is selected
          if (studentId.value) loadTargets();
        }
      },
      { deep: true }
    );

    const loadTargets = async () => {
      try {
        if (
          !userType.value ||
          (userType.value === 'staff' && !studentId.value) ||
          (userType.value == 'student' && !userStudent.value)
        )
          return;
        load.value = true;
        const url =
          userType.value === 'student'
            ? `/students/${userStudent.value.id}/targets`
            : `/students/${studentId.value}/targets`;
        const r = await api.get<TargetResponse[]>(url + '?status=active&sort=asc');
        activeTargets.value = r.data;
        error.value = false;
      } catch (e) {
        error.value = true;
      } finally {
        load.value = false;
      }
    };

    const addTarget = (target: TargetResponse) => {
      activeTargets.value.push(target);
    };

    const removeTarget = (id: number) => {
      activeTargets.value = activeTargets.value.filter(x => Number(x.id) !== Number(id));
    };

    const updateTarget = (target: TargetResponse) => {
      const i = activeTargets.value.findIndex(x => Number(x.id) === Number(target.id));
      const existingIndex = i === -1 ? null : i;
      if (existingIndex === null && target.status === 'active') {
        addTarget(target);
        return;
      }

      if (existingIndex !== null) {
        if (target.status === 'completed' || target.status === 'approved') {
          removeTarget(target.id);
        } else {
          activeTargets.value = activeTargets.value.map(t => (Number(t.id) === Number(target.id) ? target : t));
        }
        return;
      }
    };

    return {
      activeTargets,
      studentIdActiveTargets: studentId,
      activeTargetLoading: load,
      activeTargetsError: error,
      removeTarget,
      addTarget,
      updateTarget,
      loadTargets,
    };
  })
);
