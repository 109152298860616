<template>
  <div>
    <mosaic-table
      :columns="columns"
      :rows="items"
      fixed-header
      :object-type-pluralised="objectTypePluralised"
      show-not-assigned
    />
  </div>
</template>

<script>
import MosaicTable from '@/components/monitoring/MosaicTable.vue';

export default {
  name: 'MonitoringStatusTable',
  components: { MosaicTable },
  props: {
    headers: {
      type: Array, // [{ label: string, key: string, values: string[] }]
      required: true,
    },
    items: {
      type: Array, // [{ name: string, [key in this.headers.map(h => h.key)]: { value: string, color: string, link: {} } }]
      required: true,
    },
    objectTypePluralised: {
      type: String,
      required: true,
    },
  },
  computed: {
    columns() {
      return [
        {
          key: 'name',
          name: 'Name',
          sticky: true,
          text: r => r.name.value,
          clickRoute: r => r.name.link,
        },
      ].concat(
        this.headers.map(h => {
          return {
            key: h.key,
            name: h.label,
            chip: r => ({
              text: r[h.key]?.value,
              value: r[h.key]?.value,
              color: r[h.key]?.color,
            }),
            filter: h.values
              ? {
                  type: 'select',
                  items: [{ title: 'All', value: null }, ...h.values.map(v => ({ title: v, value: v }))],
                }
              : undefined,
            clickRoute: r => r[h.key]?.link,
          };
        })
      );
    },
  },
};
</script>
