<template>
  <div>
    <mosaic-loading-card v-if="busy" type="inline-table" />
    <div v-else-if="error" class="pa-4">
      {{ error }}
    </div>
    <div class="pa-4" v-else-if="mappedReviews.length === 0">
      You have no {{ reviewNounPluralised }} to monitor for this cohort
    </div>
    <template v-else-if="!busy">
      <div class="d-flex align-center flex-wrap">
        <monitoring-mode-select v-model:mode="mode" class="mr-2" :modes="modes" />
      </div>
      <div class="d-flex pt-2 align-center flex-wrap pb-6">
        <monitoring-review-select v-model:selected-review-id="selectedReviewId" class="mr-4" :reviews="mappedReviews" />
        <mosaic-cohort-monitoring-filters
          v-if="mode === 'standards'"
          :students="selectedCohortStudents"
          @update:filtered-student-ids="filteredStudentIds = $event"
        />
        <div v-if="selectedReview && !selectedReview.isLegacy" class="mt-3">
          <div v-if="selectedReview.has_judgements && mode === 'standards'" class="ml-4">
            <v-btn @click.prevent="downloadStandardJudgements">Download</v-btn>
          </div>
          <div v-else-if="selectedReview && selectedReview.has_statements && mode === 'statements'">
            <v-btn
              :disabled="downloadingStatementJudgements"
              :loading="downloadingStatementJudgements"
              @click.prevent="downloadStatementJudgements"
              >Download
            </v-btn>
          </div>
          <mosaic-snackbar v-model="errorSnackbar.active" color="error" :message="errorSnackbar.message" />
        </div>
      </div>
      <template v-if="mode == 'standards'">
        <monitoring-status-table
          v-if="selectedReviewMonitoring"
          :headers="selectedReviewMonitoring.headers"
          :items="selectedReviewMonitoringStudents"
          :object-type-pluralised="traineeNounCapitalisedAndPluralised"
        />
        <div v-else class="pt-2 pb-6 pl-8">
          This {{ reviewNounCapitalised }} was not configured to include
          {{ selectedReview && selectedReview.standard_noun }} level monitoring. If you would like to monitor
          completion, click
          <mosaic-router-link
            :to="{
              name: 'TutorAdminReviewViewPage',
              params: { cohortId: selectedCohort.id, reviewId: selectedReview && selectedReview.id },
            }"
            >here</mosaic-router-link
          >.
        </div>
      </template>
      <template v-else-if="mode === 'statements'">
        <template v-if="selectedReview.has_statements">
          <div class="pt-2 pb-6 pl-8">
            This data cannot currently be visualised, but you can download it using the button in the top right.
          </div>
        </template>
        <div v-else class="pt-2 pb-6 pl-8">
          This {{ reviewNounCapitalised }} was not configured to include statement level monitoring.
          <span v-if="!selectedReview.legacy">
            If you would like to monitor completion, click
            <mosaic-router-link
              :to="{
                name: 'TutorAdminReviewViewPage',
                params: { cohortId: selectedCohort.id, reviewId: selectedReview.id },
              }"
              >here</mosaic-router-link
            >.
          </span>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MonitoringModeSelect from '@/components/monitoring/MonitoringModeSelect.vue';
import MonitoringReviewSelect from '@/components/monitoring/MonitoringReviewSelect.vue';
import MonitoringStatusTable from '@/components/monitoring/MonitoringStatusTable.vue';
import XLSX from 'xlsx';
import moment from 'moment';
import { useCohortStore } from '@/stores/cohort';

export default {
  name: 'CohortReviewsPageMonitoringTab',
  components: {
    MonitoringModeSelect,
    MonitoringReviewSelect,
    MonitoringStatusTable,
  },
  setup() {
    const { selectedCohortStudents } = useCohortStore();
    return { selectedCohortStudents };
  },
  data: () => ({
    busy: true,
    groupFilter: null,
    reviewMonitorings: {},
    error: null,
    mode: 'standards',
    traineeFilter: '',
    approvedFilter: 'all',
    reviews: [],
    selectedReviewId: null,
    errorSnackbar: {
      message: '',
      active: false,
    },
    filteredStudentIds: [],
    downloadingStatementJudgements: false,
  }),
  computed: {
    ...mapState(['selectedCohort', 'judgementSets', 'groups']),
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalisedAndPluralised} Monitoring`,
        },
      ];
    },
    selectedReviewMonitoring() {
      return this.reviewMonitorings[this.selectedReviewId];
    },
    selectedReviewMonitoringStudents() {
      if (!this.selectedReviewMonitoring) return [];
      return this.selectedReviewMonitoring.students.filter(
        x =>
          x.name.value.toLowerCase().includes(this.traineeFilter.toLowerCase()) &&
          this.filteredStudentIds.includes(x.student_id) &&
          (this.approvedFilter === 'all' ||
            (this.approvedFilter === 'active' && x.status.value === 'Active') ||
            (this.approvedFilter === 'approved' && x.status.value !== 'Active'))
      );
    },
    modes() {
      return [
        { value: 'standards', label: `Judgements` },
        { value: 'statements', label: 'Statement Judgements' },
      ];
    },
    selectedReview() {
      return this.mappedReviews.find(x => x.id === this.selectedReviewId);
    },
    mappedReviews() {
      return this.reviews.map(x => ({ ...x, id: x.id.toString() }));
    },
  },
  watch: {
    mappedReviews() {
      if (this.mappedReviews.length === 0) return;
      this.selectedReviewId = this.selectedReviewId || this.mappedReviews[0].id.toString();
    },
    async selectedReviewId(id) {
      const review = this.mappedReviews.find(r => r.id === id);
      if (review.has_judgements) {
        this.busy = true;
        try {
          const response = await this.$api.get(`/cohorts/${this.selectedCohort.id}/review-judgements-monitoring/${id}`);
          this.reviewMonitorings = { ...this.reviewMonitorings, [id]: response.data };
        } catch (e) {
          console.log(e);
          this.error = `Sorry, cannot load your ${this.reviewNoun} monitoring at the moment. Please refresh the page to try again.`;
        }
        this.busy = false;
      }
    },
  },
  async created() {
    this.$store.dispatch('loadJudgementSets');
    this.filteredStudentIds = this.selectedCohortStudents.map(x => x.id);
    try {
      const reviewsResponse = await this.$api.get(`/cohorts/${this.selectedCohort.id}/reviews`);
      this.reviews = reviewsResponse.data;
    } catch (e) {
      console.log(e);
      this.error = `Sorry, cannot load your ${this.reviewNounCapitalisedAndPluralised} at the moment. Please refresh the page to try again.`;
    }
    this.busy = false;
  },
  methods: {
    downloadStandardJudgements() {
      const xlxs = XLSX.utils.book_new();
      const headers = this.selectedReviewMonitoring.headers;
      const data = [
        ['Email', 'Name', 'Groups', ...headers.map(x => x.label)],
        ...this.selectedReviewMonitoringStudents.map(x => [
          x.email,
          x.name.value,
          // Group may have been created by someone else and store isn't upto date
          x.group_ids
            .map(g => this.groups.find(x => x.id === g)?.name)
            .filter(x => x)
            .join(','),
          ...headers.map(h => x[h.key]?.value || 'N/A'),
        ]),
      ];
      XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), `Judgements`);
      XLSX.writeFile(xlxs, `${this.selectedReview.name} Judgements (${moment().format('DD-MM-YYYY')}).xlsx`);
    },
    // This potentially needs cleaning up / making consistent with standard judgements
    async downloadStatementJudgements() {
      this.downloadingStatementJudgements = true;
      try {
        const response = await this.$api.get(
          `/cohorts/${this.selectedCohort.id}/review-statement-monitoring-by-standard/${this.selectedReview.id}`
        );
        const xlxs = XLSX.utils.book_new();
        const headers = response.data.headers;
        const reviewStandards = ['', '', ''];
        const groups = ['', '', ''];
        let groupId = null;
        let reviewStandardId = null;
        for (const header of headers) {
          const headerGroup = response.data.groups.find(g => g.id === header.group_id);
          const headerReviewStandard = response.data.review_standards.find(
            s => s.id === headerGroup.review_standard_id
          );
          if (header.group_id !== groupId) {
            groups.push(headerGroup.name);
            groupId = header.group_id;
          } else {
            groups.push('');
          }
          if (headerReviewStandard.id !== reviewStandardId) {
            reviewStandards.push(
              headerReviewStandard.code
                ? headerReviewStandard.code + ' - ' + headerReviewStandard.name
                : headerReviewStandard.name
            );
            reviewStandardId = headerReviewStandard.id;
          } else {
            reviewStandards.push('');
          }
        }
        const data = [
          reviewStandards,
          groups,
          ['Email', 'Name', 'Groups', ...headers.map(x => x.text)],
          ...response.data.students.map(x => [
            x.email,
            x.name,
            // Group may have been created by someone else and store isn't upto date
            x.group_ids
              .map(g => this.groups.find(x => x.id === g)?.name)
              .filter(x => x)
              .join(','),
            ...headers.map(h =>
              x.overall_statement_judgements[h.value]
                ? this.statementJudgement(x.overall_statement_judgements[h.value], true)
                : this.statementJudgement(x.standard_statement_judgements[h.value], false)
            ),
          ]),
        ];
        XLSX.utils.book_append_sheet(xlxs, XLSX.utils.aoa_to_sheet(data), `Statement Judgements`);
        XLSX.writeFile(
          xlxs,
          `${this.selectedReview.name} Statement Judgements (${moment().format('DD-MM-YYYY')}).xlsx`
        );
      } catch (e) {
        console.log(e);
        this.errorSnackbar = {
          active: true,
          message: 'Sorry, cannot download your statement judgements',
        };
      }
      this.downloadingStatementJudgements = false;
    },
    statementJudgement(judgementDescriptorId, isGeneralStatement) {
      if (!judgementDescriptorId) return 'Incomplete';
      return this.getStatementJudgement(judgementDescriptorId, isGeneralStatement).descriptor;
    },
    getStatementJudgement(judgementDescriptorId, isGeneralStatement = false) {
      const setId = isGeneralStatement
        ? this.selectedReview.review_overall_statement_institution_judgement_set_id
        : this.selectedReview.review_standard_statement_institution_judgement_set_id;
      const judgementDescriptors = this.judgementSets.find(x => x.id === setId)?.institutionJudgementDescriptors;
      return judgementDescriptors.find(x => x.id === judgementDescriptorId);
    },
  },
};
</script>
