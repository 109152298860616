import { isImpersonating } from '../utils/auth';
import * as Sentry from '@sentry/vue';

// This should only be called from the store
export async function refreshUser(state, commit, dispatch, api) {
  const r = await api.get('/users/current');
  Sentry.setUser({ id: r.data.id, isImpersonating: isImpersonating() });

  const user = {
    id: r.data.id,
    name: r.data.name,
    email: r.data.email,
    isDemo: r.data.is_demo,
    forcePasswordChange: r.data.force_password_change,
    forceMicrosoftSso: r.data.force_microsoft_sso,
    emailVerified: r.data.email_verified,
    emailBounced: r.data.email_bounced,
    optedOutOfEmails: r.data.opted_out_of_emails,
    isAdmin: r.data.is_admin,
    detailsFilledIn: r.data.details_filled_in,
    hasAcceptedPrivacyPolicy: !!r.data.privacy_date,
    notificationsLastSeenAt: r.data.notifications_last_seen_at,
    student: r.data.student,
    staff: r.data.staff,
    singleInstitution: getSingleInstitution(r.data),
  };
  commit('updateUser', user);

  if (isImpersonating()) {
    commit('isImpersonating');
  }

  if (user.student) {
    if (state.userStudent) {
      dispatch('selectUserStudent', { studentId: state.userStudent.id });
    } else if (user.singleInstitution) {
      dispatch('selectUserStudent', { institutionId: user.singleInstitution.id });
    }
  }

  if (user.staff) {
    if (state.userStaff) {
      dispatch('selectUserStaff', { institutionId: state.userStaff.institution.id, force: true });
    } else if (user.singleInstitution) {
      dispatch('selectUserStaff', { institutionId: user.singleInstitution.id });
    }
  }

  if (!user.isAdmin) {
    dispatch('loadNotifications', { force: true });
  }
}

function getSingleInstitution(user) {
  if (user.is_admin) return false;

  const studentInstitutions = user.students
    ? user.students.map(s => s.institution)
    : user.student
    ? [user.student.institution]
    : [];
  const staffInstitutions = user.staff ? user.staff.filter(s => s.staff_roles.length > 0).map(s => s.institution) : [];
  const institutions = studentInstitutions.concat(staffInstitutions);
  if (institutions.length === 1) return institutions[0];
  return null;
}

export function clearUser(commit, dispatch) {
  commit('logout');
  dispatch('studentReviewLogout');
}
