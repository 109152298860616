import moment from 'moment';
import { icons } from '@/utils/icons';
import type { RouteLocationNamedRaw } from 'vue-router';

export function mapNotifications(
  notificationsFromApi: NotificationResponse[],
  lastSeen: string | null
): Notification[] {
  const lastSeenMoment = moment(lastSeen);
  return (
    notificationsFromApi
      .map(n => {
        const seen = lastSeen ? moment(n.notifiedAt).isBefore(lastSeenMoment) : false;

        let notification: Notification;
        switch (n.notificationType) {
          case 'target_comment_created':
          case 'target_comment_updated':
            notification = {
              ...n,
              icon: 'mosaic-target-comment',
              seen,
              subtitleIsQuill: true,
              to: {
                name: 'TargetPage',
                params: {
                  targetId: n.context.targetId.toString(),
                  studentId: n.studentId.toString(),
                },
                query: {
                  commentId: n.context.targetCommentId.toString(),
                },
              },
            };
            break;
          case 'reflection_comment_created':
          case 'reflection_comment_updated':
            notification = {
              ...n,
              icon: 'mosaic-reflection-comment',
              seen,
              subtitleIsQuill: true,
              to: {
                name: 'ReflectionPage',
                params: {
                  reflectionId: n.context.reflectionId.toString(),
                  studentId: n.studentId.toString(),
                },
                query: {
                  commentId: n.context.reflectionCommentId.toString(),
                },
              },
            };
            break;
          case 'staff_announcement_created':
            notification = {
              ...n,
              icon: icons.announcement,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'StaffAnnouncementPage',
                params: {
                  staffId: n.staffId.toString(),
                  id: n.context.staffAnnouncementId.toString(),
                },
              },
            };
            break;
          case 'student_announcement_created':
            notification = {
              ...n,
              icon: icons.announcement,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'StudentAnnouncementPage',
                params: {
                  id: n.context.announcementId.toString(),
                },
              },
            };
            break;
          case 'course_target_created':
            notification = {
              ...n,
              icon: icons.target,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'TargetPage',
                params: {
                  targetId: n.context.targetId.toString(),
                  studentId: n.studentId.toString(),
                },
              },
            };
            break;
          case 'course_reflection_created':
            notification = {
              ...n,
              icon: icons.reflection,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'ReflectionPage',
                params: {
                  reflectionId: n.context.reflectionId.toString(),
                  studentId: n.studentId.toString(),
                },
              },
            };
            break;
          case 'student_assignment_requires_changes':
            notification = {
              ...n,
              icon: 'mdi-clipboard-text',
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'StudentAssignmentPage',
                params: {
                  id: n.context.assignmentId.toString(),
                  versionNumber: n.context.versionNumber.toString(),
                },
              },
            };
            break;
          case 'staff_training_certificate_rejected':
            notification = {
              ...n,
              icon: icons.certificate,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'MyStaffTrainingCertificateEditPage',
                params: {
                  institutionId: n.context.institutionId.toString(),
                  certificateId: n.context.staffTrainingCertificateId.toString(),
                },
              },
            };
            break;
          case 'course_activity_created':
            notification = {
              ...n,
              icon: icons.courseActivity,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'StudentAssignmentLatestVersionPage',
                params: {
                  id: n.context.studentAssignmentId.toString(),
                },
              },
            };
            break;
          case 'review_created':
            notification = {
              ...n,
              icon: icons.reviewPoint,
              seen,
              subtitleIsQuill: false,
              to: {
                name: 'StudentReviewPage',
                params: {
                  id: n.context.studentReviewId.toString(),
                },
              },
            };
            break;
          default:
            return null;
        }
        return notification;
      })
      // If there's a type of notification that this version of the app doesn't understand yet then deliberately filter it out
      .filter((x: Notification | null) => x) as Notification[]
  );
}

export interface Notification {
  id: number;
  icon: string;
  title: string;
  subtitle: string;
  subtitleIsQuill: boolean;
  notifiedAt: string;
  to: RouteLocationNamedRaw;
  read: boolean;
  seen: boolean;
  createdBy?: {
    displayName: string;
  };
}

export type NotificationResponse =
  | TargetCommentNotification
  | ReflectionCommentNotification
  | AssignmentRequiresChangesNotification
  | StaffAnnouncementCreated
  | StudentAnnouncementCreated
  | CourseTargetCreated
  | CourseReflectionCreated
  | StaffTrainingCertificateRejected
  | CourseActivityCreated
  | ReviewCreated;

interface TargetCommentNotification extends NotificationResponseBase {
  notificationType: 'target_comment_created' | 'target_comment_updated';
  context: {
    targetId: number;
    targetCommentId: number;
  };
}

interface ReflectionCommentNotification extends NotificationResponseBase {
  notificationType: 'reflection_comment_created' | 'reflection_comment_updated';
  context: {
    reflectionId: number;
    reflectionCommentId: number;
  };
}

interface AssignmentRequiresChangesNotification extends NotificationResponseBase {
  notificationType: 'student_assignment_requires_changes';
  context: {
    assignmentId: number;
    versionNumber: number;
  };
}
interface StaffAnnouncementCreated extends NotificationResponseBase {
  notificationType: 'staff_announcement_created';
  context: {
    staffAnnouncementId: number;
  };
}

interface StudentAnnouncementCreated extends NotificationResponseBase {
  notificationType: 'student_announcement_created';
  context: {
    announcementId: number;
  };
}

interface CourseTargetCreated extends NotificationResponseBase {
  notificationType: 'course_target_created';
  context: {
    targetId: number;
    adminTargetId: number;
  };
}

interface CourseReflectionCreated extends NotificationResponseBase {
  notificationType: 'course_reflection_created';
  context: {
    reflectionId: number;
    adminReflectionId: number;
  };
}

interface StaffTrainingCertificateRejected extends NotificationResponseBase {
  notificationType: 'staff_training_certificate_rejected';
  context: {
    institutionId: number;
    staffTrainingCertificateId: number;
  };
}

interface CourseActivityCreated extends NotificationResponseBase {
  notificationType: 'course_activity_created';
  context: {
    assignmentId: number;
    studentAssignmentId: number;
  };
}

interface ReviewCreated extends NotificationResponseBase {
  notificationType: 'review_created';
  context: {
    reviewId: number;
    studentReviewId: number;
  };
}

interface NotificationResponseBase {
  id: number;
  title: string;
  subtitle: string;
  notifiedAt: string;
  read: boolean;
  staffId: number;
  studentId: number;
  createdBy?: {
    displayName: string;
  };
}
