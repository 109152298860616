<template>
  <div class="d-flex align-end">
    <v-select
      v-model="selectedRoleIdInternal"
      hide-details
      :items="institutionRoles"
      item-title="name"
      item-value="id"
      label="Filter by Role"
    ></v-select>
    <v-tooltip v-if="!selectedInstitution.config.early_careers && userStaffHasPermission('Admin')" location="bottom">
      <template #activator="{ props }">
        <v-btn class="ml-2" v-bind="props" @click.prevent="navigateToPermissionsPage()">
          <mosaic-icon icon="cog" />
        </v-btn>
      </template>
      <span>View Roles and their Permissions</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';

export default {
  name: 'StaffListRoleFilter',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      selectedRoleIdInternal: { query: 'selectedRoleId', type: 'integer' },
    }),
  ],
  emits: ['update:role'],
  data() {
    return {
      selectedRoleIdInternal: null,
    };
  },
  watch: {
    selectedRoleIdInternal(selectedRoleId) {
      this.$emit(
        'update:role',
        this.institutionRoles.find(x => x.id === selectedRoleId)
      );
    },
  },
  async created() {
    await this.$store.dispatch('loadRoles');
    this.$emit(
      'update:role',
      this.institutionRoles.find(x => x.id === this.selectedRoleIdInternal)
    );
  },
  computed: {
    ...mapState(['selectedInstitution', 'roles']),
    institutionRoles() {
      return [{ name: 'All Roles', id: null }].concat(this.roles).concat({ name: 'No Role Assigned', id: -1 });
    },
  },
  methods: {
    navigateToPermissionsPage() {
      this.$router.push({ name: 'TutorAdminPermissionsPage' });
    },
  },
};
</script>
