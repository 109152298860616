<template>
  <mosaic-save-dialog
    :active="active"
    @update:active="emit('update:active', $event)"
    title="Edit Training Module"
    object-type="Training Module"
    :save="saveEditModule"
    :can-save="canEditModule"
    @save="emit('save')"
  >
    <template #default="{ onKeyupEnter }">
      <mosaic-text-field
        v-model="name"
        name="edit-module-name"
        label="Name"
        prepend-icon="mdi-pencil"
        @keyup.enter="onKeyupEnter"
      />
      <mosaic-time-picker v-model="duration" label="Expected duration" mode="duration" allow-zero-duration />
    </template>
  </mosaic-save-dialog>
</template>

<script setup lang="ts">
import { useApi } from '@/composables/api';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { formatTime, splitTimeIntoHoursAndMinutes } from '@/utils/time';
import { computed, watchEffect } from 'vue';
import { ref } from 'vue';

const props = defineProps<{
  active: boolean;
  module: StaffTrainingModule;
}>();

interface StaffTrainingModule {
  id: number;
  name: string;
  durationHours: number | null;
  durationMinutes: number | null;
}

const emit = defineEmits<{
  'update:active': [active: boolean];
  save: [void];
}>();

const api = useApi();

const {
  actions: { clearAllStaffTraining },
} = useStaffTrainingStore();

const name = ref('');
const duration = ref('');
watchEffect(() => {
  name.value = props.module.name;
  duration.value = formatTime(props.module.durationHours || 0, props.module.durationMinutes || 0);
});

const canEditModule = computed(() => {
  const m = props.module;
  return (
    !!name.value &&
    (name.value !== m.name || duration.value !== formatTime(m.durationHours || 0, m.durationMinutes || 0))
  );
});

async function saveEditModule() {
  const { hours, minutes } = splitTimeIntoHoursAndMinutes(duration.value);
  await api.put(`/staff-training/modules/${props.module.id}/details`, {
    name: name.value,
    durationHours: hours,
    durationMinutes: minutes,
  });
  clearAllStaffTraining();
  emit('save');
  emit('update:active', false);
}
</script>
