import { parseRouteId } from '@/composables/vue-router';
import { formatDate } from '@/mixins/global-mixins';
import type { Breadcrumb } from '@/store/map-store';
import { useStaffStore } from '@/stores/staff';
import type { StaffTraining, StaffTrainingCompetencyTheme } from '@/stores/staff-training';
import { pluraliseItemsName } from '@/utils/text';
import { isString } from 'lodash';
import { computed } from 'vue';
import { useRoute, type RouteLocationNormalizedLoaded } from 'vue-router';
import type { ContentLayout } from '@/utils/content-layout';
import { mapContentLayoutToCompletionComments, mapContentLayoutToCompletionTasks } from '@/utils/content-layout';
import { formatDuration } from '@/utils/time';

export function myStaffTrainingBreadcrumbs(
  tab: 'training-requirements' | 'training-modules' | 'manual-training-records' | 'certificates' | 'events'
): Breadcrumb[] {
  return [
    {
      text: 'My Training',
      to: {
        name: 'TutorStaffTrainingPage',
        query: { tab },
      },
    },
  ];
}

export function selectedStaffTrainingBreadcrumbs(
  tab: 'training-requirements' | 'training-modules' | 'manual-training-records' | 'certificates' | 'events',
  selectedInstitutionId: number,
  staffId: number,
  route: RouteLocationNormalizedLoaded,
  staffName?: string
): Breadcrumb[] {
  return [
    {
      text: 'Instructors',
      to: {
        name: 'TutorStaffListPage',
        params: { institutionId: selectedInstitutionId },
      },
    },
    {
      text: route.params.staffName && isString(route.params.staffName) ? route.params.staffName : staffName || '',
      to: {
        name: 'TutorStaffPage',
        params: { id: staffId, institutionId: selectedInstitutionId },
        query: { tab: route.query.fromTab || tab },
      },
    },
  ];
}

export function staffTrainingRecordSubtitle(
  r:
    | StaffTraining['staffTrainingRecords'][number]
    | StaffTrainingCompetencyTheme['staffTrainingCompetencies'][number]['staffTrainingRecords'][number]
) {
  const type = r.recordType == 'training_session' ? 'Training Session' : 'Prior Experience Audit';
  const datePrefix = r.recordType == 'training_session' ? 'Completed' : 'Verified';

  let duration = '';
  if (r.recordType == 'training_session') {
    duration = ` - Duration ${formatDuration(r.durationHours, r.durationMinutes)}`;
  }

  const count = r.staffTrainingRecordCompetencies.length;
  const competencies = `Meets ${count === 0 ? 'no' : count} ${pluraliseItemsName(count, 'Competency')}`;

  return `${type} - ${datePrefix} on ${formatDate(r.date)}${duration} - ${competencies}`;
}

export function useStaffTraining() {
  const route = useRoute();
  const isMyStaffTraining = computed(
    () => route.name?.toString().startsWith('My') || route.name == 'TutorStaffTrainingPage'
  );

  const {
    selectedStaff,
    userStaff,
    actions: { loadStaff: storeLoadStaff },
  } = useStaffStore();

  const routeStaffId = parseRouteId('staffId');
  const staffId = computed(() => (isMyStaffTraining.value ? userStaff.value.id : routeStaffId.value));
  const staff = computed(() => (isMyStaffTraining.value ? userStaff.value : selectedStaff.value));

  async function loadStaff() {
    if (!isMyStaffTraining.value) {
      await storeLoadStaff(staffId.value);
    }
  }

  function staffTrainingCompetencyTo(themeId: number) {
    return isMyStaffTraining.value
      ? { name: 'MyStaffTrainingCompetencyThemePage', params: { themeId: themeId.toString() } }
      : {
          name: 'TutorStaffTrainingCompetencyThemePage',
          params: { staffId: staffId.value || '', themeId: themeId.toString() },
        };
  }

  return { isMyStaffTraining, staff, staffId, loadStaff, staffTrainingCompetencyTo };
}

export function staffTrainingCertificateStatusChip(
  status: 'not_created' | 'awaiting_approval' | 'approved' | 'rejected'
) {
  switch (status) {
    case 'not_created':
      return {
        text: 'Not Uploaded',
        color: 'accent',
        tooltip: 'This Certificate has not been uploaded',
      };
    case 'awaiting_approval':
      return {
        text: 'Awaiting Approval',
        color: 'accent',
        tooltip: 'This Certificate is awaiting approval by your course leads',
      };
    case 'approved':
      return {
        text: 'Approved',
        color: 'secondary',
        tooltip: 'This Certificate has been approved by your course leads',
      };
    case 'rejected':
      return {
        text: 'Rejected',
        color: 'red',
        tooltip: 'This Certificate has been rejeceted by your course leads',
      };
  }
}

export const staffTrainingTabHeaders = [
  {
    key: 'training-requirements',
    text: 'Training Requirements',
  },
  {
    key: 'training-modules',
    text: 'Training Modules',
  },
  {
    key: 'manual-training-records',
    text: 'Manual Training Records',
  },
  {
    key: 'certificates',
    text: 'Certificates',
  },
  {
    key: 'events',
    text: 'Attended Events',
  },
];

// #region Staff Training Module Completion
export interface StaffTrainingModuleCompletionRequest {
  comments: {
    comment: string | null;
    staffTrainingModuleCommentId: string;
  }[];
  tasks: {
    completed: boolean;
    staffTrainingModuleTaskId: string;
  }[];
  files: { fileId: string; title: string; staffTrainingModuleFileUploadId: string }[];
  completed: boolean;
}

export interface StaffTrainingModuleCompletionResponse {
  staffTrainingModule: {
    id: number;
    name: string;
    durationHours: number | null;
    durationMinutes: number | null;
    staffTrainingModuleSections: {
      id: number;
      name: string;
      contentLayout: ContentLayout;
      order: number;
    }[];
  };
  staffTrainingModuleCompletion: {
    id: number;
    staffId: number;
    staffTrainingModuleSectionCompletions: {
      id?: number;
      staffTrainingModuleSectionId: number;
      completed: boolean;
      staffTrainingModuleCompletionComments: StaffTrainingModuleCompletionComment[];
      staffTrainingModuleCompletionTasks: StaffTrainingModuleCompletionTask[];
      staffTrainingModuleCompletionFiles: { fileId: string; title: string; staffTrainingModuleFileUploadId: string }[];
    }[];
  };
  staff: { id: number; displayName: string };
}

export interface StaffTrainingModuleCompletionComment {
  id?: number;
  comment: string;
  staffTrainingModuleCommentId: string;
}

export interface StaffTrainingModuleCompletionTask {
  id?: number;
  completed: boolean;
  staffTrainingModuleTaskId: string;
}

export function mapStaffTrainingModuleToSectionCompletions(
  staffTrainingModule: StaffTrainingModuleCompletionResponse['staffTrainingModule']
): StaffTrainingModuleCompletionResponse['staffTrainingModuleCompletion']['staffTrainingModuleSectionCompletions'] {
  return staffTrainingModule.staffTrainingModuleSections.map(s => {
    return {
      staffTrainingModuleSectionId: s.id,
      completed: false,
      staffTrainingModuleCompletionComments: mapContentLayoutToCompletionComments(s.contentLayout, id => ({
        staffTrainingModuleCommentId: id,
      })),
      staffTrainingModuleCompletionTasks: mapContentLayoutToCompletionTasks(s.contentLayout, id => ({
        staffTrainingModuleTaskId: id,
      })),
      staffTrainingModuleCompletionFiles: [],
    };
  });
}
// #endregion

export function staffTrainingModuleExpectedDuration(
  module: { durationHours: number | null; durationMinutes: number | null } | null | undefined,
  noDurationText = `No expected duration`
) {
  if (!module) return noDurationText;
  if (module.durationHours === null || module.durationMinutes === null) return noDurationText;
  if (module.durationHours === 0 && module.durationMinutes === 0) return noDurationText;
  return `Expected duration ${formatDuration(module.durationHours, module.durationMinutes)}`;
}

export interface StaffTrainingThemeRequirementsResponse {
  staffTrainingCompetencyTheme: {
    id: number;
    name: string;
    code: string;
    staffTrainingFramework: {
      id: number;
      name: string;
    };
    staffTrainingCompetencies: {
      id: number;
      name: string;
      code: string;
      description: string;
      requiredModules: {
        id: number;
        name: string;
        status: 'published' | 'draft';
      }[];
    }[];
  };
  staff: {
    id: number;
    name: string;
  };
}
