<template>
  <div>
    <mosaic-loading-and-error-cards
      v-model:trigger-background-load="triggerBackgroundLoad"
      object-type="Competency"
      :load="load"
      loading-type="card"
    >
      <mosaic-save-card
        v-if="competency"
        :can-save="dirty"
        :save="save"
        object-type="Competency"
        :return-to="{
          name: 'InstitutionStaffTrainingCompetencyThemePage',
          params: { id: $route.params.themeId },
        }"
      >
        <mosaic-card-title>{{ competency.code }} - {{ competency.name }} </mosaic-card-title>
        <div>
          Use this page to explain what Instructors are required to do to demonstrate they meet this Competency.
        </div>
        <mosaic-card-subheading class="pt-6">Competency Description</mosaic-card-subheading>
        <mosaic-quill v-model:contents="description" class="pt-2" :read-only="false"></mosaic-quill>

        <div>
          <mosaic-card-subheading class="pt-6">Accepted Certificates</mosaic-card-subheading>
          <div>Instructors may upload one of these Certificates to meet this Competency.</div>
          <mosaic-list :items="certificates" empty-text="No Certificates meet this Competency.">
            <template #item="{ item: c }">
              <mosaic-list-item
                :key="c.id"
                :icon="icons.certificate"
                :to="certificateClickTo(c)"
                :title="c.certificateType.name"
              >
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </div>

        <div>
          <mosaic-card-subheading class="pt-6">Events</mosaic-card-subheading>
          <div>Instructors may attend one of these Events to meet this Competency.</div>
          <mosaic-list :items="events" empty-text="No Events meet this Competency.">
            <template #item="{ item: e }">
              <mosaic-list-item
                :key="e.id"
                :icon="icons.instructorTrainingEvent"
                :to="{ name: 'InstitutionStaffTrainingEventEditPage', params: { id: e.id } }"
                :title="e.name"
                :subtitle="`${formatDateTime(e.startsAt)} - Duration ${formatDuration(
                  e.durationHours,
                  e.durationMinutes
                )}`"
                :chip="
                  dateTimeIsInThePast(e.startsAt)
                    ? { text: 'Past', color: 'primary' }
                    : { text: 'Upcoming', color: 'accent' }
                "
              >
              </mosaic-list-item>
            </template>
          </mosaic-list>
        </div>

        <div>
          <mosaic-card-subheading class="pt-6">Module Requirements</mosaic-card-subheading>
          <div>Instructors must complete all Modules listed below to meet this Competency.</div>
          <mosaic-list :items="moduleRequirements">
            <template #item="{ item: m }">
              <mosaic-list-item
                :key="m.id"
                :icon="icons.instructorTrainingModule"
                :to="moduleClickTo(m)"
                :title="m.name"
              >
                <template #information>
                  <mosaic-published-draft-chip :published="m.status === 'published'" object-type="Training Module" />
                </template>
                <template #actions>
                  <mosaic-icon-btn
                    icon="mdi-delete"
                    tooltip="Remove Module Requirement"
                    @click.prevent="deleteModuleRequirement(m)"
                  >
                  </mosaic-icon-btn>
                </template>
              </mosaic-list-item>
            </template>
          </mosaic-list>

          <mosaic-btn
            :class="moduleRequirements.length ? '' : 'mt-4'"
            ripple
            @click.prevent="() => (addModulesDialog.active = true)"
          >
            <div class="d-flex align-center">
              <v-icon>mdi-plus</v-icon>
              <span>Module Requirement</span>
            </div>
          </mosaic-btn>
        </div>
      </mosaic-save-card>
    </mosaic-loading-and-error-cards>

    <mosaic-dialog v-model:active="addModulesDialog.active" title="Add Module Requirement" :width="950">
      <div>Please note that only published Modules can be added as Competency Requirements</div>
      <mosaic-autocomplete
        v-model="addModulesDialog.selectedModules"
        name="module-requirements"
        :items="filteredStaffTrainingModules"
        multiple
        label="Instructor Training Modules"
        no-data-text="No published Modules found"
        :prepend-icon="icons.instructorTrainingModule"
        item-title="name"
        item-value="id"
        return-object
      />

      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="primary"
          :disabled="!addModulesDialog.selectedModules.length"
          @click.prevent="addModules()"
        >
          Confirm
        </v-btn>
      </template>
    </mosaic-dialog>

    <unsaved-changes-dialog
      v-model:unsaved-changes-dialog="dialog"
      object-type="Competency"
      :save="save"
    ></unsaved-changes-dialog>
  </div>
</template>

<script setup lang="ts">
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { computed, ref } from 'vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import { mapActions, mapState } from '../../../store/map-store';
import type { StaffTrainingModule } from '@/store/map-store';
import { useUnsavedChanges } from '@/composables/unsaved-changes';
import UnsavedChangesDialog from '@/components/UnsavedChangesDialog.vue';
import _ from 'lodash';
import type { RouteLocationNamedRaw } from 'vue-router';
import { useApi } from '@/composables/api';
import { icons } from '@/utils/icons';
import { parseRouteId } from '@/composables/vue-router';
import { useStaffTrainingStore } from '@/stores/staff-training';
import { dateTimeIsInThePast, formatDateTime } from '@/utils/date';
import { formatDuration } from '@/utils/time';

const api = useApi();

const competencyId = parseRouteId('id');

type Competency = {
  id: number;
  order: number;
  name: string;
  code: string;
  description: string;
  staffTrainingCompetencyTheme: {
    id: number;
    name: string;
    staffTrainingFramework: {
      id: number;
      name: string;
    };
  };
  staffTrainingCompetencyRequirements: {
    staffTrainingModule: StaffTrainingModule;
  }[];
  staffTrainingAcceptedCertificates: {
    id: number;
    certificateType: { id: number; name: string };
  }[];
  staffTrainingEvents: {
    id: number;
    name: string;
    startsAt: string;
    durationHours: number;
    durationMinutes: number;
    status: 'draft' | 'published';
  }[];
};

const competency = ref<Competency | null>(null);
const description = ref('');
const moduleRequirements = ref<StaffTrainingModule[]>([]);

const competencyRequirements = computed(() => competency.value?.staffTrainingCompetencyRequirements || []);
const certificates = computed(() => competency.value?.staffTrainingAcceptedCertificates || []);
const events = computed(() => competency.value?.staffTrainingEvents.filter(e => e.status == 'published') || []);

async function load() {
  const r = await api.get<Competency>(
    `/staff-training/frameworks/competency-themes/competencies/${competencyId.value}`
  );
  competency.value = r.data;
  description.value = r.data.description;
  moduleRequirements.value = r.data.staffTrainingCompetencyRequirements.map(r => r.staffTrainingModule);
}

setBreadcrumbs(
  computed(() => [
    { text: 'Instructors', to: { name: 'TutorStaffListPage' } },
    { text: 'Training', to: { name: 'InstitutionStaffTrainingPage' } },
    {
      text: `Competency Frameworks`,
      to: {
        name: 'InstitutionStaffTrainingPage',
        query: { tab: 'frameworks' },
      },
    },
    ...(competency.value
      ? [
          {
            text: competency.value?.staffTrainingCompetencyTheme.staffTrainingFramework.name || '',
            to: {
              name: 'InstitutionStaffTrainingFrameworkPage',
              params: { id: competency.value.staffTrainingCompetencyTheme.staffTrainingFramework.id.toString() },
            },
          },
          {
            text: competency.value?.staffTrainingCompetencyTheme.name || '',
            to: {
              name: 'InstitutionStaffTrainingCompetencyThemePage',
              params: { id: competency.value.staffTrainingCompetencyTheme.id.toString() },
            },
          },
          {
            text: competency.value.name,
          },
        ]
      : []),
  ])
);

const moduleClickTo: (m: StaffTrainingModule) => RouteLocationNamedRaw = m => ({
  name: 'InstitutionStaffTrainingModulePage',
  params: { id: m.id.toString() },
});

// Add Module requirements
const { loadStaffTrainingModules } = mapActions();
const {
  actions: { clearAllStaffTraining },
} = useStaffTrainingStore();
loadStaffTrainingModules();

const { staffTrainingModules } = mapState();
const filteredStaffTrainingModules = computed(() =>
  staffTrainingModules.value.filter(m => !moduleRequirements.value.some(r => r.id === m.id) && m.status === 'published')
);

const addModulesDialog = ref({
  active: false,
  selectedModules: [] as StaffTrainingModule[],
});

async function addModules() {
  // Orders and ids will be properly set backend
  moduleRequirements.value.push(...addModulesDialog.value.selectedModules);
  addModulesDialog.value.active = false;
  addModulesDialog.value.selectedModules = [];
}

// Delete Module requirements

function deleteModuleRequirement(m: StaffTrainingModule) {
  moduleRequirements.value = moduleRequirements.value.filter(r => r.id !== m.id);
}

// Save updates
const moduleRequirementsChanged = computed(() => {
  const currentIds = moduleRequirements.value.map(m => m.id);
  const originalIds = competencyRequirements.value.map(r => r.staffTrainingModule.id);
  return !_.isEqual(currentIds, originalIds);
});

const dirty = computed(() => description.value !== competency.value?.description || moduleRequirementsChanged.value);
const { dialog } = useUnsavedChanges(dirty);
const triggerBackgroundLoad = ref(false);

async function save() {
  const r = await api.put<unknown, Competency>(
    `/staff-training/frameworks/competency-themes/competencies/${competencyId.value}`,
    {
      description: description.value,
      module_ids: moduleRequirements.value.map(m => m.id),
    }
  );
  competency.value = r.data;
  triggerBackgroundLoad.value = true;
  clearAllStaffTraining();
}

// #region certificates
function certificateClickTo(c: Competency['staffTrainingAcceptedCertificates'][number]) {
  return {
    name: 'InstitutionStaffTrainingAcceptedCertificateEditPage',
    params: { id: c.id.toString() },
  };
}
// #endregion
</script>
