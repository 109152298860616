<template>
  <article>
    <header>
      <h1>Privacy Policy</h1>
    </header>
    <br />
    <h2>Who we are</h2>
    <p>
      At Penrose Education, our aim is to help you create, manage and share your portfolio. As you collect evidence you
      need to know that this data is safe and being processed securely. Your privacy and security are our number one
      priority. See our Privacy Policy below to learn more about how we collect, use and share your data. If you have
      any questions about this Privacy Policy, please contact paul@penrose.education
    </p>
    <h2>Our Privacy Policy</h2>
    <span>Last updated: (April 2024)</span>
    <p>
      Penrose Education Ltd ("us", "we", or "our") operates https://mosaic.penrose.education (the "Site"). This page
      informs you of our policies regarding the collection, use and disclosure of Personal Information we receive from
      users of the Site.
    </p>
    <h2>DEFINITIONS AND INTERPRETATIONS</h2>
    <span>In this Privacy Policy, the following definitions are used:</span>
    <br />

    <div class="table">
      <div class="table-row">
        <div class="table-row-item">Data</div>
        <div class="table-row-item">
          Collectively all information that you submit to Penrose Education Ltd via mosaic.penrose.education. This
          definition incorporates, where applicable, the definitions provided in the Data Protection Laws;
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Data Protection Laws</div>
        <div class="table-row-item">
          Any applicable law relating to the processing of personal Data, including but not limited to the Directive
          96/46/EC (Data Protection Directive) or the GDPR, and ay national implementing laws, regulations and secondary
          legislation, for as long as the GDPR is effective in the UK;
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-item">GDPR</div>
        <div class="table-row-item">The General Data Protection Regulation (EU) 2016/679;</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Penrose Education Ltd (“us”, “we” or “our”)</div>
        <div class="table-row-item">
          Penrose Education Ltd, a company incorporated in England and Wales with registered number 12220704 whose
          registered office is at 2 Perrygate Avenue, Manchester, M20 1JR.
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-item">User (“you” or “your”)</div>
        <div class="table-row-item">
          Any third party that accesses the Website and is not either (i) employed by Penrose Education Ltd and acting
          in the course of their employment or (ii) engaged as a consultant or otherwise providing services to Penrose
          Education Ltd and accessing the Website in connection with the provision of such services; and
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Website</div>
        <div class="table-row-item">
          The website, mosaic.penrose.education, and any sub-domains of this site unless expressly excluded by their own
          terms and conditions.
        </div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Mobile App</div>
        <div class="table-row-item">
          The Mobile app Mosaic Tile available in the apple app store and google play store.
        </div>
      </div>
    </div>
    <br />
    <p>In this Privacy Policy, unless the context requires a different interpretation:</p>
    <ol>
      <li>The singular includes the plural and vice versa;</li>
      <li>
        References to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices
        of this Privacy Policy;
      </li>
      <li>A reference to a person includes firms, companies, government entities, trusts and partnerships;</li>
      <li>“Including” is understood to mean “including without limitation”;</li>
      <li>Reference to any statutory provision includes any modification or amendment of it;</li>
      <li>The headings and sub-headings do not form part of this Privacy Policy</li>
    </ol>
    <h2>SCOPE OF THIS PRIVACY POLICY</h2>
    <ol>
      <li>
        This Privacy Policy applies only to the actions of Penrose Education Ltd and Users with respect to the Website.
        It does not extend to any websites that can be accessed from this Website including, but not limited to, any
        links we may provide to social media websites.
      </li>
      <li>
        For purposes of the applicable Data Protection Laws, Penrose Education Ltd is the “data controller”. This means
        that Penrose Education Ltd determines for which, and the manner in which, your Data is processed.
        <ol type="a">
          <li>
            This policy only relates to instances where we are the Data Controller and we directly collect the
            information from individuals.
          </li>
          <li>
            We do not process any personal data that we receive indirectly from third-parties, other than where we are
            acting on the instructions of our Clients. These may be your employer, an independent training provider, a
            college, university or any other organisation who may have engaged with us for the purposes of using our
            products to provide you with a service. In these circumstances our Clients are the Data Controller and we
            are the Data Processor. If you have any questions about how our Clients process your personal information,
            then you must contact that organisation directly.
          </li>
        </ol>
      </li>
    </ol>
    <br />
    <h2>DATA COLLECTED</h2>
    <ol>
      <li>
        We may collect the following Data, which includes personal Data, from you:
        <span>All customers</span>
        <ol type="a">
          <li>Name, as given by you or your training provider</li>
          <li>Email address, as given by you or your training provider</li>
        </ol>
        <span>Appropriate Body customers only</span>
        <ol type="a">
          <li>Date of Birth, as given by you, your school or Appropriate Body</li>
          <li>TRN, as given by you, your school or Appropriate Bodyr</li>
          <li>
            ITT Training Provider, as given by by you, your school your Appropriate Body, or taken from the TRA portal
          </li>
          <li>QTS status, as taken from the TRA portal</li>
          <li>QTS awarded date, as taken from the TRA portal</li>
          <li>ECF Induction status, as taken from the TRA portal</li>
        </ol>
      </li>
    </ol>
    <p>In each case, in accordance with this Privacy Policy</p>
    <h2>HOW WE COLLECT DATA</h2>
    <ol>
      <li>
        We collect Data in the following ways:
        <ol type="a">
          <li>Data is given to us by you; and</li>
          <li>Data is collected automatically.</li>
        </ol>
      </li>
    </ol>
    <h2>DATA THAT IS GIVEN TO US BY YOU</h2>
    <ol>
      <li>
        Penrose Education Ltd will collect your Data in a number of ways, for example:
        <ol type="a">
          <li>When you contact us through the Website, by telephone, post, email or through any other means;</li>
          <li>
            During account setup, a name and email address will be needed to set up your account. This is given via the
            website either by you, your training provider or a Penrose Education employee with the express permission of
            you or your training provider
          </li>
        </ol>
      </li>
    </ol>
    <p>In each case, in accordance with this privacy policy.</p>
    <h2>DATA THAT IS COLLECTED AUTOMATICALLY</h2>
    <ol>
      <li>
        To the extent that you access the Website, we will collect your Data automatically, for example:
        <ol type="a">
          <li>
            We automatically collect some information about your visit to the Website. This information helps us to make
            improvements to Website content and navigation, and includes your IP address (anonymised), the date, time
            and frequency with which you access the Website and the way you use and interact with its content.
          </li>
          <li>
            In the case of Appropriate Body customers only, we automatically check TRN and DoB against the TRA to
            collect certain data as outlined above.
          </li>
        </ol>
      </li>
    </ol>
    <br />
    <h2>HOW WE STORE DATA</h2>
    <ol>
      <li>
        We store data in the following ways:
        <ol type="a">
          <li>
            Your data used for the running of the application is stored on Heroku provisioned databases. Heroku is a UK
            GDPR compliant Platform as a Service (PaaS) provider of database and computing resources. They are GDPR
            compliant and use AWS servers based in Ireland for their Europe region, which we utilise
          </li>
          <li>Logging data is stored within the EU with Sumologic, a GDPR-compliant provider of logging services.</li>
        </ol>
      </li>
    </ol>
    <br />
    <h2>OUR USE OF DATA</h2>
    <ol>
      <li>
        Any or all of the above Data may be required by us from time to time in order to provide you with the best
        possible service and experience when using our Website. Specifically, Data may be used by us for the following
        reasons:
        <ol type="a">
          <li>Internal record keeping;</li>
          <li>Improvement of our products/services;</li>
          <li>
            Contact for market research purposes which may be done using email, telephone, fax or mail. Such information
            may be used to customise or update the Website.
          </li>
        </ol>
      </li>
    </ol>
    <p>In each case, in accordance with this privacy policy.</p>
    <ol>
      <li>
        We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If
        you are not satisfied with this, you have the right to object in certain circumstances (see the section headed
        “Your rights” below).
      </li>
    </ol>
    <br />
    <h2>WHO WE SHARE DATA WITH</h2>
    <ol>
      <li>
        We may share your Data with the following groups of people for the following reasons:
        <ol type="a">
          <li>Your training provider - in accordance with the agreement set out with them as the data controller</li>
          <li>
            For Appropriate Body customers only - with the DfE for the purpose of DfE returns, in accordance with the
            agreement set out with your training provider as the data controller
          </li>
        </ol>
      </li>
    </ol>
    <p>In each case, in accordance with this Privacy Policy.</p>
    <h2>KEEPING DATA SECURE</h2>
    <ol>
      <li>
        We will use technical and organisational measures to safeguard your Data, for example:
        <ol type="a">
          <li>Access to your account is controlled by a password and an email address that is unique to you.</li>
          <li>We store your Data on secure servers.</li>
        </ol>
      </li>
      <li>
        Technical and organisational measures include measures to deal with any suspected data breach. If you suspect
        any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this
        email address: paul@penrose.education
      </li>
      <li>
        If you want detailed information from Get Safe Online on how to protect your information and your computes and
        devices against fraud, identity theft, viruses and many other online problems, please visit
        www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.
      </li>
    </ol>
    <br />
    <h2>DATA RETENTION</h2>
    <ol>
      <li>
        Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for
        the period necessary to fulfil the purposes outlined in this Privacy Policy or until you request that the Data
        be deleted.
      </li>
      <li>
        On termination of contract, we will:
        <ul>
          <li>
            delete your data within 1 year of the termination date unless otherwise agreed with you as the data
            controller.
          </li>
          <li>transfer data to you in a portable format at your request within a reasonable time frame.</li>
        </ul>
      </li>
      <li>
        Even if we delete your Data, it may persist or backup or archival media for legal, tax or regulatory purposes.
      </li>
      <li>
        As outlined in the Sub-processors section below, we utilise a few additional services to run the Mosaic
        application. See the table below for retention policies
      </li>
    </ol>
    <br />
    <h2>YOUR RIGHTS</h2>
    <ol>
      <li>
        You have the following rights in relation to your Data:
        <ol type="a">
          <li>
            Right to access – the right to request (i) copies of the information we hold about you at any time, or (ii)
            that we modify, update or delete such information. If we provide you with access to the information we hold
            about you, we will not charge you for this, unless your request is “manifestly unfounded or excessive.”
            Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell
            you the reasons why.
          </li>
          <li>Right to correct – the right to have your Data rectified if it is inaccurate or incomplete.</li>
          <li>Right to erase – the right to request that we delete or remove your Data from our systems.</li>
          <li>
            Right to restrict our use of your Data – the right to “block” us from using your Data or limit the way in
            which we can use it.
          </li>
          <li>Rights to data portability – the right to request that we move, copy or transfer your Data.</li>
          <li>
            Right to object – the right to object to our use of your Data including where we use it for our legitimate
            interests.
          </li>
        </ol>
      </li>
      <li>
        To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your
        Data (where consent is our legal basis for processing your Data), please contact us via this email address:
        paul@penrose.education.
      </li>
      <li>
        You have the right to complain to the privacy regulator in the country in which you reside, where you work, or
        anywhere where you believe we might have broken data protection rules.
        <ol type="a">
          <li>
            In the UK, the privacy regulator is the Information Commissioner's Office (the "ICO"). The ICO can be
            contacted at:
            <ol type="i">
              <li>
                Address: Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire, SK9 5AF
              </li>
              <li>Telephone: 0303 123 1113 (local rate) or 01625 545 745</li>
              <li>Email: https://ico.org.uk/global/cont...</li>
            </ol>
          </li>
        </ol>
      </li>
      <li>
        It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data
        changes during the period for which we hold it.
      </li>
    </ol>
    <br />
    <h2>LINKS TO OTHER WEBSITES</h2>
    <ol>
      <li>
        This Website may, from time to time, provide links to other websites. We have no control over such websites and
        are not responsible for the content of these websites. This Privacy Policy does not extend to your use of such
        websites. You are advised to read the Privacy Policy or Statement of other websites prior to using them.
      </li>
    </ol>
    <br />
    <h2>CHANGES</h2>
    <ol>
      <li>
        Penrose Education Ltd may, from time to time, expand or reduce our business and this may involve the sale and/or
        transfer of control of all or part of Penrose Education Ltd. Data provided by Users will, where it is relevant
        to any part of our business, be transferred along with that part and the new owner or newly controlling party
        will, under the terms of this Privacy Policy, be permitted to use the Data for the purposes for which it was
        originally supplied to us.
      </li>
      <li>We may also disclose Data to a prospective purchaser of our business or any part of it.</li>
      <li>In the above instances, we will take steps with the aim of ensuring your privacy is protected.</li>
    </ol>
    <br />
    <h2>COOKIES</h2>
    <ol>
      <li>
        This Website may place and access certain Cookies on your computer. Penrose Education Ltd uses Cookies to
        improve your experience of using the Website. Penrose Education Ltd has carefully chosen these Cookies and has
        taken steps to ensure that your privacy is protected and respected at all times.
      </li>
      <li>All Cookies used by this Website are used in accordance with current UK and EU Cookie Law.</li>
      <li>
        Before accessing the part of the website that uses cookies, you will be presented with this privacy policy and
        asked to consent to the use of these cookies. By giving your consent to the placing of Cookies, you are enabling
        Penrose Education Ltd to provide a better experience and service to you. You may, if you wish, deny consent to
        the placing of Cookies; however certain features of the Website may not function fully or as intended.
      </li>
      <li>This Website may place the following Cookies:</li>

      <br />
      <div class="table">
        <div class="table-row">
          <div class="table-row-item">Type of Cookie</div>
          <div class="table-row-item">Purpose</div>
        </div>
        <div class="table-row">
          <div class="table-row-item">Analytical/performance cookie</div>
          <div class="table-row-item">
            Allow us to recognise and count the number of visitors and to see how visitors move around our website when
            they are using it. This helps us to improve the way our website works, for example, by ensuring that users
            are finding what they are looking for easily.
          </div>
        </div>
        <div class="table-row">
          <div class="table-row-item">Functionality cookie</div>
          <div class="table-row-item">
            This cookie is user to recognise you when you return to our website. This enables us to personalise content
            for you, greet you by name and remember your preferences (for example, your choice of language or region).
          </div>
        </div>
      </div>
      <br />

      <li>You can find a list of Cookies that we use in the Cookies Schedule.</li>
      <li>
        You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept
        Cookies but this can be changed. For further details, please consult the help menu in your internet browser.
      </li>
      <li>
        You can choose to delete Cookies at any time; however you may lose any information that enables you to access
        the Website more quickly and efficiently including, but not limited to, personalisation settings.
      </li>
      <li>
        It is recommended that you ensure that your internet browser is up-to-date and that you consult the help and
        guidance provided by the developer of your internet browser if you are unsure about adjusting your privacy
        settings.
      </li>
      <li>
        For more information generally on cookies, including how to disable them, please refer to aboutcookies.org. You
        will also find details on how to delete cookies from your computer.
      </li>
    </ol>
    <br />
    <h2>GENERAL</h2>
    <ol>
      <li>
        You may not transfer any of your rights under this privacy policy to any other person. We may transfer our
        rights under this privacy policy where we reasonably believe your rights will not be affected.
      </li>
      <li>
        If any court or competent authority finds that any provision of this Privacy Policy (or part of any provision)
        is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed
        to be deleted, and the validity and enforceability of the other provisions of this Privacy Policy will not be
        affected.
      </li>
      <li>
        Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a
        waiver of that, or any other, right or remedy.
      </li>
      <li>
        This Agreement will be governed by and interpreted according to the law of England and Wales. All disputes
        arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.
      </li>
    </ol>
    <br />
    <h2>CHANGES TO THIS PRIVACY POLICY</h2>
    <ol>
      <li>
        Penrose Education Ltd reserves the right to change this Privacy Policy as we may deem necessary from time to
        time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to
        have accepted the terms of the Privacy Policy on your first use of the Website following the alterations.
      </li>
    </ol>
    <p>If you have any questions about this Privacy Policy, please contact paul@penrose.education</p>
    <br />
    <h2>COOKIES</h2>
    <p>
      Below is a list of the cookies that we use. We have tried to ensure this is complete and up to date, but if you
      think that we have missed a cookie or there is any discrepancy, please let us know.
    </p>
    <p>
      <b>Analytical/performance</b>
    </p>
    <p>We use the following analytical/performance cookies:</p>
    <div class="table">
      <div class="table-row">
        <div class="table-row-item">Description of Cookie</div>
        <div class="table-row-item">Purpose</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Analytical/performance cookie</div>
        <div class="table-row-item">
          Allow us to recognise and count the number of visitors and to see how visitors move around our website when
          they are using it. This helps us to improve the way our website works, for example, by ensuring that users are
          finding what they are looking for easily.
        </div>
      </div>
    </div>
    <b>Functionality</b>
    <p>We use the following functionality cookies:</p>
    <div class="table">
      <div class="table-row">
        <div class="table-row-item">Description of Cookie</div>
        <div class="table-row-item">Purpose</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Functionality cookie</div>
        <div class="table-row-item">
          This cookie is user to recognise you when you return to our website. This enables us to personalise content
          for you, greet you by name and remember your preferences (for example, your choice of language or region).
        </div>
      </div>
    </div>
    <br />
    <h2>Sub-processors</h2>
    <p>
      Mosaic uses certain sub-processors purely for the purposes of running, maintaining and monitoring the Mosaic
      application. Below is a list of sub-processors we use
    </p>
    <div class="table">
      <div class="table-row">
        <div class="table-row-item">Sub-processor</div>
        <div class="table-row-item">Purpose</div>
        <div class="table-row-item">Location of processing/storage</div>
        <div class="table-row-item">Retention policy</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Heroku Services</div>
        <div class="table-row-item">
          Provision cloud-based servers and databases for the running of the Mosaic application
        </div>
        <div class="table-row-item">Ireland (Heroku Europe Region)</div>
        <div class="table-row-item">Managed by Penrose Education in line with the terms set out above</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Sumologic</div>
        <div class="table-row-item">
          Application logging to support debugging of live issues and monitoring of performance
        </div>
        <div class="table-row-item">EU</div>
        <div class="table-row-item">30 days</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Postmark</div>
        <div class="table-row-item">
          Email service for the Mosaic application - handling support emails, and other automated emails from the
          platform as configured by you.
        </div>
        <div class="table-row-item">US*</div>
        <div class="table-row-item">45 days</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Gitlab</div>
        <div class="table-row-item">
          Code storage for the Mosaic repository. Holds some emails for the purposes of data migration tasks
        </div>
        <div class="table-row-item">US*</div>
        <div class="table-row-item">Determined by Penrose Education in accordance with retention policy</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Github</div>
        <div class="table-row-item">
          Code storage and infrastructure management for the Mosaic repository. Holds some emails for the purposes of
          data migration tasks
        </div>
        <div class="table-row-item">US*</div>
        <div class="table-row-item">Determined by Penrose Education in accordance with retention policy</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">Sentry</div>
        <div class="table-row-item">
          Application Logging to support debugging of live issues and monitoring of performance
        </div>
        <div class="table-row-item">US*</div>
        <div class="table-row-item">30 days</div>
      </div>
      <div class="table-row">
        <div class="table-row-item">AWS</div>
        <div class="table-row-item">
          Storage of files uploaded by users to Mosaic and caching of course content for faster downloads.
        </div>
        <div class="table-row-item">
          <div>User files - EU (Ireland)</div>
          <div class="pt-2">Course content caching - Global, dependent on user location**</div>
        </div>
        <div class="table-row-item">Determined by Penrose Education in accordance with retention policy</div>
      </div>
    </div>

    <p>
      *The GDPR do not require data to be stored within the EU so long as data is transferred in an appropriate manner.
      Postmark, Github and Gitlab are fully GDPR compliant, and the data is not used for anything other than the
      purposes outlined in this privacy policy. For information on how each of these companies uses data, including
      their use of standard contractual clauses for use of data outside of the EU, please see the links below:
    </p>
    <p>
      **To improve download speeds when accessing files on Mosaic across the globe, we use a service that caches files
      on servers closer to the user temporarily. This service is provided by AWS and is GDPR compliant.
    </p>
    <ul>
      <li>
        <a href="https://postmarkapp.com/dpa">Postmark</a>
      </li>
      <li>
        <a href="https://about.gitlab.com/privacy/">GitLab</a>
      </li>
      <li>
        <a href="https://docs.github.com/en/site-policy/privacy-policies/github-privacy-statement">Github</a>
      </li>
      <li>
        <a
          href="https://sentry.io/trust/privacy/#:~:text=Sentry%20data%20is%20hosted%20on,compliance%20with%20HIPAA's%20Security%20Rule."
          >Sentry</a
        >
      </li>
      <li>
        <a href="https://aws.amazon.com/compliance/gdpr-center/">AWS</a>
      </li>
    </ul>
  </article>
</template>

<script>
export default {
  name: 'PrivacyPolicyPage',
};
</script>

<style scoped>
.table-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}
.table-row-item {
  display: flex;
  border: 1px solid black;
  padding: 8px;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}
article {
  width: 80%;
  margin: auto;
}
</style>
