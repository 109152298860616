<template>
  <div>
    <v-card v-if="!userStaff.storage_set_up && !useOnDemandSharing">
      <v-card-text>
        <div class="pl-6 pa-4">
          <span class="text-h6">Accessing {{ traineeNounCapitalised() }} files</span>
          <p class="mt-4">
            {{ traineeNounCapitalised() }} files are stored on {{ selectedInstitution.storage_type }}. For you to open
            these files, we need to share them with your {{ selectedInstitution.storage_type }} account.
          </p>
          <div>
            We need to:
            <ul>
              <li>
                confirm the email you use for your {{ selectedInstitution.storage_type }} account by logging you into
                {{ selectedInstitution.storage_type }}
              </li>
              <li>
                if you do not have a {{ selectedInstitution.storage_type }} account, you can easily create one
                <a :href="createStorageAccountLink" target="_blank">here</a>
              </li>
              <li>press the button below to start this process</li>
            </ul>
          </div>
        </div>
        <v-alert v-if="storageConstraintMessage" class="mx-6" type="info"
          ><div>{{ storageConstraintMessage }}</div>
        </v-alert>

        <v-alert v-if="storageEmailError" class="mx-6" type="error"
          ><div>{{ storageEmailError }}</div>
          <div class="mt-2">
            You may need to <a href="https://login.microsoftonline.com/logout.srf" target="_blank">logout</a> of your
            One Drive account first.
          </div>
        </v-alert>
        <div class="mb-4">
          <v-btn class="ml-6" @click.prevent="navigateToStorageLogin()"
            >Confirm {{ selectedInstitution.storage_type }} Email</v-btn
          >
        </div>
      </v-card-text>
    </v-card>
    <v-card v-else-if="!selectedStudent.storage_set_up">
      <v-card-text>
        <div class="pl-6 pa-4">
          This {{ traineeNoun() }} needs to setup their {{ selectedInstitution.storage_type }} account before you can
          view their files.
        </div>
      </v-card-text>
    </v-card>
    <files-list
      v-else
      :load-items="loadItems"
      :files-storage-type="selectedInstitution.storage_type"
      :storage-email="userStaff.storage_email"
      :get-file-url="getFileUrl"
      :page-breadcrumbs="breadcrumbs"
      :show-share-warning="sharingNotPossible && selectedInstitution.storage_type === 'One Drive'"
      :share-root-folder="shareRootFolder"
      @after-file-launch="afterFileLaunch()"
      @accept-sharing="$event => acceptSharing($event)"
      @volunteered-accept-sharing="$event => volunteeredAcceptSharing($event)"
    />
    <mosaic-dialog
      v-model:active="fileLaunchConfirmationDialog.active"
      :hide-close="true"
      title="One Drive File launch confirmation"
      :error-message="fileLaunchConfirmationDialog.error"
    >
      <mosaic-alert type="info" class="mb-4">
        <div>
          It looks like you've just launched a file for the first time with this {{ traineeNounCapitalised() }}'s One
          Drive setup and your Mosaic-linked OneDrive account.
        </div>
        <div class="pt-2">
          Sometimes Microsoft enforces an extra sharing step before {{ traineeNounCapitalised() }} files can be
          launched.
        </div>
      </mosaic-alert>
      <div class="pt-2">
        If the file launched successfully, please confirm this below. If you were unable to launch the file, please
        click click to go through the accept sharing flow. You will only need to do this once per
        {{ traineeNounCapitalised() }} Microsoft account domain.
      </div>

      <div class="pt-2">
        If you continue to experience issues launching files, please contact support@penrose.education.
      </div>

      <template #buttons>
        <mosaic-btn variant="text" @click="oneDriveFileLaunchedSuccessfully()">The file launched fine!</mosaic-btn>
        <mosaic-btn class="ml-2" color="primary" variant="text" @click="oneDriveAcceptSharing()"
          >I need to accept sharing</mosaic-btn
        >
      </template>
    </mosaic-dialog>
  </div>
</template>

<script>
import FilesList from '../../components/files-list/FilesList.vue';
import { mapState, mapGetters } from 'vuex';
import {
  startStaffLinkOneDriveEmailMicrosoftFlow,
  startStaffLinkGoogleDriveEmailFlow,
} from '../../utils/external-auth';
import { storageEmailError, storageConstraintMessage } from '../../utils/storage-constraints';

export default {
  name: 'TutorStudentFilesListPage',
  components: { FilesList },
  data: function () {
    return {
      studentId: null,
      hasCourseFiles: null,
      storageConstraint: null,
      fileLaunchConfirmationDialog: {
        active: false,
        error: '',
      },
      sharingNotPossible: false,
      promptLaunchCheck: false,
    };
  },
  computed: {
    ...mapState(['selectedStudent', 'selectedInstitution', 'userStaff']),
    ...mapGetters(['useOnDemandSharing']),
    breadcrumbs() {
      return [
        {
          text: 'Files',
          to: { name: 'FilesListPage' },
        },
      ];
    },
    createStorageAccountLink() {
      return this.selectedInstitution.storage_type == 'Google Drive'
        ? 'https://accounts.google.com/SignUp?hl=en'
        : 'https://signup.live.com/';
    },
    storageEmailError() {
      if (!this.storageConstraint) return false;
      return storageEmailError(this.$route.query, this.storageConstraint);
    },
    storageConstraintMessage() {
      if (!this.storageConstraint) return '';
      return storageConstraintMessage(this.storageConstraint);
    },
  },
  async created() {
    this.studentId = this.$route.params.studentId;
    if (this.selectedInstitution.storage_type === 'One Drive' && !this.userStaff.storage_email) {
      try {
        const r = await this.$api.get(`/institution-storage-constraints/${this.userStaff.id}`);
        this.storageConstraint = r.data;
      } catch (e) {
        console.log(e);
      }
    }
  },
  methods: {
    async loadItems(folder) {
      if (this.hasCourseFiles === null) {
        const r = await this.$api.get(`/institutions/${this.selectedInstitution.id}/course-files-exist`);
        this.hasCourseFiles = r.data.exists;
      }

      const url = folder.isCourse
        ? `/institutions/${this.selectedInstitution.id}/course-files/${encodeURIComponent(folder.id)}`
        : `/students/${this.studentId}/files/${encodeURIComponent(folder.id)}`;
      const response = await this.$api.get(url);
      this.sharingNotPossible = response.data.sharingNotPossible;
      this.promptLaunchCheck = response.data.promptLaunchCheck;

      // Add editable at the API instead
      let items = response.data.items.map(x => ({
        ...x,
        sortName: x.shared ? `000000000${x.name}` : x.name,
        isCourse: folder.isCourse,
        icon: {
          name: this.getIconName(x, folder),
          color: null,
          tooltip: x.isShortcut
            ? `This item is a shortcut and may need to be shared separately by the ${this.traineeNoun()}`
            : '',
        },
        editable: false,
        canLinkEvidence: false,
        filesApiPath: `/students/${this.studentId}/files`,
      }));

      if (this.hasCourseFiles && folder.id === '' && !folder.isCourse) {
        const courseFolder = {
          id: '',
          isCourse: true,
          type: 'folder',
          name: 'Course Files',
          icon: {
            name: 'folder-home',
            color: null,
            tooltip: 'This folder contains files uploaded by your course leads',
          },
          sortName: '000000000000',
          editable: false,
          filesApiPath: `/institutions/${this.selectedInstitution.id}/course-files`,
        };
        items = [courseFolder].concat(items);
      }

      return {
        items: items,
        folder: {
          id: folder.id || response.data.folder.id,
          name: folder.name || response.data.folder.name,
          isRootFolder: folder.isRootFolder || false,
          editable: false,
          isCourse: folder.isCourse,
          filesApiPath: folder.isCourse
            ? `/institutions/${this.selectedInstitution.id}/course-files`
            : `/students/${this.studentId}/files`,
          folderApiPath: `/students/${this.studentId}/folders`,
        },
        sharingLink: response.data.sharingLink,
        backupSharingLink: response.data.backupSharingLink,
      };
    },
    afterFileLaunch() {
      if (this.selectedInstitution.storage_type === 'One Drive' && this.promptLaunchCheck) {
        this.fileLaunchConfirmationDialog.active = true;
      }
    },
    async getFileUrl(item, download) {
      const urlRoot = item.isCourse
        ? `institutions/${this.selectedInstitution.id}/course-file`
        : `students/${this.studentId}/file`;
      const r = await this.$api.get(`/${urlRoot}/${encodeURIComponent(item.id)}?download=${download}`);
      return r.data.webUrl;
    },
    getIconName(item, folder) {
      if (item.type === 'file') {
        if (item.isShortcut) return 'file-move';
        return 'file';
      } else {
        if (folder.isCourse) return 'folder-home';
        if (item.isShortcut) return 'folder-arrow-right';
      }
      return 'folder';
    },
    async shareRootFolder() {
      const r = await this.$api.post(`students/${this.studentId}/files-share`);
      return r.data;
    },
    navigateToStorageLogin: function () {
      this.selectedInstitution.storage_type === 'One Drive'
        ? startStaffLinkOneDriveEmailMicrosoftFlow(this.$route.path, this.selectedInstitution.id)
        : startStaffLinkGoogleDriveEmailFlow(this.$route.path, this.$api);
    },
    async oneDriveFileLaunchedSuccessfully() {
      await this.updateOneDriveSharingStatus({
        post_launch_sharing_status: 'confirmed_works',
      });
      this.promptLaunchCheck = false;
    },
    async oneDriveAcceptSharing() {
      await this.updateOneDriveSharingStatus({
        post_launch_sharing_status: 'accepted_sharing',
      });
    },
    async updateOneDriveSharingStatus(body) {
      if (!this.selectedInstitution.storage_type === 'One Drive') return;
      try {
        await this.$api.post(`/staff/${this.userStaff.id}/update-sharing-status/${this.selectedStudent.id}`, body);

        this.fileLaunchConfirmationDialog.active = false;
      } catch (e) {
        console.log(e);
        this.fileLaunchConfirmationDialog.error = e.response.data.error;
      }
    },

    volunteeredAcceptSharing(sharingLink) {
      window.open(sharingLink, '_blank');
      this.updateOneDriveSharingStatus({
        volunteered_to_go_through_accept_sharing: true,
      });
    },
  },
};
</script>
