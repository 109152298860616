<template>
  <mosaic-cohort-students-wrapper :students="rows">
    <mosaic-table
      :key="placementId"
      :columns="monitoringColumnsWithExtras"
      :rows="rows"
      :object-type-pluralised="traineeNounCapitalisedAndPluralised"
    ></mosaic-table>
  </mosaic-cohort-students-wrapper>
</template>

<script setup lang="ts">
import type { Row, Column, NumberColumn } from '@/components/monitoring/mosaic-table';
import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';
import MosaicTable from './MosaicTable.vue';
import type { WeeklyMonitoringHeader } from '@/pages/tutor/utils/monitoring';
import MosaicCohortStudentsWrapper from './MosaicCohortStudentsWrapper.vue';

const props = withDefaults(
  defineProps<{
    headers: WeeklyMonitoringHeader[];
    placementId: number;
    rows: Row[];
    extraColumns?: {
      column: Column;
      position: number;
    }[];
    objectTypePluralised: string;
    nameClickRoute: (row: Row) => RouteLocationNamedRaw;
    listPageRouteName?: string;
    dataTextTransform?: (row: Row, header: WeeklyMonitoringHeader) => number;
  }>(),
  {
    dataTextTransform: (row: Row, header: WeeklyMonitoringHeader) => row[header.key],
  }
);

const countColumns = computed(() =>
  props.headers.map<NumberColumn>(h => ({
    name: h.label,
    key: h.key,
    sticky: false,
    value: (row: Row) => props.dataTextTransform(row, h),
    clickRoute: props.listPageRouteName
      ? (row: Row) => ({
          name: props.listPageRouteName,
          params: { studentId: row.id },
          query: { start: h.start, end: h.end },
        })
      : undefined,
  }))
);

const monitoringColumnsWithExtras = computed<Column[]>(() => {
  const columns = [
    {
      name: 'Name',
      key: 'name',
      sticky: true,
      clickRoute: (row: Row) => props.nameClickRoute(row),
      text: (row: Row) => row.name,
    },
    ...countColumns.value,
  ] as Column[];
  if (props.extraColumns) {
    props.extraColumns.forEach(c => {
      columns.splice(c.position, 0, c.column);
    });
  }
  return columns;
});
</script>
