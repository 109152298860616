<template>
  <div>
    <review-template-header>
      <template #configure>
        <v-card class="mb-4">
          <v-card-text>
            <div class="pa-2">
              <div class="d-flex pb-2">
                <div class="flex-grow-1">
                  <div class="text-h6">Configuring "{{ reviewTemplate.name }}"</div>
                  <div v-if="reviewTemplate.review_type">
                    {{ reviewNounCapitalised }} Type -
                    {{ reviewTypeItems.find(x => x.value === reviewTemplate.review_type).title }}
                  </div>
                </div>
                <v-menu v-if="!viewOnly">
                  <template #activator="{ props }">
                    <v-btn ripple v-bind="props">
                      <div class="d-flex align-center">
                        <v-icon>mdi-plus</v-icon>
                        <span class="pl-1">{{ reviewNounCapitalised }}</span>
                      </div>
                    </v-btn>
                  </template>
                  <v-list min-width="200">
                    <v-list-item
                      v-for="c in activeCohorts"
                      :key="c.id"
                      @click="createReview(c)"
                      :title="c.name"
                      prepend-icon="mdi-google-classroom"
                    >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <v-alert
                v-if="reviewTemplatesThatShareStatementSet.length > 0 || reviewTemplateReviews.length > 0"
                type="info"
                variant="outlined"
                class="mb-2 mt-1"
              >
                <div v-if="reviewTemplateReviews.length > 0">
                  This {{ reviewNounCapitalised }} Template is used by the following
                  {{ reviewNounCapitalisedAndPluralised }}:
                  <span v-for="(r, index) of reviewTemplateReviews" :key="r.id">
                    <mosaic-router-link
                      :to="{
                        name: 'TutorAdminReviewViewPage',
                        params: { reviewId: r.id, cohortId: r.cohort_id },
                      }"
                      >{{ r.name }} ({{ r.cohort.name }})</mosaic-router-link
                    ><span v-if="index !== reviewTemplateReviews.length - 1">, </span>
                  </span>
                  <div v-if="!viewOnly">
                    Any edits here will not be applied to existing {{ reviewNounCapitalisedAndPluralised }} and there
                    will be large amounts of the template that cannot be edited. If you need a different configuration
                    then consider creating a new template.
                  </div>
                </div>
                <div
                  v-if="reviewTemplatesThatShareStatementSet.length > 0 || reviewTemplateReviews.length > 0"
                  :class="{ 'pt-1': reviewTemplateReviews.length > 0 }"
                ></div>
                <div v-if="reviewTemplatesThatShareStatementSet.length > 0">
                  This {{ reviewNounCapitalised }} Template shares a statement set with:
                  <span v-for="(rt, index) of reviewTemplatesThatShareStatementSet" :key="rt.id">
                    <mosaic-router-link :to="{ name: 'TutorAdminReviewTemplatePage', params: { templateId: rt.id } }">{{
                      rt.name
                    }}</mosaic-router-link>
                    <span v-if="index !== reviewTemplatesThatShareStatementSet.length - 1">,</span>
                  </span>
                  <div>
                    This means the statements in this template cannot be edited. If you'd like to use a variation on
                    these statements then create a copy of them by creating a new template copied from this one.
                  </div>
                </div>
              </v-alert>
              <div>
                Here you can set who can approve the {{ reviewNounCapitalised }} and who the main contributors are.
                Contributors will have a button to mark when they've completed their sections of the
                {{ reviewNounCapitalised }}, but other roles can still be given permissions to edit various parts of the
                {{ reviewNounCapitalised }}.
              </div>

              <div class="text-h6 pt-3">Approvers</div>
              <review-role-select
                :roles="approvalPermissions"
                label="Who can approve"
                @selected-roles-updated="roleApprovalsChanged($event)"
              />
              <div class="text-h6 pt-3">Viewers</div>
              <review-role-select
                :roles="viewerPermissions"
                label="Who can view"
                @selected-roles-updated="viewersChanged($event)"
              />
              <div class="text-h6 pt-3">Contributors</div>
              <review-role-select
                :roles="contributorPermissions"
                label="Who are the main contributors"
                @selected-roles-updated="contributorsChanged($event)"
              />
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mb-4">
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">Non-{{ standardNounCapitalised }} Specific Configuration</div>
              <v-list>
                <mosaic-list-item
                  lines="two"
                  :to="{
                    name: 'TutorAdminReviewTemplateOverallPage',
                    params: { templateId: reviewTemplate.id },
                  }"
                  icon="mdi-cog-outline"
                  title="General"
                >
                </mosaic-list-item>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
        <v-card>
          <v-card-text>
            <div class="pa-2">
              <div class="text-h6">{{ standardNounCapitalised }} Specific Configuration</div>
              <template v-if="sortedReviewTemplateStandards.length > 0">
                <div class="pt-2">Configuration applying to all {{ standardNounCapitalisedAndPluralised }}</div>
                <v-list lines="two">
                  <mosaic-list-item
                    :to="{
                      name: 'TutorAdminReviewTemplatePerStandardPage',
                      params: { templateId: reviewTemplate.id },
                    }"
                    icon="mdi-cog-outline"
                    :title="`Per ${standardNounCapitalised} Assessments`"
                  >
                  </mosaic-list-item>
                </v-list>
                <div class="pt-2">Configuration specific to each {{ standardNounCapitalised }}</div>

                <mosaic-list
                  :empty-state="`There are no ${standardNounCapitalisedAndPluralised} in this ${reviewNounCapitalised}`"
                  :items="sortedReviewTemplateStandards"
                >
                  <template #item="{ item: rts }">
                    <mosaic-list-item
                      :to="{
                        name: 'TutorAdminReviewTemplateStandardPage',
                        params: { templateId: reviewTemplate.id, id: rts.id },
                      }"
                      icon="mdi-cog-outline"
                      :title="`${rts.code} - ${rts.name}`"
                    >
                      <template #actions>
                        <ndt-icon-button
                          icon="delete"
                          :tooltip="
                            reviewTemplateInUse ? 'Cannot remove as Template is in use' : 'Remove from Template'
                          "
                          :disabled="reviewTemplateInUse"
                          @click.prevent="deleteReviewTemplateStandard(rts)"
                        />
                      </template>
                    </mosaic-list-item>
                  </template>
                </mosaic-list>
              </template>

              <mosaic-list-item
                lines="two"
                :to="{
                  name: 'TutorAdminReviewTemplatePart2Page',
                  params: { templateId: reviewTemplate.id },
                }"
                icon="mdi-cog-outline"
                title="Part 2 - Personal and Professional Conduct"
                style="margin-top: -8px"
              >
              </mosaic-list-item>
            </div>
          </v-card-text>
        </v-card>
      </template>
      <template #preview>
        <review-card
          :student-review="reviewTemplateAsStudentReview"
          :staff-viewer="previewUser.staff"
          :student-viewer="previewUser.student"
          :student="previewStudent"
        />
      </template>
    </review-template-header>

    <ndt-dialog
      v-model:active="deleteDialog.active"
      :error-message="deleteDialog.error"
      :title="`Remove ${standardNounCapitalised} from ${reviewNounCapitalised} Template`"
      :width="500"
    >
      <span
        >Are you sure you want to remove "{{ deleteDialog.title }}" from this template? This action cannot be
        undone.</span
      >
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteReviewTemplateStandard()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>
  </div>
</template>

<script>
import ReviewCard from '@/components/ReviewCard.vue';
import ReviewTemplateHeader from '@/components/ReviewTemplateHeader.vue';
import { mapGetters, mapState } from 'vuex';
import config from '@/utils/config';
import NdtIconButton from '@/components/NdtIconButton.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import ReviewRoleSelect from './ReviewRoleSelect.vue';

export default {
  name: 'TutorAdminReviewTemplatePage',
  components: { ReviewCard, ReviewTemplateHeader, NdtIconButton, NdtDialog, ReviewRoleSelect },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.$store.dispatch('loadReviewTemplate', {
        id: to.params.templateId,
        force: from.name === 'TutorAdminReviewCreatePage',
      });
    });
  },
  data: () => ({
    error: '',
    config,
    deleteDialog: {
      active: false,
      id: null,
      title: '',
      error: null,
      processing: false,
    },
  }),
  watch: {
    $route: {
      handler(to) {
        this.$store.dispatch('loadReviewTemplate', { id: to.params.templateId });
      },
      deep: true,
    },
  },
  computed: {
    ...mapState(['reviewTemplate', 'roles', 'selectedInstitution', 'reviewTypeItems']),
    ...mapGetters([
      'reviewTemplateRoles',
      'reviewTemplateAsStudentReview',
      'previewUser',
      'previewStudent',
      'reviewTemplateReviews',
      'reviewTemplatesThatShareStatementSet',
      'reviewTemplateInUse',
      'createPermissionsArray',
      'activeCohorts',
    ]),
    breadcrumbs() {
      return [
        {
          text: `${this.reviewNounCapitalised} Templates`,
          to: {
            name: 'TutorAdminReviewTemplatesListPage',
            params: { institutionId: this.selectedInstitution.id },
          },
        },
        {
          text: this.reviewTemplate?.name,
        },
      ];
    },
    viewOnly() {
      return this?.reviewTemplate?.status === 'archived';
    },
    sortedReviewTemplateStandards() {
      return [...this.reviewTemplate.review_template_standards].sort((a, b) => (a.order > b.order ? 1 : -1));
    },
    approvalPermissions() {
      return this.createPermissionsArray('Approve');
    },
    contributorPermissions() {
      return [
        {
          roleId: 'student',
          selected: this.reviewTemplate.is_trainee_contributor,
          canView: this.reviewTemplate.can_trainee_view_review,
        },
        ...this.createPermissionsArray('Contributor'),
      ];
    },
    viewerPermissions() {
      // canView is used to determine whether to disable the permissions checkboxes
      // This doesn't apply when editing the view permission itself
      return [
        {
          roleId: 'student',
          selected: this.reviewTemplate.can_trainee_view_review,
          canView: true,
        },
        ...this.createPermissionsArray('Viewer').map(p => ({ ...p, canView: true })),
      ];
    },
  },
  methods: {
    roleApprovalsChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles.map(role => ({
          roleId: role.roleId,
          edit: role.selected,
          permission: 'Approve',
        })),
      });
    },
    contributorsChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => ({
            roleId: role.roleId,
            edit: role.selected,
            permission: 'Contributor',
          })),
        traineePermissionUpdates: { is_trainee_contributor: roles.find(r => r.roleId === 'student').selected },
      });
    },
    viewersChanged(roles) {
      this.$store.dispatch('updateReviewTemplatePermissions', {
        roles: roles
          .filter(r => r.roleId !== 'student')
          .map(role => ({
            roleId: role.roleId,
            edit: role.selected,
            view: role.selected,
            permission: 'Viewer',
          })),
        traineePermissionUpdates: { can_trainee_view_review: roles.find(r => r.roleId === 'student').selected },
      });
    },
    // This is different to the auto-saving in the rest of review templates because it's a destructive action
    // that you currently cannot recover fro,
    deleteReviewTemplateStandard(rts) {
      this.deleteDialog = {
        active: true,
        id: rts.id,
        title: `${rts.code} - ${rts.name}`,
        processing: false,
      };
    },
    async submitDeleteReviewTemplateStandard() {
      this.deleteDialog.processing = true;
      this.deleteDialog.error = null;
      try {
        await this.$api.delete(`/review-template-standards/${this.deleteDialog.id}`);
        this.$store.commit('removeReviewTemplateStandard', this.deleteDialog.id);
        this.deleteDialog.active = false;
      } catch (e) {
        console.log(e);
        this.deleteDialog.error = `Sorry, cannot remove ${this.standardNounCapitalised} from the ${this.reviewNounCapitalised} Template at the moment`;
      }
      this.deleteDialog.processing = false;
    },
    createReview(cohort) {
      this.$router.push({
        name: 'TutorAdminReviewCreatePage',
        params: { cohortId: cohort.id },
        query: { reviewTemplateId: this.reviewTemplate.id },
      });
    },
  },
};
</script>
