<template>
  <v-card elevation="4">
    <v-toolbar color="white" elevation="6">
      <v-toolbar-title>Forgot your password?</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <mosaic-error-alert :override-error-message="errorMessage" />
      <v-text-field
        v-model="email"
        prepend-icon="mdi-account"
        name="email"
        label="Email"
        type="text"
        @keyup.enter="forgotPassword()"
      />
      <v-alert v-model="showSuccessMessage" type="success" density="compact">
        <div class="pb-2">Email sent!</div>
        <div class="pb-2">Please check your email for instructions on how to reset your password.</div>
        <div>If you do not receive an email:</div>
        <ul>
          <li>
            Please check your spam/junk folder. If you find the email here, please whitelist the sender
            (accounts@mosaic.penrose.education)
          </li>
          <li>
            Contact your Training Provider/Appropriate Body to request a password reset if you still can't find the
            email
          </li>
        </ul>
      </v-alert>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn :disabled="!canSubmit" @click.prevent="forgotPassword()">Send instructions</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { rateLimitErrorMessage } from '@/utils/errors';
import { validateEmail } from '@/utils/email';

export default {
  name: 'ForgotPasswordPage',
  data: function () {
    return {
      email: '',
      errorMessage: '',
      showSuccessMessage: false,
      busy: false,
    };
  },
  computed: {
    canSubmit: function () {
      return !this.busy && this.email;
    },
  },
  created: function () {
    const email = localStorage.getItem('lastLoginEmail');
    if (email) {
      this.email = email;
    }
  },
  methods: {
    async forgotPassword() {
      if (!this.canSubmit) return;
      this.busy = true;
      this.errorMessage = '';
      this.showSuccessMessage = false;
      try {
        const email = this.email.trim();
        if (!validateEmail(email)) {
          // Give visual feedback if resubmitting an invalid email - is there a better way to do this?
          setTimeout(() => (this.errorMessage = 'Please supply a valid email'), 100);
        } else {
          await this.$api.post('password/forgot', { email });
          this.showSuccessMessage = true;
        }
      } catch (e) {
        if (e.response?.status === 429) {
          this.errorMessage = rateLimitErrorMessage(e.response.data.wait_time_in_seconds, 'reset your password');
        } else if (e.response?.status === 422 && e.response.data.error_code === 'email_bounced') {
          this.errorMessage =
            'Cannot deliver to your email address. Please get in touch with support@penrose.education.';
        } else if (e.response?.status === 422 && e.response.data.error_code === 'no_password') {
          this.errorMessage =
            'Sorry, this Mosaic account does not support password login so the password cannot be reset. Sign in with Microsoft instead.';
        } else {
          console.log(e);
          this.errorMessage = 'Sorry, cannot connect to the server. Please try again later.';
        }
      }
      this.busy = false;
    },
  },
};
</script>

<style scoped>
.signup-message {
  padding: 8px 0;
  width: 100%;
  text-align: center;
}
</style>
