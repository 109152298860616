<template>
  <div>
    <mosaic-alert
      v-if="userStaffHasPermission('staff.edit') && userStaffHasPermission('courseFiles.edit')"
      type="info"
      closable
      >Shared Instructor Files can be edited in the
      <mosaic-router-link class="text-body-1 router-link" :to="{ name: 'TutorAdminStaffFilesPage' }"
        >Instructor Files</mosaic-router-link
      >
      area under Course Files</mosaic-alert
    >
    <files-list
      :load-items="loadItems"
      files-storage-type="Mosaic"
      :get-file-url="getFileUrl"
      :can-add-as-evidence="false"
      :page-breadcrumbs="breadcrumbs"
    />
  </div>
</template>

<script>
import FilesList from '../../components/files-list/FilesList.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorStaffFilesListPage',
  computed: {
    ...mapState(['selectedInstitution']),
    breadcrumbs() {
      return [{ text: `Shared Files`, to: { name: 'TutorStaffFilesListPage' } }];
    },
  },
  methods: {
    async loadItems(folder) {
      const response = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/staff-files/${this.loadItemsSuffix(folder)}`
      );

      // Add editable at the API instead
      return {
        items: response.data.items.map(x => ({
          ...x,
          sortName: x.name,
          editable: false,
          isMoveTarget: x.type === 'folder',
          filesApiPath: `/institutions/${this.selectedInstitution.id}/staff-files/${this.roleSuffix(x)}`,
          icon: {
            name: this.getIconName(x),
            color: null,
            tooltip: '',
          },
        })),
        folder: {
          id: folder.id || response.data.folder.id,
          name: folder.name || response.data.folder.name,
          isRootFolder: folder.isRootFolder || false,
          editable: false,
          roleId: response.data.folder.roleId,
          isRootRoleFolder: response.data.folder.isRootRoleFolder || false,
          uploadDirectToExternalStorage: true,
          filesApiPath: null,
          presignFilesApiPath: null,
          folderApiPath: null,
        },
      };
    },
    loadItemsSuffix(item) {
      if (item.roleId) {
        const suffix = `roles/${item.roleId}`;
        if (item.isRootRoleFolder) return suffix;
        return `${suffix}/${encodeURIComponent(item.id)}`;
      }

      if (item.id) {
        return `all-staff/${encodeURIComponent(item.id)}`;
      }

      return '';
    },
    roleSuffix(item) {
      return item.roleId ? `roles/${item.roleId}` : 'all-staff';
    },
    getIconName(x) {
      if (x.roleName) return 'folder-lock-open';
      return x.type;
    },
    async getFileUrl(item, download) {
      const r = await this.$api.get(
        `/institutions/${this.selectedInstitution.id}/admin-staff-file/${this.roleSuffix(item)}/${encodeURIComponent(
          item.id
        )}?download=${download}`
      );
      return r.data.webUrl;
    },
  },
  components: { FilesList },
};
</script>
<style scoped>
.router-link {
  color: white;
}
</style>
