<template>
  <mosaic-loading-and-error-cards object-type="Competency Statements" :load="load">
    <mosaic-card v-if="theme">
      <mosaic-card-title>
        <span>{{ theme.code }} - {{ theme.name }}</span>
        <template #chip>
          <mosaic-tooltip-chip :color="theme.status == 'not_met' ? 'accent' : 'primary'">
            <template #text>{{ theme.status === 'not_met' ? 'Not Met' : 'Met' }}</template>
            <template #tooltip>
              <div v-if="theme.status === 'not_met'">
                This Competency Theme has not yet been met. Meet it by fulfilling all the Requirements of the
                Competencies or having a Training Record marking them as met.
              </div>
              <div v-else>This Competency Theme has been met as all of its Competencies have been met</div>
            </template>
          </mosaic-tooltip-chip>
        </template>
      </mosaic-card-title>
      <mosaic-card-subtitle class="pb-4"> {{ framework?.name }}</mosaic-card-subtitle>
      <template v-for="(competency, i) in competencies" :key="competency.id">
        <div :class="{ 'pb-4': i < competencies.length - 1 }">
          <mosaic-card-subheading :class="{ 'pt-4': i > 0 }" full-width>
            <span>{{ competency.code }} - {{ competency.name }}</span>
            <template #chip>
              <mosaic-tooltip-chip :color="competency.status == 'not_met' ? 'accent' : 'primary'">
                <template #text>{{ competency.status === 'not_met' ? 'Not Met' : 'Met' }}</template>
                <template #tooltip>
                  <div v-if="competency.status === 'not_met'">
                    This Competency has not yet been met. Meet it by fulfilling all the Module Requirements or having a
                    Training Record marking it as met.
                  </div>
                  <div v-else-if="competency.status === 'met_by_requirements'">
                    This Competency has been met by fulfilling all of its Module Requirements
                  </div>
                  <div v-else-if="competency.status === 'met_by_training_record'">
                    This Competency has been met by a manual Training Record
                  </div>
                  <div v-else-if="competency.status === 'met_by_certificate'">
                    This Competency has been met by an approved Certificate
                  </div>
                  <div v-else-if="competency.status === 'met_by_event'">
                    This Competency has been met by attending an Event
                  </div>
                </template>
              </mosaic-tooltip-chip>
            </template>
          </mosaic-card-subheading>
          <mosaic-quill
            v-if="competency.description"
            :key="competency.id"
            v-model:contents="competency.description"
            :read-only="true"
          />
          <div>
            <div
              v-if="
                competency.requiredModules.length > 0 && ['not_met', 'met_by_requirements'].includes(competency.status)
              "
            >
              <div class="text-h8 pt-4">Training Modules</div>
              <div>
                This Competency {{ competency.status == 'not_met' ? 'can be' : 'has been' }} met by completing all of
                these Training Modules
              </div>
              <mosaic-list :items="competency.requiredModules">
                <template #item="{ item: module }">
                  <mosaic-list-item
                    :key="module.id"
                    :icon="icons.instructorTrainingModule"
                    :title="module.name"
                    :subtitle="
                      'Training Module - ' +
                      (module.status === 'not_started'
                        ? 'Not Started'
                        : `${mapStatus(module).text} on ${formatDate(
                            module.status === 'completed' && module.completedAt
                              ? module.completedAt
                              : module.completionCreatedAt
                          )}`) +
                      (staffTrainingModuleExpectedDuration(module, '')
                        ? ` - ${staffTrainingModuleExpectedDuration(module, '')}`
                        : '')
                    "
                    :to="clickModuleTo(module)"
                    :chip="mapStatus(module)"
                  >
                  </mosaic-list-item>
                </template>
              </mosaic-list>
            </div>

            <div
              v-if="['not_met', 'met_by_event'].includes(competency.status) && competencyEvents(competency).length > 0"
            >
              <div class="text-h8 pt-4">Events</div>
              <div>
                This Competency {{ competency.status == 'not_met' ? 'can be' : 'has been' }} met by attending one of
                these Events
              </div>
              <mosaic-list :items="competencyEvents(competency)">
                <template #item="{ item: event }">
                  <mosaic-list-item
                    :key="event.id"
                    :icon="icons.instructorTrainingEvent"
                    :title="event.name"
                    :subtitle="`Event - ${formatDateTime(event.startsAt)} - Duration ${formatDuration(
                      event.durationHours,
                      event.durationMinutes
                    )}`"
                    :to="clickEventTo(event)"
                  >
                  </mosaic-list-item>
                </template>
              </mosaic-list>
            </div>

            <div v-if="['notMet', 'met_by_training_record'].includes(competency.status)">
              <div class="text-h8 pt-4">Manual Training Records</div>
              <div>
                This Competency {{ competency.status == 'not_met' ? 'can be' : 'has been' }} met by your course leads
                creating a Manual Training Record
              </div>
              <mosaic-list v-if="competency.staffTrainingRecords.length > 0" :items="competency.staffTrainingRecords">
                <template #item="{ item: record }">
                  <mosaic-list-item
                    :key="record.id"
                    :icon="
                      record.recordType === 'prior_experience_audit'
                        ? icons.instructorTrainingRecordAudit
                        : icons.instructorTrainingRecordSession
                    "
                    :title="record.name"
                    :subtitle="staffTrainingRecordSubtitle(record)"
                    :to="clickRecordTo(record)"
                  >
                  </mosaic-list-item>
                </template>
              </mosaic-list>
            </div>

            <div
              v-if="
                competency.staffTrainingCertificates.length > 0 &&
                ['not_met', 'met_by_certificate'].includes(competency.status)
              "
            >
              <div class="text-h8 pt-4">Certificates</div>
              <div>
                This Competency {{ competency.status == 'not_met' ? 'can be' : 'has been' }} met by having one of these
                Certificates approved by your course leads
              </div>
              <mosaic-list :items="competency.staffTrainingCertificates">
                <template #item="{ item: certificate }">
                  <mosaic-list-item
                    :key="certificate.id"
                    :icon="icons.certificate"
                    :title="certificate.certificateType.name"
                    subtitle="Certificate"
                    :to="clickCertificateTo(certificate)"
                    :chip="staffTrainingCertificateStatusChip(certificate.status)"
                  >
                  </mosaic-list-item>
                </template>
              </mosaic-list>
            </div>
          </div>
        </div>
        <v-divider class="mb-2" v-if="i < competencies.length - 1" />
      </template>
    </mosaic-card>
  </mosaic-loading-and-error-cards>
</template>

<script setup lang="ts">
import { mapState } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import MosaicQuill from '@/components/quill/MosaicQuill.vue';
import {
  useStaffTrainingStore,
  type StaffTrainingCompetencyRequiredModule,
  type StaffTraining,
} from '@/stores/staff-training';
import { parseRouteId } from '@/composables/vue-router';
import { icons } from '@/utils/icons';
import {
  staffTrainingRecordSubtitle,
  staffTrainingCertificateStatusChip,
  useStaffTraining,
  staffTrainingModuleExpectedDuration,
} from './staff-training';
import { formatDateTime } from '@/utils/date';
import { formatDuration } from '@/utils/time';

const moduleStatusMap = {
  not_started: {
    text: 'Not Started',
    color: 'accent',
  },
  started: {
    text: 'Started',
    color: 'accent',
  },
  completed: {
    text: 'Completed',
    color: 'primary',
  },
};

const mapStatus = (module: StaffTrainingCompetencyRequiredModule) => moduleStatusMap[module.status];

// Load Staff Training
const { userStaff, selectedInstitution } = mapState();

const {
  staffTraining,
  actions: { loadStaffTraining },
} = useStaffTrainingStore();

const route = useRoute();
const { isMyStaffTraining, staffId, staff, loadStaff } = useStaffTraining();
const themeId = parseRouteId('themeId');

const theme = computed(() => {
  if (staffTraining.value) {
    for (const f of staffTraining.value.staffTrainingFrameworks) {
      const theme = f.staffTrainingCompetencyThemes.find(t => t.id == themeId.value);
      if (theme) return theme;
    }
  }
  return undefined;
});

const framework = computed(() =>
  staffTraining.value?.staffTrainingFrameworks.find(f =>
    f.staffTrainingCompetencyThemes.some(t => t.id == themeId.value)
  )
);

const competencies = computed(() => {
  return (
    theme.value?.staffTrainingCompetencies.map(c => ({
      ...c,
      staffTrainingCertificates:
        c.status == 'met_by_certificate'
          ? c.staffTrainingCertificates.filter(c => c.status == 'approved')
          : c.staffTrainingCertificates,
    })) || []
  );
});

type Competency =
  StaffTraining['staffTrainingFrameworks'][number]['staffTrainingCompetencyThemes'][number]['staffTrainingCompetencies'][number];
function competencyEvents(competency: Competency) {
  if (competency.status == 'not_met') {
    return competency.staffTrainingEvents.filter(e => e.status == 'upcoming');
  }
  return competency.staffTrainingEvents.filter(e => e.status == 'attended');
}

async function load() {
  await Promise.all([loadStaff(), loadStaffTraining(staffId.value)]);
}

setBreadcrumbs(
  computed(() => {
    if (isMyStaffTraining.value) {
      return [
        {
          text: 'My Training',
          to: { name: 'TutorStaffTrainingPage' },
          query: { tab: 'training-requirements' },
        },
        {
          text: theme.value?.name || '',
          to: {
            name: 'TutorStaffTrainingCompetencyThemePage',
            params: {
              themeId: themeId.value.toString(),
            },
          },
        },
      ];
    }
    return [
      {
        text: 'Instructors',
        to: {
          name: 'TutorStaffListPage',
        },
      },
      {
        text: staff.value?.user.name || '',
        to: {
          name: 'TutorStaffPage',
          params: { institutionId: selectedInstitution.value.id.toString(), id: staffId.value.toString() },
          query: { tab: route.query.fromTab?.toString() || 'training-requirements' },
        },
      },
      { text: theme.value?.name || '' },
    ];
  })
);

// Click
const clickModuleTo = (module: StaffTrainingCompetencyRequiredModule) => {
  if (isMyStaffTraining.value) {
    return {
      name: 'MyStaffTrainingModulePage',
      params: { moduleId: module.id },
    };
  }
  return {
    name: 'TutorStaffTrainingModulePage',
    params: { moduleId: module.id, staffId: staffId.value },
  };
};

const clickRecordTo = (record: { id: number }) => {
  if (isMyStaffTraining.value) {
    return {
      name: 'MyStaffTrainingRecordEditPage',
      params: { recordId: record.id },
    };
  }
  return {
    name: 'StaffTrainingRecordEditPage',
    params: { recordId: record.id, staffId: userStaff.value.id },
  };
};

const clickCertificateTo = (
  certificate: (typeof competencies)['value'][number]['staffTrainingCertificates'][number]
) => {
  if (isMyStaffTraining.value) {
    return certificate.status == 'not_created'
      ? {
          name: 'MyStaffTrainingCertificateCreatePage',
          query: { certificateTypeId: certificate.certificateType.id },
        }
      : {
          name: 'MyStaffTrainingCertificateEditPage',
          params: { certificateId: certificate.id },
        };
  }

  if (certificate.status == 'not_created') return undefined;
  return {
    name: 'StaffTrainingCertificateEditPage',
    params: { staffId: staffId.value, certificateId: certificate.id },
  };
};

const clickEventTo = (event: { id: number }) => {
  if (isMyStaffTraining.value) {
    return {
      name: 'MyStaffTrainingEventPage',
      params: { eventId: event.id },
    };
  }
  return {
    name: 'StaffTrainingEventPage',
    params: { eventId: event.id, staffId: staffId.value },
  };
};
</script>
