<template>
  <mosaic-card>
    <mosaic-card-title>Professional Resources Configuration</mosaic-card-title>

    <div class="my-2">
      Professional Resources are curated resources and opportunities to support the professional development of
      teachers, presented to your users on their home page.
    </div>
    <div class="mb-2">
      At Mosaic, we believe working in partnership is vital to the success of teacher professional development. As we
      establish more and more relationships with organisations we think you might find useful, we'll add these to the
      list below.
    </div>
    <div class="mb-2">
      However, you will <span class="text-decoration-underline">always</span> be able to request resources to be turned
      off if you do not wish these to display for your users. You will also be notified in advance whenever new
      Professional Resources are added.
    </div>
    <div>
      Please contact your Mosaic account manager if you would like to update your institution status for any of the
      Professional Resources below.
    </div>
    <mosaic-info-alert class="mt-2" v-if="!selectedInstitution.config.show_professional_resources"
      >The entire Professional Resources feature is currently switched off for your users.</mosaic-info-alert
    >
    <mosaic-card-subheading class="mt-4">Available Professional Resources</mosaic-card-subheading>
    <v-row class="my-2" v-if="professionalResources.length">
      <v-col :cols="smallScreen ? 12 : 6" v-for="resource in professionalResources" :key="resource.id">
        <mosaic-card variant="outlined">
          <div class="d-flex align-center mb-2">Resource: {{ resource.title }}</div>
          <div class="d-flex align-center mb-2">
            <div class="mr-2">Institution Status:</div>
            <v-chip :color="resource.onForInstitution ? 'success' : 'error'">
              {{ resource.onForInstitution ? 'ON' : 'OFF' }}
            </v-chip>
          </div>
          <div class="d-flex align-center mb-2">Shows for: {{ resource.viewers }}</div>
          <div class="mb-2">What users see:</div>
          <professional-resource-card
            :resource="resource"
            show-institution-state
            :on-for-institution="
              !resource.institutionExclusions.map(x => x.institutionId).includes(selectedInstitution.id)
            "
          />
        </mosaic-card>
      </v-col>
    </v-row>
    <div v-else class="mt-2">There are no Professional Resources available for your Institution.</div>
  </mosaic-card>
</template>
<script setup lang="ts">
import { ref } from 'vue';
import type { ProfessionalResource } from './professional-resource';
import ProfessionalResourceCard from './ProfessionalResourceCard.vue';
import { useApi } from '@/composables/api';
import { mapState, mapGetters } from '@/store/map-store';
import { setBreadcrumbs } from '@/utils/breadcrumbs';

const api = useApi();
const { selectedInstitution } = mapState();
const { traineeNounCapitalisedAndPluralised } = mapGetters();

setBreadcrumbs([
  {
    text: `Professional Resources Configuration`,
  },
]);

const renderViewers = (resource: ProfessionalResource) => {
  const viewers = [];
  if (resource.showStaff) viewers.push('Instructors');
  if (resource.showTrainees && !selectedInstitution.value.config.early_careers)
    viewers.push(traineeNounCapitalisedAndPluralised.value);
  if (resource.showEcts && selectedInstitution.value.config.early_careers)
    viewers.push(traineeNounCapitalisedAndPluralised.value);
  return viewers.join(', ') || 'No one';
};

const professionalResources = ref<(ProfessionalResource & { onForInstitution: boolean; viewers: string })[]>([]);
const loadProfessionalResources = async () => {
  const r = await api.get<ProfessionalResource[]>(
    `institutions/${selectedInstitution.value.id}/professional-resources`
  );
  professionalResources.value = r.data
    .filter(x =>
      selectedInstitution.value.config.early_careers ? x.showEcts || x.showStaff : x.showTrainees || x.showStaff
    )
    .map(x => ({
      ...x,
      onForInstitution:
        selectedInstitution.value.config.show_professional_resources &&
        !x.institutionExclusions.map(x => x.institutionId).includes(selectedInstitution.value.id),
      viewers: renderViewers(x),
    }));
};
loadProfessionalResources();
</script>
