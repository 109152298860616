<template>
  <v-autocomplete
    v-model="internalSelectedSubstandards"
    chips
    closable-chips
    multiple
    :label="standardNounCapitalised"
    :items="substandardsForAutocompleteV2"
    :custom-filter="autocompleteFilter"
    prepend-inner-icon="mdi-magnify"
    color="black"
    item-value="id"
    item-title="code"
    return-object
  >
    <template #item="{ props, item }">
      <v-divider v-if="item.raw.divider" />
      <v-list-subheader v-else-if="item.raw.header">
        {{ item.raw.header }}
      </v-list-subheader>
      <v-list-item v-else v-bind="props" :title="item.raw.code + ' - ' + item.raw.name" class="standard">
        <template #prepend>
          <v-list-item-action start>
            <v-checkbox-btn :model-value="isSelected(item.raw)" @click.stop="toggleItem(item.raw)" color="primary" />
          </v-list-item-action>
        </template>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import config from '../../utils/config';

export default {
  props: {
    selectedSubstandards: {
      type: Array,
      required: true,
    },
  },
  emits: ['selectedSubstandardsUpdated'],
  data: function () {
    return {
      config: config,
      internalSelectedSubstandards: [],
    };
  },
  mounted: function () {
    this.internalSelectedSubstandards = this.selectedSubstandards;
  },
  computed: {
    ...mapState(['standards']),
    ...mapGetters(['substandards', 'singleSubstandardPerStandard']),
    substandardsForAutocompleteV2() {
      if (!this.substandards || !this.standards) return [];
      let substandardsForAutocomplete;

      if (this.singleSubstandardPerStandard) {
        substandardsForAutocomplete = this.standards.map(x => {
          return this.substandards.find(s => s.code === x.code);
        });
      } else {
        substandardsForAutocomplete = [];
        this.standards.forEach(s => {
          const filterCode = s.substandards.map(x => x.code).join(' ');
          const filterName = s.substandards.map(x => x.name).join(' ');
          substandardsForAutocomplete.push({ header: `${s.code} - ${s.name}`, code: filterCode, name: filterName });
          substandardsForAutocomplete.push({ divider: true, code: filterCode, name: filterName });
          s.substandards.forEach(ss => substandardsForAutocomplete.push(ss));
        });
      }
      return substandardsForAutocomplete;
    },
  },
  watch: {
    selectedSubstandards: {
      handler(s) {
        this.internalSelectedSubstandards = s;
      },
      deep: true,
    },
    internalSelectedSubstandards: {
      handler(s) {
        this.$emit('selectedSubstandardsUpdated', s);
      },
      deep: true,
    },
  },
  methods: {
    isSelected(item) {
      return this.internalSelectedSubstandards.map(s => s.code).includes(item.code);
    },
    toggleItem(item) {
      if (this.isSelected(item)) {
        this.remove(item);
      } else {
        this.internalSelectedSubstandards.push(item);
      }
    },
    remove(item) {
      const index = this.internalSelectedSubstandards.map(s => s.code).indexOf(item.code);
      this.internalSelectedSubstandards.splice(index, 1);
    },
    autocompleteFilter(_, queryText, itemObject) {
      return (
        itemObject.raw.code.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        itemObject.raw.name.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) > -1 ||
        `${itemObject.raw.code} - ${itemObject.raw.name}`.toLocaleLowerCase().indexOf(queryText.toLocaleLowerCase()) >
          -1
      );
    },
  },
};
</script>

<style scoped>
.standard {
  max-width: 850px;
  word-break: break-word;
}
</style>
