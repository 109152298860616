import { defineStore } from 'pinia';
import { useStore } from './common';
import { computed, ref, watch } from 'vue';
import { useApi } from '@/composables/api';
import { useUserStore } from './user';
import { useStaffStore } from './staff';
import { CacheMap } from './cache-map';
import { isAxiosError } from 'axios';

export interface NasbttModule {
  publication_code: string;
  title: string;
  cover_url: string;
  subtitle: string | null;
  full_title: string;
  description: string;
}

interface NasbttModuleCompletion {
  progress: number | null;
  completed: boolean;
  lastCheckedAt: string;
}

export const useNasbttModulesStore = useStore(
  defineStore('nasbttModules', () => {
    const api = useApi();
    const { user } = useUserStore();
    const { userStaff } = useStaffStore();
    const nasbttModuleCache = ref(new CacheMap<NasbttModule[]>(60 * 24));
    const nasbttMentorModules = computed(() => nasbttModuleCache.value.getEvenIfExpired('mdm') || []);

    function getNasbttModuleTitle(publicationCode: string) {
      if (nasbttMentorModules.value.length === 0) return '';
      const module = nasbttMentorModules.value.find(m => m.publication_code === publicationCode);
      if (!module) throw `Can't find NASBTT module with publication code: ${publicationCode}`;
      return module.full_title;
    }

    function getNasbttModuleDescription(publicationCode: string) {
      if (nasbttMentorModules.value.length === 0) return '';
      const module = nasbttMentorModules.value.find(m => m.publication_code === publicationCode);
      if (!module) throw `Can't find NASBTT module with publication code: ${publicationCode}`;
      return module.description;
    }

    function getNasbttModuleImageUrl(publicationCode: string) {
      if (nasbttMentorModules.value.length === 0) return '';
      const module = nasbttMentorModules.value.find(m => m.publication_code === publicationCode);
      if (!module) throw `Can't find NASBTT module with publication code: ${publicationCode}`;
      return module.cover_url;
    }

    const loading = ref(false);
    const loadingError = ref<boolean | string>(false);
    async function loadNasbttMentorModules() {
      if (loading.value || nasbttModuleCache.value.get('mdm')) return;

      loading.value = true;
      loadingError.value = false;
      try {
        const r = await api.get<NasbttModule[]>(`/nasbtt-modules/modules/mentor`);
        nasbttModuleCache.value.set('mdm', r.data);
      } catch (e) {
        loadingError.value = true;
      } finally {
        loading.value = false;
      }
    }

    async function openStudentNasbttModule(studentId: number, publicationCode: string) {
      return openNasbttModule(`student/${studentId}`, publicationCode);
    }

    async function openStaffNasbttModule(staffId: number, publicationCode: string) {
      return openNasbttModule(`staff/${staffId}`, publicationCode);
    }

    async function openNasbttModule(urlPrefix: string, publicationCode: string): Promise<void | string> {
      if (isNasbttAccountLinkedForUser.value) {
        try {
          const r = await api.post<Record<string, never>, { url: string }>(
            `/${urlPrefix}/nasbtt-modules/modules/${publicationCode}/get-login-url`,
            {}
          );
          window.open(r.data.url, '_blank');
        } catch (e) {
          if (isAxiosError(e) && e.response?.status === 422 && e.response?.data.error_code == 'missing_license') {
            return 'Cannot open this NASBTT module as your NASBTT Modules account is missing a license. Please get in touch with your course leads.';
          }
          throw e;
        }
      } else {
        linkNasbttModulesAccount(publicationCode);
      }
    }

    function linkNasbttModulesAccount(publicationCode: string | null) {
      nasbttModulesAccountLinkDialog.value = {
        active: true,
        publicationCode,
      };
    }

    function getStudentNasbttModuleProgress(studentId: number, publicationCode: string, force?: boolean) {
      return getNasbttModuleProgress(`student/${studentId}`, publicationCode, force);
    }

    function getStaffNasbttModuleProgress(staffId: number, publicationCode: string, force?: boolean) {
      return getNasbttModuleProgress(`staff/${staffId}`, publicationCode, force);
    }

    const nasbttModuleProgressCache = ref<CacheMap<NasbttModuleCompletion>>(new CacheMap(10));
    async function getNasbttModuleProgress(urlPrefix: string, publicationCode: string, force?: boolean) {
      const key = `${urlPrefix}/${publicationCode}`;
      const progress = nasbttModuleProgressCache.value.get(key);
      if (!force && progress) return progress;

      const r = await api.get<NasbttModuleCompletion>(
        `${urlPrefix}/nasbtt-modules/modules/${publicationCode}/progress?force=${!!force}`
      );
      nasbttModuleProgressCache.value.set(key, r.data);
      return r.data;
    }

    const nasbttModulesAccountLinkDialog = ref({
      active: false,
      publicationCode: null as string | null,
    });
    const isNasbttAccountLinkedForUser = computed(() => {
      if (!user.value) return false;
      return user.value.student
        ? !!user.value.student.nasbtt_modules_user_id
        : !!userStaff.value.nasbtt_modules_user_id;
    });

    watch(isNasbttAccountLinkedForUser, x => {
      if (!x) {
        nasbttModuleProgressCache.value.clear();
      }
    });

    return {
      nasbttMentorModules,
      nasbttModulesAccountLinkDialog,
      isNasbttAccountLinkedForUser,
      nasbttModuleProgressCache,
      nasbttModuleCache,
      loadingError,
      loadNasbttMentorModules,
      getNasbttModuleTitle,
      getNasbttModuleDescription,
      getNasbttModuleImageUrl,
      openStudentNasbttModule,
      openStaffNasbttModule,
      getStudentNasbttModuleProgress,
      getStaffNasbttModuleProgress,
      linkNasbttModulesAccount,
    };
  })
);
