<template>
  <mosaic-dialog v-model:active="activeNdtDialog" :title="'Link to ' + curriculumNoun" :width="950">
    <v-autocomplete
      name="curriculum-statement-autocomplete"
      v-model="internalCurriculumStatement"
      :label="curriculumNoun"
      :items="curriculumStatementsArray"
      filter-keys="title"
      prepend-inner-icon="mdi-magnify"
      color="black"
      item-value="id"
      item-title="title"
      return-object
    >
      <template #item="{ item, props }">
        <v-divider v-if="item.raw.divider" />

        <v-list-subheader v-else-if="item.raw.header">{{ item.raw.header }}</v-list-subheader>
        <v-list-item v-else v-bind="props" :title="item.raw.code + ' - ' + item.raw.statement" width="900px">
          <template #append>
            <mosaic-tooltip-chip v-if="item.raw.hidden" color="secondary"
              ><template #text>Hidden</template
              ><template #tooltip>
                This Curriculum Statement is currently hidden. {{ traineeNounCapitalisedAndPluralised }} will not be
                able to see it unless you change its visibility.</template
              ></mosaic-tooltip-chip
            >
            <v-chip v-if="item.raw.subjectId">{{ subjectName(item.raw.subjectId) }} Only</v-chip>
          </template>
        </v-list-item>
      </template>
    </v-autocomplete>
    <template #buttons>
      <v-btn
        variant="text"
        ripple
        color="primary"
        :disabled="processing || !internalCurriculumStatement"
        @click.prevent="confirm()"
      >
        Confirm
      </v-btn>
    </template>
  </mosaic-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { visibleCurriculumStatements } from '@/utils/curriculum';

export default {
  name: 'LinkToCurriculumDialog',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    curriculumStatement: {
      type: Object,
      default: null,
    },
    selectedCurriculumStatements: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['curriculumStatementSelected', 'update:active'],
  data() {
    return {
      activeNdtDialog: this.active,
      processing: false,
      internalCurriculumStatement: null,
    };
  },
  watch: {
    activeNdtDialog(x) {
      this.$emit('update:active', x);
    },
    active(x) {
      this.activeNdtDialog = x;
    },
  },
  created() {
    this.$store.dispatch('loadSubjects');
  },
  computed: {
    ...mapState(['curriculumThemes', 'curriculumStatements', 'selectedStudent', 'user', 'subjects']),
    ...mapGetters([
      'singleStatementPerCurriculumTheme',
      'curriculumStatementsOrderedArray',
      'curriculumThemesOrderedArray',
    ]),
    curriculumStatementsArray: function () {
      if (!this.curriculumStatements) return [];
      let curriculumStatementsArray = [];

      if (this.singleStatementPerCurriculumTheme) {
        curriculumStatementsArray = visibleCurriculumStatements(
          this.curriculumStatementsOrderedArray,
          this.selectedStudent,
          this.user
        ).filter(s => !this.selectedCurriculumStatements.some(x => x.id === s.id));
      } else {
        this.curriculumThemesOrderedArray
          .filter(ct => ct.student_visible)
          .forEach(ct => {
            const filterCode = ct.curriculum_statements.map(x => x.code).join(' ');
            const filterStatement = ct.curriculum_statements.map(x => x.statement).join(' ');
            curriculumStatementsArray.push({
              header: ct.name,
              code: filterCode,
              statement: filterStatement,
            });
            curriculumStatementsArray.push({ divider: true, code: filterCode, statement: filterStatement });
            visibleCurriculumStatements(ct.curriculum_statements, this.selectedStudent, this.user)
              .filter(s => !this.selectedCurriculumStatements.some(x => x.id === s.id))
              .forEach(cs => {
                curriculumStatementsArray.push(cs);
              });
          });
      }
      return curriculumStatementsArray.map(s => {
        return {
          ...s,
          title: `${s.code} - ${s.statement}`,
        };
      });
    },
  },
  methods: {
    autocompleteFilter: (item, queryText) => {
      return queryText
        .split(' ')
        .every(q => `${item.code} - ${item.statement}`.toLocaleLowerCase().indexOf(q.toLocaleLowerCase()) > -1);
    },
    subjectName(id) {
      return this.subjects.find(x => x.id == id).name;
    },
    confirm() {
      this.$emit('curriculumStatementSelected', this.internalCurriculumStatement);
      this.internalCurriculumStatement = null;
    },
  },
};
</script>

<style scoped>
.curriculum-statement {
  max-width: 850px;
  word-break: break-word;
}
</style>
