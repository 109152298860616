<template>
  <div>
    <mosaic-loading-card v-if="loading" />
    <mosaic-load-error-card v-else-if="loadError" object-type="Instructor Training monitoring" @retry="loadTraining" />
    <div v-else>
      <cohort-staff-training-card :modules="data.staffTrainingModules" :training-data="data.staffTrainingData" />
    </div>
  </div>
</template>

<script>
import CohortStaffTrainingCard from './CohortStaffTrainingCard.vue';
import { mapState } from 'vuex';

export default {
  name: 'TutorCohortStaffTrainingPage',
  components: { CohortStaffTrainingCard },
  data: () => ({
    loading: true,
    loadError: false,
    data: null,
  }),
  created() {
    this.loadTraining();
  },
  computed: {
    ...mapState(['selectedCohort']),
    breadcrumbs() {
      return [
        {
          text: 'Instructor Training Monitoring',
        },
      ];
    },
  },
  methods: {
    async loadTraining() {
      this.loading = true;
      this.loadError = false;

      try {
        const r = await this.$api.get(`cohorts/${this.selectedCohort.id}/staff-training`);
        this.data = r.data;
      } catch (e) {
        console.log(e);
        this.loadError = true;
      }

      this.loading = false;
    },
  },
};
</script>
