import { defineStore } from 'pinia';
import { mapState } from '@/store/map-store';
import { useStore } from './common';
import { useApi } from '@/composables/api';
import type { SelectedStaff } from '@/pages/tutor/TutorStaffPage.vue';
import { computed, ref } from 'vue';
import { CacheMap } from './cache-map';
import type { Permission } from '@/store/map-store';
import { hasPermissionForStudent } from '@/mixins/global-mixins';

export const useStaffStore = useStore(
  defineStore('staff', () => {
    const api = useApi();

    const { userStaff: stateUserStaff, user, selectedStudent } = mapState();
    const userStaff = computed(() => ({ ...stateUserStaff.value, user: user.value }));

    const staffCache = ref<CacheMap<SelectedStaff>>(new CacheMap());
    const selectedStaffId = ref<number>();
    const selectedStaff = computed(() =>
      selectedStaffId.value ? staffCache.value.getEvenIfExpired(selectedStaffId.value) : undefined
    );

    const userStaffHasPermissionForSelectedStudent = (...permissions: Permission[]) => {
      return permissions.every(permission =>
        hasPermissionForStudent(userStaff.value, permission, selectedStudent.value)
      );
    };
    const userStaffHasRoleIdForSelectedStudent = (roleId: number) => {
      return userStaff.value.staff_roles.some(
        sr =>
          sr.role.id === roleId &&
          (sr.student_id === selectedStudent.value.id ||
            sr.cohort_id === selectedStudent.value.cohort.id ||
            sr.institution_id === selectedStudent.value.institution_id)
      );
    };

    // selectedStaff should probably be automatically selected by the router and not rely on indivdual pages to call loadStaff
    async function loadStaff(staffId: number, force = false) {
      selectedStaffId.value = staffId;
      if (force || !staffCache.value.get(staffId)) {
        const r = await api.get<SelectedStaff>(`/staff/${staffId}`);
        staffCache.value.set(staffId, r.data);
      }
    }

    return {
      userStaff,
      loadStaff,
      staffCache,
      selectedStaffId,
      selectedStaff,
      userStaffHasPermissionForSelectedStudent,
      userStaffHasRoleIdForSelectedStudent,
    };
  })
);
