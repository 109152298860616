import { defineStore } from 'pinia';
import { useStore } from './common';
import { computed, ref } from 'vue';
import { useApi } from '@/composables/api';
import { CacheMap } from './cache-map';
import { dateTimeIsInThePast } from '@/utils/date';
import { formatDuration } from '@/utils/time';

// #region StaffTraining representation used in store and across app
export type StaffTraining = {
  totalDuration: string;
  requirementsCount: {
    incomplete: number;
    total: number;
  };
  // Just the staffTrainingFrameworks required for the staff
  staffTrainingFrameworks: {
    id: number;
    name: string;
    requiredFor: {
      cohort: {
        id: number;
        name: string;
      };
      roles: {
        id: number;
        name: string;
      }[];
    }[];
    staffTrainingCompetencyThemes: StaffTrainingCompetencyTheme[];
  }[];
  staffTrainingModuleCompletions: {
    id: number;
    completed: boolean;
    completedAt: string;
    createdAt: string;
    staffTrainingModuleId: number;
    staffTrainingModule: {
      id: number;
      name: string;
      durationHours: number | null;
      durationMinutes: number | null;
    };
  }[];
  staffTrainingRecords: {
    id: number;
    name: string;
    recordType: 'training_session' | 'prior_experience_audit';
    date: string;
    durationHours: number;
    durationMinutes: number;
    staffTrainingRecordCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  }[];
  // All the staffTrainingAcceptedCertificates in the institution
  staffTrainingAcceptedCertificates: {
    id: number;
    description: string;
    certificateType: {
      id: number;
      name: string;
    };
    staffTrainingAcceptedCertificateCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  }[];
  staffTrainingCertificates: {
    id: number;
    certificateType: {
      id: number;
      name: string;
    };
    status: 'awaiting_approval' | 'approved' | 'rejected';
    relevantForInstitution: boolean;
    approvedAtLeastOnce: boolean;
  }[];
  // Just the attended staff training events
  staffTrainingEvents: {
    id: number;
    name: string;
    startsAt: string;
    durationHours: number;
    durationMinutes: number;
    staffTrainingEventCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  }[];
};

export interface StaffTrainingCompetencyTheme {
  id: number;
  name: string;
  code: string;
  incompleteRequiredModuleCount: number;
  startedRequiredModuleCount: number;
  requiredModuleCount: number;
  status: 'met' | 'not_met';
  staffTrainingCompetencies: {
    id: number;
    name: string;
    code: string;
    description: string;
    requiredModules: StaffTrainingCompetencyRequiredModule[];
    staffTrainingRecords: {
      id: number;
      name: string;
      date: string;
      durationMinutes: number;
      durationHours: number;
      recordType: 'training_session' | 'prior_experience_audit';
      staffTrainingRecordCompetencies: {
        staffTrainingCompetencyId: number;
      }[];
    }[];
    staffTrainingCertificates: {
      id: number;
      status: 'not_created' | 'awaiting_approval' | 'approved' | 'rejected';
      certificateType: {
        id: number;
        name: string;
      };
    }[];
    staffTrainingEvents: {
      id: number;
      name: string;
      startsAt: string;
      durationHours: number;
      durationMinutes: number;
      status: 'upcoming' | 'attended';
    }[];
    status: StaffTrainingCompetencyStatus;
  }[];
}

type StaffTrainingCompetencyStatus =
  | 'met_by_training_record'
  | 'met_by_certificate'
  | 'met_by_event'
  | 'met_by_requirements'
  | 'not_met';

export type StaffTrainingCompetencyRequiredModule = {
  id: number;
  name: string;
  durationHours: number | null;
  durationMinutes: number | null;
} & (
  | { status: 'not_started'; completedAt: undefined; completionCreatedAt: undefined }
  | { status: 'started'; completedAt: undefined; completionCreatedAt: string }
  | { status: 'completed'; completedAt: string; completionCreatedAt: string }
);
// #endregion

export const useStaffTrainingStore = useStore(
  defineStore('staffTraining', () => {
    const api = useApi();

    // #region staff training for a particular staff
    const staffTrainingCache = ref<CacheMap<StaffTraining>>(new CacheMap());
    const currentStaffId = ref<number>();

    async function loadStaffTraining(staffId: number, force?: boolean) {
      currentStaffId.value = staffId;
      if (force || !staffTrainingCache.value.get(staffId)) {
        const r = await api.get<StaffTrainingResponse>(`/staff/${staffId}/staff-training`);
        staffTrainingCache.value.set(staffId, mapStaffTrainingRequirements(r.data));
      }
    }

    function clearStaffTraining(staffId: number) {
      staffTrainingCache.value.remove(staffId);
    }

    function clearAllStaffTraining() {
      staffTrainingCache.value = new CacheMap();
    }

    const staffTraining = computed(() =>
      currentStaffId.value ? staffTrainingCache.value.getEvenIfExpired(currentStaffId.value) : undefined
    );
    const hasStaffTraining = computed(() => {
      const st = staffTraining.value;
      if (!st) return false;
      return (
        st.staffTrainingFrameworks.length > 0 ||
        st.staffTrainingCertificates.length > 0 ||
        st.staffTrainingModuleCompletions.length > 0 ||
        st.staffTrainingRecords.length > 0
      );
    });
    const assignedStaffTrainingFrameworks = computed(() => staffTraining.value?.staffTrainingFrameworks || []);
    const staffTrainingModuleCompletions = computed(() => staffTraining.value?.staffTrainingModuleCompletions || []);
    const staffTrainingRecords = computed(() => staffTraining.value?.staffTrainingRecords || []);
    const staffTrainingCertificates = computed(() => staffTraining.value?.staffTrainingCertificates || []);
    const staffTrainingAcceptedCertificates = computed(
      () => staffTraining.value?.staffTrainingAcceptedCertificates || []
    );
    // #endregion

    return {
      currentStaffId,
      staffTrainingCache,
      loadStaffTraining,
      clearStaffTraining,
      clearAllStaffTraining,
      staffTraining,
      hasStaffTraining,
      assignedStaffTrainingFrameworks,
      staffTrainingModuleCompletions,
      staffTrainingRecords,
      staffTrainingCertificates,
      staffTrainingAcceptedCertificates,
    };
  })
);

function mapStaffTrainingRequirements(staffTraining: StaffTrainingResponse): StaffTraining {
  const staffTrainingEvents = staffTraining.staffTrainingEvents.filter(e =>
    staffTraining.staffTrainingEventAttendances.some(a => a.staffTrainingEventId == e.id)
  );

  const training = {
    ...staffTraining,
    staffTrainingFrameworks: staffTraining.staffTrainingFrameworks.map(f => ({
      ...f,
      staffTrainingCompetencyThemes: f.staffTrainingCompetencyThemes.map(t =>
        mapStaffTrainingRequirementTheme(t, staffTraining)
      ),
    })),
    staffTrainingCertificates: staffTraining.staffTrainingCertificates.map(c => ({
      ...c,
      relevantForInstitution: staffTraining.staffTrainingAcceptedCertificates.some(
        ac => ac.certificateType.id == c.certificateType.id
      ),
    })),
  };

  const requirementsCounts = training.staffTrainingFrameworks.flatMap(f =>
    f.staffTrainingCompetencyThemes.flatMap(t => ({
      incomplete: t.incompleteRequiredModuleCount,
      total: t.requiredModuleCount,
    }))
  );
  const requirementsCount = requirementsCounts.reduce(
    (a, b) => ({
      incomplete: a.incomplete + b.incomplete,
      total: a.total + b.total,
    }),
    { incomplete: 0, total: 0 }
  );

  const total = [
    ...staffTrainingEvents,
    ...staffTraining.staffTrainingRecords.filter(r => r.recordType == 'training_session'),
    ...staffTraining.staffTrainingModuleCompletions.filter(c => c.completed).map(c => c.staffTrainingModule),
  ].reduce(
    (total, x) => ({
      hours: total.hours + (x.durationHours || 0),
      minutes: total.minutes + (x.durationMinutes || 0),
    }),
    { hours: 0, minutes: 0 }
  );

  total.hours += Math.floor(total.minutes / 60);
  total.minutes = total.minutes % 60;

  return {
    ...training,
    totalDuration: formatDuration(total.hours, total.minutes),
    staffTrainingEvents,
    requirementsCount,
  };
}

function mapStaffTrainingRequirementTheme(
  t: StaffTrainingResponse['staffTrainingFrameworks'][number]['staffTrainingCompetencyThemes'][number],
  response: StaffTrainingResponse
): StaffTrainingCompetencyTheme {
  const themeModules = t.staffTrainingCompetencies.map(c => c.requiredModules).flat();

  const staffTrainingCompetencies: StaffTrainingCompetencyTheme['staffTrainingCompetencies'] =
    t.staffTrainingCompetencies.map(c => {
      const staffTrainingRecords = response.staffTrainingRecords.filter(r =>
        r.staffTrainingRecordCompetencies.map(c => c.staffTrainingCompetencyId).includes(c.id)
      );

      const staffTrainingCertificates = response.staffTrainingAcceptedCertificates
        .filter(ac =>
          ac.staffTrainingAcceptedCertificateCompetencies.map(c => c.staffTrainingCompetencyId).includes(c.id)
        )
        .map(ac => {
          const c = response.staffTrainingCertificates.find(c => ac.certificateType.id == c.certificateType.id);
          if (c) return c;
          return {
            id: -1,
            status: 'not_created',
            certificateType: ac.certificateType,
          } as const;
        });

      const requiredModules: StaffTrainingCompetencyRequiredModule[] = c.requiredModules.map(m => {
        const completion = response.staffTrainingModuleCompletions.find(c => c.staffTrainingModuleId === m.id);
        return {
          ...m,
          ...(completion
            ? completion.completed
              ? {
                  status: 'completed',
                  completedAt: completion.completedAt,
                  completionCreatedAt: completion.createdAt,
                }
              : { status: 'started', completedAt: undefined, completionCreatedAt: completion.createdAt }
            : { status: 'not_started', completedAt: undefined, completionCreatedAt: undefined }),
        };
      });

      const staffTrainingEvents = response.staffTrainingEvents
        .filter(
          e =>
            e.staffTrainingEventCompetencies.map(c => c.staffTrainingCompetencyId).includes(c.id) &&
            (!dateTimeIsInThePast(e.startsAt) ||
              response.staffTrainingEventAttendances.some(a => a.staffTrainingEventId == e.id))
        )
        .map(
          e =>
            ({
              ...e,
              status: dateTimeIsInThePast(e.startsAt) ? 'attended' : 'upcoming',
            } as const)
        );

      return {
        ...c,
        status:
          staffTrainingEvents.filter(e => e.status == 'attended').length > 0
            ? 'met_by_event'
            : staffTrainingRecords.length > 0
            ? 'met_by_training_record'
            : staffTrainingCertificates.filter(c => c.status == 'approved').length > 0
            ? 'met_by_certificate'
            : requiredModules.length > 0 && requiredModules.every(m => m.status == 'completed')
            ? 'met_by_requirements'
            : 'not_met',
        requiredModules,
        staffTrainingRecords,
        staffTrainingCertificates,
        staffTrainingEvents,
      };
    });

  return {
    ...t,
    status: staffTrainingCompetencies.some(c => c.status == 'not_met') ? 'not_met' : 'met',
    staffTrainingCompetencies,
    requiredModuleCount: themeModules.length,
    startedRequiredModuleCount: themeModules.filter(m => {
      const completion = response.staffTrainingModuleCompletions.find(c => c.staffTrainingModuleId === m.id);
      return completion && !completion.completed;
    }).length,
    incompleteRequiredModuleCount: themeModules.filter(m => {
      const completion = response.staffTrainingModuleCompletions.find(c => c.staffTrainingModuleId === m.id);
      return !completion || !completion.completed;
    }).length,
  };
}

export type StaffTrainingAcceptedCertificateSlimResponse = {
  id: number;
  certificateType: {
    id: number;
    name: string;
  };
}[];

export type StaffTrainingAcceptedCertificatePublishedResponse = {
  id: number;
  description: string;
  certificateType: {
    id: number;
    name: string;
  };
  staffTrainingCompetencies: { id: number }[];
}[];

export interface StaffTrainingResponse {
  staffTrainingFrameworks: {
    id: number;
    name: string;
    requiredFor: {
      cohort: {
        id: number;
        name: string;
      };
      roles: {
        id: number;
        name: string;
      }[];
    }[];
    staffTrainingCompetencyThemes: {
      id: number;
      name: string;
      code: string;
      staffTrainingCompetencies: {
        id: number;
        name: string;
        code: string;
        description: string;
        // It's probably neater/more consistent to pull modules into its own top level collection and have linking staffCompetencyIds on them
        requiredModules: {
          id: number;
          name: string;
          status: 'published' | 'draft';
          durationHours: number | null;
          durationMinutes: number | null;
        }[];
      }[];
    }[];
  }[];
  staffTrainingModuleCompletions: {
    id: number;
    completed: boolean;
    completedAt: string;
    createdAt: string;
    staffTrainingModuleId: number;
    staffTrainingModule: {
      id: number;
      name: string;
      durationHours: number | null;
      durationMinutes: number | null;
    };
  }[];
  staffTrainingRecords: {
    id: number;
    name: string;
    recordType: 'training_session' | 'prior_experience_audit';
    date: string;
    durationHours: number;
    durationMinutes: number;
    staffTrainingRecordCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  }[];
  staffTrainingAcceptedCertificates: {
    id: number;
    description: string;
    certificateType: {
      id: number;
      name: string;
    };
    staffTrainingAcceptedCertificateCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  }[];
  staffTrainingCertificates: {
    id: number;
    certificateType: {
      id: number;
      name: string;
    };
    status: 'awaiting_approval' | 'approved' | 'rejected';
    approvedAtLeastOnce: boolean;
  }[];
  staffTrainingEvents: {
    id: number;
    name: string;
    startsAt: string;
    durationHours: number;
    durationMinutes: number;
    attendanceCount: number;
    staffTrainingEventCompetencies: {
      staffTrainingCompetencyId: number;
    }[];
  }[];
  staffTrainingEventAttendances: {
    staffTrainingEventId: number;
  }[];
}
