import { defineStore } from 'pinia';
import { mapGetters } from '@/store/map-store';
import { useStore } from './common';
import { computed } from 'vue';
import { mapState } from '@/store/map-store';

export const useStudentStore = useStore(
  defineStore('student', () => {
    const { traineeNounCapitalised, traineeNounCapitalisedAndPluralised } = mapGetters();
    const { selectedStudent } = mapState();

    return {
      traineeNoun: computed(() => traineeNounCapitalised.value(false)),
      aTraineeNoun: computed(() => traineeNounCapitalised.value(true)),
      traineeNounPluralised: traineeNounCapitalisedAndPluralised,
      selectedStudent,
    };
  })
);
