<template>
  <div>
    <v-card v-if="error">
      <v-card-text>
        <div class="pa-4">{{ error }}</div>
      </v-card-text>
    </v-card>
    <v-card v-if="!error && !busy">
      <v-card-text>
        <mosaic-card-title>{{ reviewNounCapitalisedAndPluralised }}</mosaic-card-title>
        <v-divider class="mt-4" />
        <v-tabs v-model="tab">
          <v-tab v-for="h of tabHeadings" :key="h" class="pr-0">{{ h }}<span style="width: 24px"></span></v-tab>
        </v-tabs>
        <v-window v-model="tab">
          <v-window-item>
            <div class="d-flex align-center py-1">
              <mosaic-checkbox
                v-if="selectedInstitution.config.early_careers"
                no-icon
                v-model="hideNotActive"
                hide-details
                density="compact"
                label="Show inactive templates"
                class="px-4 mt-0 pt-0"
              />
              <mosaic-checkbox
                v-else
                no-icon
                v-model="showArchived"
                density="compact"
                hide-details
                label="Show archived Templates"
                name="show-archived"
                class="px-2 mt-0 pt-0"
              />
              <div class="flex-grow-1" />
              <div class="pr-2">
                <v-btn ripple @click.prevent="create()">
                  <span class="d-flex align-center"><v-icon class="mr-2">mdi-plus</v-icon>Template</span>
                </v-btn>
              </div>
              <div v-if="templates.length > 0">
                <v-btn ripple @click.prevent="copy()">
                  <span class="d-flex align-center"
                    ><v-icon class="mr-2">mdi-plus-box-multiple-outline</v-icon>Copy Template</span
                  >
                </v-btn>
              </div>

              <mosaic-help-site-link
                class="ml-2"
                :object-type="reviewNounCapitalised + ' Templates'"
                url="http://help.mosaic.penrose.education/books/how-to-guide-for-administrators-and-course-leads/chapter/review-points"
              />
            </div>
            <v-alert
              v-if="selectedInstitution.config.early_careers && missingActiveTemplateTypes.length > 0"
              class="mt-4"
              color="warning"
              variant="outlined"
            >
              There are no active {{ reviewTemplateNoun }}s for the following types:
              {{ missingActiveTemplateTypes.join(', ') }}
            </v-alert>
            <mosaic-list
              :empty-text="`You currently do not have any ${
                !showArchived && !selectedInstitution.config.early_careers ? 'non-archived' : ''
              } ${reviewNounCapitalised} templates. Add one above to be able to create ${reviewNounCapitalisedAndPluralised}.`"
              :items="filteredTemplates"
            >
              <template #item="{ item: template }">
                <mosaic-list-item
                  :to="templateClickTo(template.id)"
                  icon="mdi-check-circle-outline"
                  :title="template.name"
                >
                  <template #subtitle v-if="template.review_type">
                    {{ reviewTypeItems.find(x => x.value === template.review_type).title }}
                  </template>

                  <template #information>
                    <div v-if="selectedInstitution.config.early_careers" class="mr-4">
                      <mosaic-tooltip-chip v-if="template.active" color="accent">
                        <template #text> Active </template>
                        <template #tooltip>
                          <div>
                            Active templates are used by Mosaic to automatically create
                            {{ reviewNounCapitalisedAndPluralised }}
                          </div>
                          <div v-if="!selectedInstitution.config.run_reviews_scheduler">
                            (this is not currently turned on for your Institution)
                          </div>
                        </template>
                      </mosaic-tooltip-chip>
                      <mosaic-tooltip-chip v-else color="secondary">
                        <template #text> Inactive </template>
                        <template #tooltip>
                          <div>
                            Active templates are used by Mosaic to automatically create
                            {{ reviewNounCapitalisedAndPluralised }}
                          </div>
                          <div v-if="!selectedInstitution.config.run_reviews_scheduler">
                            (this is not currently turned on for your Institution)
                          </div>
                        </template></mosaic-tooltip-chip
                      >
                    </div>
                    <div v-else class="mr-4">
                      <mosaic-published-draft-archived-chip
                        :status="template.status"
                        :object-type="reviewTemplateNoun"
                        archived-phrase="archived"
                      />
                    </div>
                  </template>

                  <template #actions>
                    <template v-if="selectedInstitution.config.early_careers">
                      <ndt-icon-button
                        v-if="!template.active"
                        icon="star"
                        tooltip="Make active"
                        @click.prevent="makeActive(template)"
                      />

                      <div v-else-if="filteredTemplates.some(x => !x.active)" style="width: 48px" />
                    </template>
                    <ndt-icon-button
                      icon="pencil"
                      :tooltip="`Rename ${reviewTemplateNoun}`"
                      @click.prevent="editTemplate(template)"
                    />
                    <ndt-icon-button
                      v-if="!selectedInstitution.config.early_careers"
                      :icon="template.status !== 'archived' ? 'archive' : 'archive-off'"
                      :tooltip="`${template.status !== 'archived' ? 'Archive' : 'Unarchive'} ${reviewTemplateNoun} ${
                        template.status === 'archived' && template.has_reviews
                          ? '(as published)'
                          : template.status === 'archived' && !template.has_reviews
                          ? '(as draft)'
                          : ''
                      }`"
                      @click.prevent="archiveTemplate(template)"
                    />
                    <ndt-icon-button
                      v-if="canDelete(template)"
                      icon="delete"
                      :tooltip="`Delete ${reviewTemplateNoun}`"
                      @click.prevent="deleteTemplate(template)"
                    />
                    <div v-else-if="anyVisibleTemplatesAreDeletable" style="width: 48px" />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </v-window-item>

          <v-window-item>
            <div class="d-flex justify-end py-1">
              <div class="pr-2">
                <v-btn ripple @click.prevent="previewEmailDialog.active = true">
                  <span class="d-flex align-center"><v-icon class="mr-2">mdi-eye</v-icon>Preview Emails</span>
                </v-btn>
              </div>
              <div>
                <v-btn ripple @click.prevent="createRule()">
                  <span class="d-flex align-center"><v-icon class="mr-2">mdi-plus</v-icon>Rule</span>
                </v-btn>
              </div>
            </div>

            <mosaic-list
              :items="emailRules"
              empty-text="You currently do not have any automated email rules configured."
            >
              <template #item="{ item: rule }">
                <mosaic-list-item icon="mdi-email-fast-outline" :title="rule.name" :subtitle="ruleDescription(rule)">
                  <template #information>
                    <v-chip v-if="rule.isActive" color="accent">Active</v-chip>
                    <v-chip v-else color="secondary">Inactive</v-chip>
                  </template>

                  <template #actions>
                    <ndt-icon-button icon="pencil" tooltip="Edit rule" @click.prevent="editRule(rule)" />
                    <ndt-icon-button icon="delete" tooltip="Delete rule" @click.prevent="deleteRule(rule)" />
                  </template>
                </mosaic-list-item>
              </template>
            </mosaic-list>
          </v-window-item>
        </v-window>
      </v-card-text>
    </v-card>

    <ndt-dialog v-model:active="editDialog.active" title="Rename Template" :error-message="editDialog.error">
      <div class="d-flex">
        <div class="flex-grow-1 px-4">
          <v-text-field v-model="editDialog.name" :label="`${reviewTemplateNoun} Name`" hide-details></v-text-field>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="editDialog.processing" @click.prevent="submitEditTemplate()"
          >Save</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Template" :error-message="deleteDialog.error">
      <span>Are you sure you want to delete "{{ deleteDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteTemplate()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="makeActiveDialog.active"
      title="Make Template Active"
      :error-message="makeActiveDialog.error"
    >
      <span v-if="makeActiveDialog.template"
        >This will make this template the active
        {{ reviewTypeItems.find(x => x.value === makeActiveDialog.template.review_type).title }} template. Any currently
        active {{ reviewTypeItems.find(x => x.value === makeActiveDialog.template.review_type).title }} template will be
        marked as inactive.</span
      >
      <template #buttons>
        <v-btn variant="text" ripple :disabled="makeActiveDialog.processing" @click.prevent="submitMakeActive()"
          >Confirm</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog
      v-model:active="editRuleDialog.active"
      :title="(editRuleDialog.editing ? 'Edit' : 'Create') + ' Rule'"
      :error-message="editRuleDialog.error"
      :width="900"
    >
      <div>
        <div class="font-weight-bold">Who should receive {{ reviewNounCapitalised }} reminder emails?</div>
        <div>
          They will only receive reminder emails if they've not completed the {{ reviewNounCapitalised }} and their role
          is configured to be a "Contributor" for the {{ reviewNounCapitalised }}.
        </div>
        <div class="d-flex pt-1 align-center">
          <v-checkbox v-model="editRuleDialog.isStudentRule" :label="traineeNounCapitalised()" class="pr-2" />
          <v-checkbox
            v-for="role in studentScopedRoles"
            :key="role.id"
            v-model="editRuleDialog.selectedRoles"
            :label="role.name"
            :value="role.id"
            class="pr-2"
          />
        </div>
        <div class="font-weight-bold">When should they receive {{ reviewNounCapitalised }} reminder emails?</div>
        <div class="pt-1 pb-2">
          <div>
            <v-radio-group v-model="editRuleDialog.isOverdueRule" inline>
              <v-radio label="Fixed time before or after the due date" :value="false"></v-radio>
              <v-radio label="Regularly when overdue" :value="true"></v-radio>
            </v-radio-group>
          </div>
          <template v-if="editRuleDialog.isOverdueRule">
            <div>Send reminder email every:</div>
            <div class="d-flex align-center">
              <div class="pr-2" style="width: 60px">
                <v-text-field
                  v-model="editRuleDialog.overdueFrequency"
                  type="number"
                  :rules="overduePeriodRules"
                  class="text-center"
                ></v-text-field>
              </div>
              <div class="pr-2" style="width: 128px">
                <v-select v-model="editRuleDialog.overdueFrequencyPeriodType" :items="rulePeriods" />
              </div>

              <div>after due date</div>
            </div>
            <div>Finish after:</div>
            <div class="d-flex align-center">
              <div class="pr-2" style="width: 60px">
                <v-text-field
                  v-model="editRuleDialog.overdueEndPeriodsCount"
                  type="number"
                  :rules="overduePeriodRules"
                  class="text-center"
                ></v-text-field>
              </div>
              <div class="pr-2" style="width: 128px">
                <v-select v-model="editRuleDialog.overdueEndPeriodType" :items="rulePeriods" />
              </div>
            </div>
          </template>
          <template v-else>
            <div>Send reminder email:</div>
            <div class="d-flex align-center">
              <div class="pr-2" style="width: 60px">
                <v-text-field
                  v-model="editRuleDialog.periodsBeforeDue"
                  type="number"
                  :rules="beforePeriodRules"
                  class="text-center"
                ></v-text-field>
              </div>
              <div class="pr-2" style="width: 128px">
                <v-select v-model="editRuleDialog.beforeDuePeriodType" :items="rulePeriods" />
              </div>
              <div class="pr-2" style="width: 128px">
                <v-select v-model="editRuleDialog.beforeOrAfter" :items="['before', 'after']" />
              </div>
              <div>due date</div>
            </div>
          </template>
        </div>
        <div class="font-weight-bold pb-1">Is this rule active?</div>
        <div>
          <v-btn-toggle v-model="editRuleDialog.isRuleActive" mandatory variant="outlined" divided color="accent">
            <v-btn color="accent" :value="true">Active</v-btn>
            <v-btn color="primary" :value="false">Inactive</v-btn>
          </v-btn-toggle>
        </div>
      </div>
      <template #buttons>
        <v-btn variant="text" ripple :disabled="!canUpdateRule" @click.prevent="submitEditRule()">Save</v-btn>
      </template>
    </ndt-dialog>

    <ndt-dialog v-model:active="deleteRuleDialog.active" title="Delete Rule" :error-message="deleteRuleDialog.error">
      <span>Are you sure you want to delete rule: "{{ deleteRuleDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteRuleDialog.processing"
          @click.prevent="submitDeleteRule()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog v-model:active="deleteDialog.active" title="Delete Template" :error-message="deleteDialog.error">
      <span>Are you sure you want to delete "{{ deleteDialog.title }}"?</span>
      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="deleteDialog.processing"
          @click.prevent="submitDeleteTemplate()"
          >Delete</v-btn
        >
      </template>
    </ndt-dialog>

    <ndt-dialog v-model:active="previewEmailDialog.active" title="Preview Emails" close-button-text="Close">
      <div v-if="!emailPreviewsError">
        <div class="d-flex justify-center">
          <v-btn-toggle
            v-model="previewEmailDialog.roleToggle"
            class="py-2"
            mandatory
            color="accent"
            variant="outlined"
            divided
          >
            <v-btn class="px-3" value="student">{{ traineeNounCapitalised() }}</v-btn>
            <v-btn class="px-3" value="instructor">Instructor</v-btn>
          </v-btn-toggle>
        </div>
        <div class="d-flex justify-center">
          <iframe
            :srcdoc="previewEmailDialog.roleToggle === 'student' ? emailPreviews.student : emailPreviews.staff"
            :style="{ height: '500px', width: '550px' }"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
      <div v-else>{{ emailPreviewsError }}</div>
    </ndt-dialog>

    <mosaic-dialog v-model:active="archiveDialog.active" title="Confirmation" :error-message="archiveDialog.error">
      <span
        >Are you sure you want to {{ archiveDialog.template.status !== 'archived' ? 'archive' : 'unarchive' }} the
        {{ reviewTemplateNoun }} "{{ archiveDialog.template?.name }}"?
        {{
          archiveDialog.template.status === 'archived'
            ? `The status will be set back to ${archiveDialog.template.has_reviews ? 'published' : 'draft'}.`
            : ''
        }}
      </span>

      <template #buttons>
        <v-btn
          variant="text"
          ripple
          color="error"
          :disabled="archiveDialog.processing"
          :loading="archiveDialog.processing"
          @click.prevent="submitArchiveTemplate()"
          >Confirm</v-btn
        >
      </template>
    </mosaic-dialog>
  </div>
</template>

<script>
import NdtIconButton from '@/components/NdtIconButton.vue';
import NdtDialog from '@/components/NdtDialog.vue';
import { mapState, mapGetters } from 'vuex';
import { syncQueryParamsMixin } from '@/mixins/query-mixins';
import { useQueryStore } from '@/stores/query';
import { validationsPass } from '@/utils/validations';

const editRuleDialogDefaults = {
  active: false,
  editing: true,
  processing: false,
  periodsBeforeDue: 1,
  beforeDuePeriodType: 'weeks',
  beforeOrAfter: 'before',
  overdueFrequency: 1,
  overdueFrequencyPeriodType: 'weeks',
  overdueEndPeriodsCount: 1,
  overdueEndPeriodType: 'months',
  id: null,
  error: '',
  isOverdueRule: false,
  isRuleActive: true,
  isStudentRule: true,
  selectedRoles: [],
};
export default {
  name: 'TutorAdminReviewTemplatesListPage',
  setup() {
    const queryStore = useQueryStore();
    return { queryStore };
  },
  mixins: [
    syncQueryParamsMixin({
      tab: { query: '_tab', type: 'integer' },
      showArchived: { query: 'showArchived', type: 'boolean' },
    }),
  ],
  components: { NdtIconButton, NdtDialog },
  data: () => ({
    rulePeriods: ['days', 'weeks', 'months'],
    error: '',
    tab: 0,
    templates: [],
    emailRules: [],
    busy: true,
    beforePeriodRules: [v => (!isNaN(parseInt(v)) && v >= 0) || 'Should be a non-negative number'],
    overduePeriodRules: [v => (!isNaN(parseInt(v)) && v >= 1) || 'Should be a positive number'],
    editRuleDialog: { ...editRuleDialogDefaults },
    previewEmailDialog: {
      active: false,
      roleToggle: 'student',
    },
    emailPreviews: {
      staff: '',
      student: '',
    },
    emailPreviewsError: '',
    deleteRuleDialog: {
      active: false,
      processing: false,
      id: null,
      error: '',
      title: '',
    },
    editDialog: {
      active: false,
      processing: false,
      id: null,
      name: '',
      error: '',
    },
    deleteDialog: {
      active: false,
      processing: false,
      id: null,
      error: '',
      title: '',
    },
    makeActiveDialog: {
      active: false,
      processing: false,
      template: null,
      error: '',
    },
    archiveDialog: {
      active: false,
      processing: false,
      id: null,
      error: '',
      title: '',
    },
    hideNotActive: false,
    showArchived: false,
  }),
  async created() {
    this.hideNotActive = this.selectedInstitution.config.early_careers;
    this.$store.commit('resetReviewTemplatePage');
    await this.$store.dispatch('loadRoles');
    this.editRuleDialog.selectedRoles = this.studentScopedRoles.map(r => r.id);
    await this.loadTemplates();
    await this.loadRules();
    await this.loadPreviews();
    if (this.$route.query.tab === 'rules' && this.selectedInstitution.config.show_review_email_reminder_rules) {
      this.tab = 1;
    }
  },
  computed: {
    ...mapState(['selectedInstitution', 'reviewTypeItems', 'roles']),
    ...mapGetters(['studentScopedRoles']),
    breadcrumbs() {
      return [
        {
          text: this.reviewNounCapitalisedAndPluralised,
        },
      ];
    },
    reviewTemplateNoun() {
      return this.reviewNounCapitalised + ' Template';
    },
    canUpdateRule() {
      return (
        !this.editRuleDialog.processing &&
        (!this.editRuleDialog.isOverdueRule ||
          (validationsPass(this.overduePeriodRules, this.editRuleDialog.overdueFrequency) &&
            validationsPass(this.overduePeriodRules, this.editRuleDialog.overdueEndPeriodsCount))) &&
        (this.editRuleDialog.isOverdueRule ||
          validationsPass(this.beforePeriodRules, this.editRuleDialog.periodsBeforeDue))
      );
    },
    tabHeadings() {
      const headings = [`Templates`];
      if (this.selectedInstitution.config.show_review_email_reminder_rules) {
        headings.push(`Email Reminder Rules`);
      }
      return headings;
    },
    previewEmailImageUrl() {
      const file_stem = this.selectedInstitution.config.early_careers
        ? this.previewEmailDialog.roleToggle === 'student'
          ? 'ect'
          : 'it'
        : this.previewEmailDialog.roleToggle === 'student'
        ? 'trainee'
        : 'mentor';
      return `https://mosaic-production-1.s3.eu-west-1.amazonaws.com/public/email/previews/${file_stem}_reminder_preview.png`;
    },
    filteredTemplates() {
      return this.templates
        .filter(t =>
          this.selectedInstitution.config.early_careers
            ? !this.hideNotActive || t.active
            : this.showArchived || t.status !== 'archived'
        )
        .sortBy(['active', 'created_at'], ['desc', 'desc']);
    },
    anyVisibleTemplatesAreDeletable() {
      return this.filteredTemplates.some(t => this.canDelete(t));
    },
    missingActiveTemplateTypes() {
      return this.reviewTypeItems
        .filter(x => !this.templates.some(t => t.active && t.review_type === x.value))
        .map(x => x.title);
    },
  },
  methods: {
    async loadTemplates() {
      this.busy = true;
      try {
        const response = await this.$api.get(
          `/institutions/${this.selectedInstitution.id}/review-templates?archived=true`
        );

        this.templates = response.data;
      } catch (e) {
        this.error = `Sorry, cannot load your ${this.reviewNoun} templates`;
        console.log(e);
      }
      this.busy = false;
    },
    async loadRules() {
      this.busy = true;
      try {
        const response = await this.$api.get(`/institutions/${this.selectedInstitution.id}/email-rules`);
        this.emailRules = response.data;
      } catch (e) {
        this.error = `Sorry, cannot load your automated email rules`;
        console.log(e);
      }
      this.busy = false;
    },
    async loadPreviews() {
      try {
        this.emailPreviewsError = '';
        const response = await this.$api.get(`/institutions/${this.selectedInstitution.id}/email-previews`);
        this.emailPreviews = response.data;
      } catch (e) {
        console.log(e);
        this.emailPreviewsError =
          'Sorry, cannot load email previews at the moment. Please refresh the page to try again and contact support if this continues to happen.';
      }
    },
    ruleDescription(rule) {
      const roles = [];
      if (rule.isStudentRule) {
        roles.push(this.traineeNounCapitalised());
      }
      roles.push(...rule.roleIds.map(x => this.roles.find(r => r.id === x)?.name).filter(x => x));
      return roles.join(', ');
    },
    createRule() {
      this.editRuleDialog.active = true;
      this.editRuleDialog.editing = false;
    },
    async submitEditRule() {
      this.editRuleDialog.error = '';
      this.editRuleDialog.processing = true;

      if (this.editRuleDialog.isOverdueRule) {
        const frequencyPeriodType = this.editRuleDialog.overdueFrequencyPeriodType;
        const endPeriodType = this.editRuleDialog.overdueEndPeriodType;
        if (
          (endPeriodType === 'days' && frequencyPeriodType !== 'days') ||
          (endPeriodType === 'weeks' && frequencyPeriodType === 'months') ||
          (endPeriodType === frequencyPeriodType &&
            this.editRuleDialog.overdueEndPeriodsCount < this.editRuleDialog.overdueFrequency)
        ) {
          this.editRuleDialog.error = 'Cannot have rule that never triggers';
          this.editRuleDialog.processing = false;
          return;
        }
      }

      let apiCall;
      const urlRoot = `reviews-automated-email-rules`;
      const ruleConfiguration = this.editRuleDialog.isOverdueRule
        ? // nulls are important in below to force fields in db to null. If excluded, they don't get overwritten if rule type changes, causing validation to fail
          {
            overdueFrequency: this.editRuleDialog.overdueFrequency,
            overdueFrequencyPeriodType: this.editRuleDialog.overdueFrequencyPeriodType,
            overdueEndPeriodsCount: this.editRuleDialog.overdueEndPeriodsCount,
            overdueEndPeriodType: this.editRuleDialog.overdueEndPeriodType,
            periodsBeforeDue: null,
            beforeDuePeriodType: null,
          }
        : {
            overdueFrequency: null,
            overdueFrequencyPeriodType: null,
            overdueEndPeriodsCount: null,
            overdueEndPeriodType: null,
            periodsBeforeDue:
              this.editRuleDialog.periodsBeforeDue * (this.editRuleDialog.beforeOrAfter === 'before' ? 1 : -1),
            beforeDuePeriodType: this.editRuleDialog.beforeDuePeriodType,
          };
      if (!this.editRuleDialog.id) {
        apiCall = x => this.$api.post(urlRoot, x);
      } else {
        apiCall = x => this.$api.put(`${urlRoot}/${this.editRuleDialog.id}`, x);
      }
      try {
        await apiCall({
          institutionId: this.selectedInstitution.id,
          isOverdueRule: this.editRuleDialog.isOverdueRule,
          ...ruleConfiguration,
          isActive: this.editRuleDialog.isRuleActive,
          isStudentRule: this.editRuleDialog.isStudentRule,
          selectedRoleIds: this.editRuleDialog.selectedRoles,
        });
        this.loadRules();
        this.editRuleDialog.active = false;
        // Wait for dialog to close before updating it
        setTimeout(
          () =>
            (this.editRuleDialog = {
              ...editRuleDialogDefaults,
              selectedRoles: this.studentScopedRoles.map(r => r.id),
            }),
          500
        );
      } catch (e) {
        console.log(e);
        this.editRuleDialog.error = 'Sorry, cannot save your automed email rule at the moment';
        this.editRuleDialog.processing = false;
      }
    },
    deleteRule(rule) {
      this.deleteRuleDialog = {
        active: true,
        processing: false,
        id: rule.id,
        error: '',
        title: rule.name,
      };
    },
    async submitDeleteRule() {
      try {
        this.deleteRuleDialog.processing = false;
        await this.$api.delete(`/reviews-automated-email-rules/${this.deleteRuleDialog.id}`);
        this.deleteRuleDialog.active = false;
        this.loadRules();
      } catch (e) {
        console.log(e);
        this.deleteRuleDialog.processing = true;
      }
    },
    editRule(rule) {
      this.editRuleDialog = {
        ...rule,
        active: true,
        editing: true,
        processing: false,
        beforeOrAfter:
          (rule.periodsBeforeDue && (rule.periodsBeforeDue < 0 ? 'after' : 'before')) ||
          editRuleDialogDefaults.beforeOrAfter,
        error: '',
        isRuleActive: rule.isActive,
        selectedRoles: rule.roleIds,
        periodsBeforeDue:
          rule.periodsBeforeDue || rule.periodsBeforeDue === 0
            ? Math.abs(rule.periodsBeforeDue)
            : editRuleDialogDefaults.periodsBeforeDue,
        beforeDuePeriodType: rule.beforeDuePeriodType || editRuleDialogDefaults.beforeDuePeriodType,
        overdueFrequency: rule.overdueFrequency || editRuleDialogDefaults.overdueFrequency,
        overdueFrequencyPeriodType:
          rule.overdueFrequencyPeriodType || editRuleDialogDefaults.overdueFrequencyPeriodType,
        overdueEndPeriodsCount: rule.overdueEndPeriodsCount || editRuleDialogDefaults.overdueEndPeriodsCount,
        overdueEndPeriodType: rule.overdueEndPeriodType || editRuleDialogDefaults.overdueEndPeriodType,
      };
    },
    templateClickTo(id) {
      return {
        name: 'TutorAdminReviewTemplatePage',
        params: { templateId: id },
      };
    },
    create() {
      this.$router.push({
        name: 'TutorAdminReviewTemplateCreatePage',
      });
    },
    copy() {
      this.$router.push({
        name: 'TutorAdminReviewTemplateCopyPage',
      });
    },
    editTemplate(template) {
      this.editDialog.name = template.name;
      this.editDialog.id = template.id;
      this.editDialog.active = true;
    },
    async submitEditTemplate() {
      this.editDialog.processing = true;
      this.editDialog.error = '';
      try {
        await this.$api.put(`/review-templates/${this.editDialog.id}/name`, {
          name: this.editDialog.name,
        });
        this.editDialog.active = false;
        this.loadTemplates();
      } catch (e) {
        console.log(e);
        this.editDialog.error = `Sorry, cannot rename your ${this.reviewTemplateNoun} at the moment`;
      }
      this.editDialog.processing = false;
    },
    deleteTemplate(template) {
      this.deleteDialog = {
        active: true,
        id: template.id,
        title: template.name,
        processing: false,
      };
    },
    async submitDeleteTemplate() {
      this.deleteDialog.processing = true;
      try {
        await this.$api.delete(`/review-templates/${this.deleteDialog.id}`);
        this.deleteDialog.active = false;
        this.loadTemplates();
      } catch (e) {
        console.log(e);
        this.deleteDialog.error = `Sorry, cannot delete this ${this.reviewTemplateNoun} at the moment`;
        this.deleteDialog.processing = false;
      }
    },
    makeActive(template) {
      this.makeActiveDialog = {
        active: true,
        template: template,
        processing: false,
        error: '',
      };
    },
    async submitMakeActive() {
      this.makeActiveDialog.processing = true;
      try {
        await this.$api.post(`/review-templates/${this.makeActiveDialog.template.id}/make-active`);
        this.makeActiveDialog.active = false;
        this.loadTemplates();
      } catch (e) {
        console.log(e);
        this.makeActiveDialog.error = `Sorry, cannot make this ${this.reviewTemplateNoun} active at the moment`;
        this.makeActiveDialog.processing = false;
      }
    },
    canDelete(template) {
      if (this.selectedInstitution.config.early_careers) return !template.has_reviews;
      return template.status === 'draft';
    },
    archiveTemplate(template) {
      this.archiveDialog = {
        active: true,
        template: template,
        processing: false,
        error: '',
      };
    },
    async submitArchiveTemplate() {
      this.archiveDialog.processing = false;
      try {
        await this.$api.put(`/review-templates/${this.archiveDialog.template.id}/status`, {
          status:
            this.archiveDialog.template.status !== 'archived'
              ? 'archived'
              : this.archiveDialog.template.has_reviews
              ? 'published'
              : 'draft',
        });
        this.archiveDialog.active = false;
        this.loadTemplates();
      } catch (e) {
        console.log(e);
        this.archiveDialog.error = `Sorry, cannot archive this ${this.reviewTemplateNoun} at the moment`;
        this.archiveDialog.processing = false;
      }
    },
  },
};
</script>
